export default (func, wait, immediate) => {
  let timeout;
  return function debounced () {
    // eslint-disable-next-line no-undef,prefer-rest-params
    const arg = arguments;

    const later = () => {
      timeout = null;
      func.apply(this, arg);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, arg);
  };
};
