import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { IQuotaState } from './types';

const initialState: IQuotaState = {
  totalBytes: 1024 * 1024 * 1024 * 16,
  usedBytes: 0,
};

export const updateQuotaStart = createAction('quota/updateQuotaStart');
export const updateQuotaFailed = createAction('quota/updateQuotaFailed');

export const quotaSlice = createSlice({
  name: 'quota',
  initialState,
  reducers: {
    updateQuotaSuccess(state, action: PayloadAction<IQuotaState>) {
      state.totalBytes = action.payload.totalBytes;
      state.usedBytes = action.payload.usedBytes;
    },
  },
});

export const { updateQuotaSuccess } = quotaSlice.actions;
export const quotaReducer = quotaSlice.reducer;
