import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { IFileRes } from 'api/files.types';
import { IFileModalState } from './types';

const initialState: IFileModalState = {
  isOpen: false,
  fileIdx: null,
  fromSearch: false,
  dataFromSearch: undefined,
};

export const fileModalOpenFromSearchStart = createAction<string>('fileModal/fileModalOpenFromSearchStart');
export const fileModalOpenFromSearchFailed = createAction('fileModal/fileModalOpenFromSearchFailed');

export const fileModalSlice = createSlice({
  name: 'fileModal',
  initialState,
  reducers: {
    fileModalOpen: (state, action: PayloadAction<number>) => {
      state.isOpen = true;
      state.fileIdx = action.payload;
    },
    fileModalOpenFromSearchSuccess: (state, action: PayloadAction<IFileRes>) => {
      state.isOpen = true;
      state.fromSearch = true;
      state.dataFromSearch = action.payload;
    },

    fileModalChoiceIndex: (state, action: PayloadAction<number>) => {
      state.fileIdx = action.payload;
    },

    fileModalIndexDecrease: (state) => {
      if (state.fileIdx !== null) {
        state.fileIdx = state.fileIdx > 1 ? state.fileIdx - 1 : 0;
      }
    },

    fileModalOpenFromSearchUpdate: (state, action: PayloadAction<IFileRes>) => {
      if (state.dataFromSearch) state.dataFromSearch = { ...state.dataFromSearch, ...action.payload };
    },

    fileFromSearchAddToAlbumOrRemoveFromAlbum: (state, { payload }: PayloadAction<{ albumUUID: string }>) => {
      if (state.dataFromSearch) {
        if (state.dataFromSearch.albums_uuids.includes(payload.albumUUID)) {
          state.dataFromSearch.albums_uuids = state.dataFromSearch.albums_uuids.filter(
            (currAlbumUUID) => currAlbumUUID !== payload.albumUUID,
          );
        } else {
          state.dataFromSearch.albums_uuids = [...state.dataFromSearch.albums_uuids, payload.albumUUID];
        }
      }
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase('@@router/LOCATION_CHANGE', (state) => {
        state.isOpen = initialState.isOpen;
        state.fileIdx = initialState.fileIdx;
        state.fromSearch = initialState.fromSearch;
        state.dataFromSearch = initialState.dataFromSearch;
      });
  },
});

export const {
  fileModalOpen,
  fileModalOpenFromSearchSuccess,
  fileModalChoiceIndex,
  fileModalOpenFromSearchUpdate,
  fileFromSearchAddToAlbumOrRemoveFromAlbum,
  fileModalIndexDecrease,
} = fileModalSlice.actions;
export const fileModalReducer = fileModalSlice.reducer;
