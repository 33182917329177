// конвертирует байты в читабельную строку с единицей изменения
type TDivisionFn = (currSize: number) => TDivisionFn | number;

export const fileSizeConverter = (bytes: number): string => {
  const divider = 1024;
  const items = ['KB', 'MB', 'GB'];
  let degree = 1;

  const division: TDivisionFn = (currSize) => {
    const newSize = currSize / divider;

    if (newSize > divider / 2 && degree < 3) {
      degree += 1;
      return division(newSize);
    }
    return Math.round(newSize * 100) / 100;
  };

  if (!bytes && bytes !== 0) return '-';

  return `${division(bytes)} ${items[degree - 1]}`;
};
