import * as Yup from 'yup';
// import i18next from 'i18next';

export const editProfileMap = () => ({
  name: Yup.string()
    .trim()
    // .max(180, i18next.t('validator__phone-format-error'))
    .required(),

  country: Yup.object({
    name: Yup.string().trim().required(),
    value: Yup.string().trim().required(),
  }),
});
