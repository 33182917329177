import React from 'react';
import classNames from 'classnames';
import { IBigAvatarProps } from './bigAvatar.types';
import './bigAvatar.scss';

export const BigAvatar = ({ userAvatar, className, isEditable = false, withDecreasing = false }: IBigAvatarProps) => {
  const elementClasses = classNames('big-avatar', className, {
    'big-avatar--editable': isEditable,
    'big-avatar--with-decreasing': withDecreasing,
  });

  const avatarBgString: string = isEditable
    ? `linear-gradient(to bottom, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.1) 55%, rgba(0,0,0,0.3) 100%),url(${userAvatar})`
    : `url(${userAvatar})`;

  return (
    <div
      className={elementClasses}
      style={userAvatar ? { backgroundImage: avatarBgString } : {}}
    />
  );
};
