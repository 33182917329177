import filesApi from './files.api';
import accountApi from './account.api';
import devicesApi from './devices.api';
import downloadManagerApi from './downloadManager.api';
import countriesApi from './countries.api';
import albumsApi from './albums.api';

export default {
  ...filesApi,
  ...accountApi,
  ...devicesApi,
  ...downloadManagerApi,
  ...countriesApi,
  ...albumsApi,
};
