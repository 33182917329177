import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Icon } from 'components/common/Icon';

export const BlockedAccount = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon type="BlockIcon" className="custom-modal__icon custom-modal__icon--red" />
        <div className="custom-modal__title">
          {t('context-modal__blocked-title')}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey="context-modal__blocked-text"
          // eslint-disable-next-line
          components={[<a href="/support"></a>]}
        />
      </Modal.Body>
    </Fragment>
  );
};
