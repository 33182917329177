import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { MenuItemProps } from './menu.types';

import './menuItem.scss';

export const MenuItem = (props: MenuItemProps) => {
  const { children, className } = props;
  let to;
  let handleClick;

  /* eslint-disable react/destructuring-assignment */
  if ('to' in props) to = props.to;
  if ('handleClick' in props) handleClick = props.handleClick;
  /* eslint-enable react/destructuring-assignment */
  const elementClasses = classNames('menu-item', className);

  if (to) {
    return (
      <NavLink to={to} onClick={handleClick} className={elementClasses} end>
        {children}
      </NavLink>
    );
  }

  return (
    <div onClick={handleClick} className={elementClasses}>
      {children}
    </div>
  );
};
