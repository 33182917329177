import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon } from 'components/common/Icon';

import { logout } from 'redusers/global/auth';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { ReactComponent as BinocularIcon } from 'static/icons/binocular.svg';
import { SettingsMenuDelete } from './settingsMenuDelete';
import { SettingsMenuLanguage } from './settingsMenuLanguage';
import { ISettingMenuComponentProps } from './types';

import './settignsMenu.scss';

export const SettingsMenuComponent = ({ handleLogout }: ISettingMenuComponentProps) => {
  const { t } = useTranslation();

  const handleHelpClick = () => sendGtmTrigger('sv2_click_help');

  return (
    <div className="settings-menu">
      <div className="settings-menu__wrap">
        <Link to="/devices" className="settings-menu__item" onClick={handleHelpClick}>
          <div className="settings-menu__item-type">
            <BinocularIcon className="settings-menu__item-icon" />
            <span className="settings-menu__item-name">{t('settings__my_devices')}</span>
          </div>
          <div className="settings-menu__item-meta">
            <Icon type="CheveronRight" className="settings-menu__item-cheveron" />
          </div>
        </Link>

        <SettingsMenuLanguage />

        <Link to="/support" className="settings-menu__item" onClick={handleHelpClick}>
          <div className="settings-menu__item-type">
            <Icon type="ChatOutline" className="settings-menu__item-icon" />
            <span className="settings-menu__item-name">{t('settings__support')}</span>
          </div>
          <div className="settings-menu__item-meta">
            <Icon type="CheveronRight" className="settings-menu__item-cheveron" />
          </div>
        </Link>

        <a href="/privacy" target="_blank" className="settings-menu__item">
          <div className="settings-menu__item-type">
            <Icon type="FileDocumentMultipleOutline" className="settings-menu__item-icon" />
            <span className="settings-menu__item-name">{t('settings__private_police')}</span>
          </div>
          <div className="settings-menu__item-meta">
            <Icon type="CheveronRight" className="settings-menu__item-cheveron" />
          </div>
        </a>

        <a href="/therms" target="_blank" className="settings-menu__item">
          <div className="settings-menu__item-type">
            <Icon type="FileDocumentMultipleOutline" className="settings-menu__item-icon" />
            <span className="settings-menu__item-name">{t('settings__license')}</span>
          </div>
          <div className="settings-menu__item-meta">
            <Icon type="CheveronRight" className="settings-menu__item-cheveron" />
          </div>
        </a>

        <SettingsMenuDelete />

        <div className="settings-menu__item" onClick={handleLogout}>
          <div className="settings-menu__item-type">
            <Icon type="LogoutIcon" className="settings-menu__item-icon" />
            <span className="settings-menu__item-name">{t('settings__logout')}</span>
          </div>
          <div className="settings-menu__item-meta">
            <Icon type="CheveronRight" className="settings-menu__item-cheveron" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SettingsMenu = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    sendGtmTrigger('sv2_log_out');
  };
  return <SettingsMenuComponent handleLogout={handleLogout} />;
};
