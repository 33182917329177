export interface ICreateAlbumData {
  uuid: string;
  name: string;
  files_ids?: string[]
  created_at?: string;
  updated_at?: string;
}

export enum AlbumTypes {
  favorite = 'favorite',
  custom = 'custom',
}

export interface IUpdateAlbumValues {
  name?: string;
  preview_file_id?: string;
}

export interface IAlbumRes {
  uuid: string;
  name: string;
  type: AlbumTypes;
  preview_url?: string;
  created_at?: string;
  updated_at?: string;
}

export interface IAlbumHintRes {
  id: string;
  name: string;
}
