import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useTimer from 'utils/hooks/useTimer';
import { firebaseProvider } from 'providers/firebaseProvider';

import { ISmsResend } from './types';

export const SmsResend = ({ phone, handleResend, stopTimer }: ISmsResend) => {
  const { t } = useTranslation();
  const [tooManyRequest, setTooManyRequest] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [seconds, isFinish, restart, stop] = useTimer(30);

  useEffect(() => {
    if (stopTimer) stop();
  }, [stop, stopTimer]);

  const sendPhone = async () => {
    if (!isClicked) {
      setIsClicked(true);
      try {
        await firebaseProvider.phoneAuth(phone);
      } catch (e) {
        console.log(e);
        // @ts-ignore
        if (e.code === 'auth/too-many-requests') {
          setTooManyRequest(true);
        }
      } finally {
        restart();
        setIsClicked(false);
        handleResend();
      }
    }
  };

  const elementClasses = classNames('action-timer', {
    'action-timer--end': isFinish,
    'action-timer--disable': isClicked,
  });

  if (tooManyRequest) {
    return (
      <div className={elementClasses}>
        <span className="invalid-feedback">{t('auth-page__many-request')}</span>
      </div>
    );
  }

  if (isFinish) {
    return (
      <div className={elementClasses}>
        {t('auth-page__times-is-out')}&nbsp;
        <span onClick={sendPhone} className="action-timer__link">{t('auth-page__send-code-again')}</span>
      </div>
    );
  }

  return (
    <div translate="no" className={elementClasses}>
      00:{seconds > 9 ? seconds : `0${seconds}`}
    </div>
  );
};
