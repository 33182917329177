import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountryRes } from 'api/countries.types';
import { IPayloadWithPagination } from 'types/pagination.types';
import { ICountriesState, ICountriesFetchingStartPayload } from './types';

const initialState: ICountriesState = {
  loading: false,
  data: [],
  pagination: {
    currentPage: 0,
    pageCount: 0,
    perPage: 0,
    totalCount: 0,
  },
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    countriesFetchingStart: (state, action: PayloadAction<ICountriesFetchingStartPayload>) => {
      state.loading = true;
    },
    countriesFetchingSuccess: (state, action: PayloadAction<IPayloadWithPagination<ICountryRes[]>>) => {
      state.loading = false;
      state.data = action.payload.data;
      state.pagination = action.payload.pagination;
    },

    countriesFetchingLoadMoreSuccess: (state, action: PayloadAction<IPayloadWithPagination<ICountryRes[]>>) => {
      state.loading = false;
      state.data = state.data.concat(action.payload.data);
      state.pagination = action.payload.pagination;
    },

    countriesFetchingFailed: (state) => {
      state.loading = false;
    },

    countriesClear: (state) => {
      state.loading = false;
      state.data = [];
      state.pagination = initialState.pagination;
    },
  },
});

export const {
  countriesFetchingStart,
  countriesFetchingFailed,
  countriesFetchingSuccess,
  countriesFetchingLoadMoreSuccess,
  countriesClear,
} = countriesSlice.actions;
export const countriesReducer = countriesSlice.reducer;
