import * as Yup from 'yup';
import i18next from 'i18next';

export default () => ({
  phone: Yup.string()
    .trim()
    .min(9, i18next.t('validator__phone-format-error'))
    .max(16, i18next.t('validator__phone-format-error'))
    .required(i18next.t('validator__phone-format-error')),

  sms_code: Yup.string()
    .trim()
    .min(6, i18next.t('validator__sms-code-format-error'))
    .max(6, i18next.t('validator__sms-code-format-error'))
    .required(i18next.t('validator__sms-code-format-error')),
});
