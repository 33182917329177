import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'redux-first-history';
import { Modal } from 'react-bootstrap';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import classNames from 'classnames';
import { IFileRes } from 'api/files.types';

import { Icon } from 'components/common/Icon';
import { Swipe } from 'components/common/swipe';
import { PhotoViewer } from 'components/viewers/PhotoViewer';
import { VideoViewer } from 'components/viewers/VideoViewer';

import {
  fileModalChoiceIndex,
  selectFileModalFromSearch,
  selectFileModalIsOpen,
  selectFileModalIndex,
  selectFileModalDataFromSearch,
} from 'redusers/fileModal';
import {
  selectFileByIndex,
  selectFileLastIndex,
  filesLoadModeStart,
  favoriteStatusChangeStart,
} from 'redusers/files/list';

import { selectFileIsFavorite } from 'redusers/albums';
import { FileTypes } from 'types/element.types';

import { ItemModalControl } from './itemModalControl';

import { IItemModalComponent } from './types';

import './itemModal.scss';

export const ItemModalComponent = ({
  isOpen,
  fileIndex,
  file,
  isFavorite,
  hasFilePreview,
  hasFileNext,
  setFavorite,
  fromSearch,
  handleClose,
  handlePreview,
  handleNext,
}: IItemModalComponent) => {
  const [revert, setRevert] = useState(false);

  const preview = useCallback(() => {
    setRevert(true);
    handlePreview(fileIndex);
  }, [handlePreview, fileIndex]);

  const next = useCallback(() => {
    setRevert(false);
    handleNext(fileIndex);
  }, [handleNext, fileIndex]);

  const handlePress = useCallback((e: any) => {
    if (e.key === 'ArrowLeft') preview();
    if (e.key === 'ArrowRight') next();
  }, [next, preview]);

  useEffect(() => {
    if (isOpen) document.addEventListener('keydown', handlePress);
    if (!isOpen) document.removeEventListener('keydown', handlePress);
    return () => document.removeEventListener('keydown', handlePress);
  }, [isOpen, fileIndex, handlePress]);

  const currentViewer = useMemo(() => {
    if (file.metadata.type === FileTypes.IMG) return <PhotoViewer file={file} />;
    if (file.metadata.type === FileTypes.VIDEO) return <VideoViewer file={file} />;
    return null;
  }, [file]);

  const previewArrow = useMemo(() => {
    if (!hasFilePreview) return null;
    return (
      <div className="item-modal__btn-preview-slider" onClick={preview}>
        <Icon type="CheveronLeft" />
      </div>
    );
  }, [hasFilePreview, preview]);

  const nextArrow = useMemo(() => {
    if (!hasFileNext) return null;
    return (
      <div className="item-modal__btn-next-slider" onClick={next}>
        <Icon type="CheveronRight" />
      </div>
    );
  }, [hasFileNext, next]);

  const animationClasses = classNames({
    'item-modal__animation-box': !revert,
    'item-modal__animation-box-revert': revert,
  });

  return (
    <Modal show={isOpen} onHide={handleClose} className="item-modal" backdropClassName="item-modal__backdrop">
      <div className="item-modal__slider-container">
        <ItemModalControl
          handleClose={handleClose}
          file={file}
          fromSearch={fromSearch}
          fileIndex={fileIndex}
          isFavorite={isFavorite}
          setFavorite={setFavorite}
        />
        <Swipe handleSwipeLeft={next} handleSwipeRight={preview}>
          <div className="item-modal__slider-box">
            <SwitchTransition>
              <CSSTransition key={fileIndex || 'item'} timeout={150} classNames={animationClasses}>
                {currentViewer}
              </CSSTransition>
            </SwitchTransition>
            {previewArrow}
            {nextArrow}
          </div>
        </Swipe>
      </div>
    </Modal>
  );
};

export const ItemModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectFileModalIsOpen);
  const fromSearch = useSelector(selectFileModalFromSearch);
  const fileIndex = useSelector(selectFileModalIndex);
  const dataFromSearch = useSelector(selectFileModalDataFromSearch);
  const fileFromList = useSelector(selectFileByIndex(fileIndex));
  const fileLastIndex: number = useSelector(selectFileLastIndex);

  const filePreview: IFileRes | undefined = useSelector(selectFileByIndex(fileIndex === null ? null : fileIndex - 1));
  const fileNext: IFileRes | undefined = useSelector(selectFileByIndex(fileIndex === null ? null : fileIndex + 1));

  const handleClose = useCallback(() => dispatch(goBack()), [dispatch]);

  const handlePreview = useCallback(
    (idx: number | null) => {
      if (idx !== null) dispatch(fileModalChoiceIndex(idx > 1 ? idx - 1 : 0));
    },
    [dispatch],
  );

  const handleNext = useCallback(
    (idx: number | null) => {
      if (idx !== null && fileLastIndex <= idx + 2) dispatch(filesLoadModeStart());
      if (idx !== null) dispatch(fileModalChoiceIndex(idx < fileLastIndex ? idx + 1 : fileLastIndex));
    },
    [fileLastIndex, dispatch],
  );

  let file: IFileRes | undefined;

  if (fromSearch) file = dataFromSearch;
  if (fileFromList) file = fileFromList;

  const isFavorite = useSelector(selectFileIsFavorite(file?.albums_uuids || []));
  const setFavorite = () => {
    if (file) dispatch(favoriteStatusChangeStart({ fileId: file.id, isFavorite, fromSearch }));
  };

  const preloadFiles = (preloadedFile: IFileRes | undefined): void => {
    if (preloadedFile) {
      const img = document.createElement('img');
      img.src = preloadedFile?.metadata?.type === FileTypes.VIDEO ? preloadedFile.preview_url : preloadedFile.data_url;
    }
  };

  useEffect(() => {
    preloadFiles(fileNext);
    preloadFiles(filePreview);
  }, [fileNext, filePreview]);

  if (!file) return null;

  const props = {
    isOpen,
    fromSearch,
    fileIndex,
    file,
    isFavorite,
    hasFilePreview: !!filePreview,
    hasFileNext: !!fileNext,
    handleClose,
    setFavorite,
    handlePreview,
    handleNext,
  };

  return <ItemModalComponent {...props} />;
};
