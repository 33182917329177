import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceItemTypesProps } from './deviceItem.types';
import './deviceItem.scss';

export const DeviceItem = ({ device, handleOpenDevice, hasNewFirmware }: IDeviceItemTypesProps) => {
  const { t } = useTranslation();
  const handleClick = () => {
    handleOpenDevice(device);
  };

  return (
    <div className="col-12 col-md-6 col-lg-4 devices-list__wrap-item">
      <div className="devices-list__item" onClick={handleClick}>
        {
          hasNewFirmware && (
            <span className="devices-list__item-has-update animate__fadeIn animate__animated animate__fast">
              {t('devices__update_is_available')}
            </span>
          )
        }
        <img src={device.image_url} alt={device.name} className="devices-list__item-img" />
        <h3>{device.name}</h3>
        <span className="devices-list__item-sku">s/n: {device.serial_number}</span>
      </div>
    </div>
  );
};
