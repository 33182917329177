import React, { useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createAlbumStart } from 'redusers/albums';
import { Icon } from 'components/common/Icon';
import { AlbumForm, THandleSubmit } from 'components/forms/AlmubForm';
import { INewAlbumRef } from './newAlbumModal.types';

export const NewAlbumModal = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useImperativeHandle(ref, (): INewAlbumRef => ({ openModal }));

  const handleSubmit: THandleSubmit = (values) => {
    dispatch(createAlbumStart({ values }));
    closeModal();
  };

  return (
    <Modal show={isOpen} onHide={closeModal} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Icon type="AddFolderIcon" className="custom-modal__icon" />
        <div className="custom-modal__title">
          {t('album__new-album-modal-title')}
        </div>
      </Modal.Header>
      <Modal.Body>
        <AlbumForm
          initialValues={{ name: '' }}
          handleSubmit={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
});
