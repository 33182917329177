import React, { useEffect, useRef } from 'react';

import { IVideoViewer } from './types';
import './videoViewer.scss';

export const VideoViewer = ({ file }: IVideoViewer) => {
  const videoBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (videoBoxRef.current) {
      videoBoxRef.current.innerHTML = '';
      const video = document.createElement('video');
      video.controls = true;
      video.poster = file.preview_url;
      video.innerHTML = `<source src=${file.data_url} />`;

      videoBoxRef.current.appendChild(video);
    }
  }, [file.preview_url, file.data_url]);

  return <div className="video-viewer" ref={videoBoxRef} />;
};
