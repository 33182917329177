import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FullWidthLayout } from 'components/layouts/FullWidthLayout';
import { SettingsHeader } from 'components/settings/SettingsHeader';
import { SettingsMenu } from 'components/settings/SettingsMenu';

import { updateQuotaStart } from 'redusers/global/quota';

export const SettingsPageComponent = () => {
  return (
    <FullWidthLayout hiddenAvatar>
      <SettingsHeader />
      <SettingsMenu />
    </FullWidthLayout>
  );
};

export const SettingsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateQuotaStart());
  }, [dispatch]);

  return <SettingsPageComponent />;
};
