import * as Yup from 'yup';
import i18next from 'i18next';

export default () => ({
  name: Yup.string().trim(),
  country: Yup.object({
    name: Yup.string().trim().required(i18next.t('validator__form-required')),
    value: Yup.string().trim().required(i18next.t('validator__form-required'))
  }),
  email: Yup.string().trim().email().required(i18next.t('validator__form-required')),
  description: Yup.string().trim().required(i18next.t('validator__form-required')),
});
