import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
/* eslint-disable import/extensions,import/no-unresolved */
import {
  countriesFetchingStart,
  countriesClear,
  selectAllCountries,
  selectCountriesLoading,
  selectHasMoreCountries
} from '../../../../redusers/global/countries';
/* eslint-enable import/extensions,import/no-unresolved */
import SelectWithSearch from '../SelectWithSearch';

// todo rewrite in TypeScript
const SelectCountry = (props) => {
  const dispatch = useDispatch();
  const countries = useSelector(selectAllCountries);
  const loading = useSelector(selectCountriesLoading);
  const hasMore = useSelector(selectHasMoreCountries);
  const getData = useCallback((query) => dispatch(countriesFetchingStart({ query })), [dispatch]);
  const loadMore = useCallback((query, page) => dispatch(countriesFetchingStart({ query, page })), [dispatch]);

  useEffect(() => {
    dispatch(countriesFetchingStart());

    return () => dispatch(countriesClear());
  }, [dispatch]);

  const newProps = {
    ...props,
    getData,
    loadMore,
    hasMore,
    data: countries.map((item) => ({ name: item.name, value: item.id })),
    loading,
  };
  return <SelectWithSearch {...newProps} />;
};

export default SelectCountry;
