import React, { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { ElementTypes } from 'types/element.types';
import isMobile from 'utils/isMobile';
import { AlbumsList } from './AlbumsList';
import { IAlbumsScreenComponentProps } from './albumsScreen.types';
import './albumsScreen.scss';

export const AlbumsScreenComponent = ({ redirectToAlbumPage }: IAlbumsScreenComponentProps) => {
  const handleOpenEvent = (e: MouseEvent) => {
    const currItem = e.nativeEvent.composedPath().find(
      // @ts-ignore
      (element) => element?.dataset?.ctxMenuType === ElementTypes.ALBUM,
    );

    if (currItem) {
      // @ts-ignore
      redirectToAlbumPage(currItem?.dataset?.itemId);
    }
  };

  return (
    <div
      className="albums-screen"
      onDoubleClick={isMobile() ? undefined : handleOpenEvent}
      onClick={isMobile() ? handleOpenEvent : undefined}
    >
      <div className="albums-screen__wrap">
        <AlbumsList />
      </div>
    </div>
  );
};

export const AlbumsScreen = () => {
  const dispatch = useDispatch();

  const redirectToAlbumPage = (albumUuid: string) => {
    dispatch(push(`/albums/${albumUuid}`));
  };

  return <AlbumsScreenComponent redirectToAlbumPage={redirectToAlbumPage} />;
};
