import React from 'react';
import { AlbumTypes } from 'api/albums.types';
import { ISidebarAlbumListItemProps } from './sidebarAlbumList.types';
import { MenuItem, MenuItemIcon, MenuItemImg } from '../common/Menu';

export const SidebarAlbumListItem = ({ album, handleClickByItem }: ISidebarAlbumListItemProps) => {
  const isFavorite = album.type === AlbumTypes.favorite;

  const renderIcon = () => {
    if (isFavorite) return <MenuItemIcon type="Star" />;
    if (!album.preview_url) return <MenuItemIcon type="ImageOutline" />;

    return <MenuItemImg imgUrl={album.preview_url} />;
  };

  return (
    <MenuItem to={`/albums/${album.uuid}`} handleClick={handleClickByItem}>
      {renderIcon()}
      <span className="">{album.name}</span>
    </MenuItem>
  );
};
