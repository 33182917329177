import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';
import { deleteFilesFromAlbumStart } from 'redusers/files/list';
import { selectCurrentAlbumId } from 'redusers/albums';
import { selectCtxModalData, selectCtxModalLoading, selectCtxModalIsMany } from 'redusers/ctxModal';
import { IDeleteFIleFromAlbumComponentProps } from './deleteFIleFromAlbum.types';

export const DeleteFileFromAlbumComponent = ({ isMany, loading, removeHandler }: IDeleteFIleFromAlbumComponentProps) => {
  const { t } = useTranslation();

  const renderTitle = () => {
    if (isMany) return t('album__delete_files_from_album_modal_title');
    return t('album__delete_file_from_album_modal_title');
  };

  const renderDesc = () => {
    if (isMany) return t('album__delete_files_from_album_modal_desc');
    return t('album__delete_file_from_album_modal_desc');
  };

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon type="DeleteOutline" className="custom-modal__icon" />
        <div className="custom-modal__title">
          {renderTitle()}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          {renderDesc()}
        </div>
        <div className="custom-modal__btn-box">
          <button
            onClick={removeHandler}
            className="btn btn-danger sv-btn custom-modal__btn-margin"
            disabled={loading}
          >
            {t('global__remove')}
          </button>
        </div>
      </Modal.Body>
    </Fragment>
  );
};

export const DeleteFileFromAlbum = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectCtxModalLoading);
  const isMany = useSelector(selectCtxModalIsMany);
  const modalData = useSelector(selectCtxModalData);
  const currentAlbumId = useSelector(selectCurrentAlbumId);

  const removeHandler = useCallback(() => {
    if (currentAlbumId) {
      const payload: { albumUuid: string, fileIds?: string[] } = { albumUuid: currentAlbumId };
      if (!isMany && modalData) payload.fileIds = [modalData?.id];

      dispatch(deleteFilesFromAlbumStart(payload));
    }
  }, [currentAlbumId, dispatch, isMany, modalData]);

  const handleKeyUp = useCallback((e: any) => {
    if (e.key === 'Enter') removeHandler();
  }, [removeHandler]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [handleKeyUp]);

  return <DeleteFileFromAlbumComponent isMany={isMany} loading={loading} removeHandler={removeHandler} />;
};
