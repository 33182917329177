import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';
import { IFileRes } from 'api/files.types';
import { IFileModalState } from './types';

export const selectFileModalState = ({ fileModal }: TRootState): IFileModalState => fileModal;

export const selectFileModalIsOpen = createSelector(
  selectFileModalState,
  (state: IFileModalState): boolean => state.isOpen,
);

export const selectFileModalIndex = createSelector(
  selectFileModalState,
  (state: IFileModalState): number | null => state.fileIdx,
);

export const selectFileModalFromSearch = createSelector(
  selectFileModalState,
  (state: IFileModalState): boolean => state.fromSearch,
);

export const selectFileModalDataFromSearch = createSelector(
  selectFileModalState,
  (state: IFileModalState): IFileRes | undefined => state.dataFromSearch,
);