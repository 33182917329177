import React, { useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import validators from '../../../validators';
import fileMap from '../../../validators/fileMap';
import { FILE_NAME_MAX_LENGTH, FILE_NAME_MIN_LENGTH } from '../../../constants/validator';

import './index.scss';

// todo rewrite in TypeScript
const FileNameInput = ({
  field,
  form,
  format,
  innerRef,
  ...props
}) => {
  const { errors, touched, submitCount } = form;
  const { name } = field;

  return (
    <div className="form-group file-form__name-input">
      <input
        name={name}
        {...field}
        {...props}
        ref={innerRef}
        type="text"
        autoComplete="off"
        maxLength={FILE_NAME_MAX_LENGTH - format.length}
        className={`form-control ${errors[name] && touched[name] && submitCount ? 'is-invalid' : ''}`}
      />
      {
        errors[name] && touched[name] && submitCount
          ? <div className="invalid-feedback">{errors[name]}</div>
          : <small className="form-text" />
      }
      <div className="file-form__format">{format}</div>
    </div>
  );
};

const FileForm = ({ handleSubmit, initialValues, submitText, loading }) => {
  const { t } = useTranslation();
  const { name } = initialValues;

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const splitName = (fileName) => {
    const arr = fileName.split('.');
    const format = `.${arr.pop()}`;
    const realName = arr.join('.');
    return [realName, format];
  };

  const [realName, format] = splitName(name);

  const submit = (values) => {
    // eslint-disable-next-line no-shadow
    const { name } = values;
    handleSubmit({ ...values, name: name + format });
  };

  const FileSchema = validators(fileMap, ['name']);

  return (
    <Formik
      initialValues={{ ...initialValues, name: realName }}
      validationSchema={FileSchema}
      onSubmit={submit}
    >
      {({ values }) => (
        <Form className="file-form">
          <Field
            component={FileNameInput}
            name="name"
            placeholder={t('form__file-name-placeholder')}
            format={format}
            innerRef={inputRef}
          />
          <div className="custom-modal__btn-box">
            <button
              className="btn sv-btn-blue sv-btn"
              type="submit"
              disabled={loading || values.name.length < FILE_NAME_MIN_LENGTH}
            >
              {submitText || t('global__create')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

FileForm.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  loading: PropTypes.bool,
};

FileForm.defaultProps = {
  initialValues: { name: '' },
  submitText: '',
  loading: false
};

export default FileForm;
