import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { concatMap, catchError, map, debounceTime } from 'rxjs/operators';
import { getPagination } from 'utils/getPagination';
import API from 'api';
import { TRootState } from 'redusers';

import {
  countriesFetchingStart,
  countriesFetchingFailed,
  countriesFetchingLoadMoreSuccess,
  countriesFetchingSuccess,
} from './countries.slice';

export const getCountriesEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getCountries },
) => action$.pipe(
  ofType(countriesFetchingStart),
  debounceTime(300),
  concatMap((action) => {
    return from(getCountries(action?.payload?.query, action?.payload?.page)).pipe(
      map(({ data, status, headers }) => {
        if (status !== 200) return countriesFetchingFailed();
        const payload = { data, pagination: getPagination(headers) };
        return action?.payload?.page ? countriesFetchingLoadMoreSuccess(payload) : countriesFetchingSuccess(payload);
      }),
      catchError(() => of(countriesFetchingFailed())),
    );
  }),
);

export const countriesEpics = [getCountriesEpic];
