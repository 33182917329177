import { Epic, ofType } from 'redux-observable';
import { PayloadAction } from '@reduxjs/toolkit';
import { from, of } from 'rxjs';
import {
  catchError,
  switchMap,
  map,
  debounceTime,
} from 'rxjs/operators';
import { TRootState } from 'redusers';
import API from 'api';
import {
  albumHintsFetchingStart,
  albumHintsFetchingSuccess,
  albumHintsClear,
} from './albumHints.slice';

export const getAlbumHintsEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getAlbumHints },
) => action$.pipe(
  ofType(albumHintsFetchingStart),
  debounceTime(200),
  switchMap<PayloadAction<string | undefined>, any>(({ payload }) => {
    return from(getAlbumHints(payload)).pipe(
      map(({ data, status }) => {
        if (status === 200) {
          return albumHintsFetchingSuccess(data);
        }
        return albumHintsClear();
      }),
      catchError(() => {
        return of(albumHintsClear());
      }),
    );
  }),
);

export const albumHintsEpics = [
  getAlbumHintsEpic,
];