import React, { useEffect } from 'react';
import { ISocialBtnsProps } from './socialBtns.types';
import './socialBtns.scss';

export const SocialBtns = ({ url, className, title = ' ' }: ISocialBtnsProps) => {
  useEffect(() => {
    /** @ts-ignore скрипт загружен в index.html */
    window.addthis.layers.refresh();
  });

  return (
    <div className={`social-btns ${className}`}>
      <div className="addthis_inline_share_toolbox" data-url={url} data-title={title} />
    </div>
  );
};
