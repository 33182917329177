import React from 'react';
import PropTypes from 'prop-types';

import StaticTextBlock from './StaticTextBlock';

import './index.scss';

const StaticText = ({ title, content, withoutNumber, className }) => {
  return (
    <div className={`static-text ${className}`}>
      <div className="static-text__title">{title}</div>
      {content.map((item, idx) => (
        <StaticTextBlock
          key={`block-${idx}`}
          number={idx + 1}
          title={item.title}
          className={item.className}
          paragraphs={item.paragraphs}
          withoutNumber={withoutNumber}
        />
      ))}
    </div>
  );
};

StaticText.propTypes = {
  content: PropTypes.array.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
};

StaticText.defaultProps = {
  title: '',
  className: '',
};

export default StaticText;
