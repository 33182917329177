import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { exhaustMap, catchError, map } from 'rxjs/operators';

import API from 'api';
import { TRootState } from 'redusers';
import { getQuota } from 'utils/getQuota';
import { updateQuotaSuccess, updateQuotaFailed, updateQuotaStart } from './quota.slice';

export const updateQuotaEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getAccount },
) => action$.pipe(
  ofType(updateQuotaStart),
  exhaustMap(() => {
    return from(getAccount()).pipe(
      map(({ status, headers }) => {
        if (status !== 200) updateQuotaFailed();
        return updateQuotaSuccess(getQuota(headers));
      }),
      catchError(() => of(updateQuotaFailed())),
    );
  }),
);

export const quotaEpics = [updateQuotaEpic];
