import React from 'react';
import { ISocialBtnProps } from './types';

export const SocialBtn = ({ className, onClick, children }: ISocialBtnProps) => {
  const handleClick = () => onClick();

  return (
    <div
      className={`social-login__item ${className || ''}`}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};
