import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/extensions, import/no-unresolved
import { Icon } from '../Icon';

// todo rewrite in TypeScript
const ContextMenuHeader = ({ elName, elCount, isMany, closeFn }) => {
  const { t } = useTranslation();

  return (
    <div className="context-menu__header">
      <div className="context-menu__icon" onClick={closeFn}>
        <Icon type="CloseIcon" />
      </div>
      <div className="context-menu__header-name">
        { isMany ? `${t('selected-bar__select-label')} ${elCount}` : elName }
      </div>
    </div>
  );
};

ContextMenuHeader.propTypes = {
  closeFn: PropTypes.func.isRequired,
  elName: PropTypes.string,
  elCount: PropTypes.number,
  isMany: PropTypes.bool,
};

ContextMenuHeader.defaultProps = {
  elName: '',
  elCount: 1,
  isMany: false,
};

export default ContextMenuHeader;
