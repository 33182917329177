import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ReactCodeInput from 'react-verification-code-input-2';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import validators from 'validators';
import authMap from 'validators/authMap';
import { authFetchingStart } from 'redusers/global/auth';
import { AuthSocial } from 'types/auth.types';
import { firebaseProvider } from 'providers/firebaseProvider';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { SmsResend } from './smsResend';
import { IPhoneLoginModalComponentProps, IPhoneLoginModalProps } from './types';

export const PhoneLoginModalComponent = ({
  isOpen,
  toggle,
  phone,
  handleLogin,
}: IPhoneLoginModalComponentProps) => {
  const { t } = useTranslation();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [expiredError, setExpiredError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const focusAction = () => {
    const inputBox = document.getElementsByClassName('phone-login-modal__input');
    const inputsArr = inputBox[0]?.getElementsByTagName('input');
    if (inputsArr[0]) inputsArr[0].focus();
  };

  const AuthSchema = validators(authMap, ['sms_code']);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validator = async () => {
    return AuthSchema.validate({ sms_code: code })
      .then(() => {
        setError(false);
        setErrorText('');
        return false;
      })
      .catch(() => {
        setError(true);
        setErrorText('');
        return true;
      });
  };

  const handleChange = (value: string) => setCode(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sendCode = async () => {
    try {
      const token = await firebaseProvider.confirmPhoneAuth(code);
      if (token) handleLogin(token);
    } catch (e) {
      console.log(e);
      // @ts-ignore
      if (e.code === 'auth/invalid-verification-code') {
        setError(true);
        setErrorText(t('invalid_sms_code'));
      }
      // @ts-ignore
      if (e.code === 'auth/code-expired') {
        setError(true);
        setErrorText(t('auth-page__code-expired'));
        setExpiredError(true);
      }
      setIsComplete(false);
    } finally {
      setIsPending(false);
    }
  };

  const handleClick = useCallback(async () => {
    const currError = await validator();
    if (!currError) await sendCode();
  }, [sendCode, validator]);

  const handleResend = () => {
    setCode('');
    setError(false);
    setExpiredError(false);
    setErrorText('');
    focusAction();
  };

  const handleComplete = () => {
    setIsComplete(true);
  };

  const closeModal = () => {
    toggle();
    setIsComplete(false);
    setCode('');
    setError(false);
    setExpiredError(false);
  };

  useEffect(() => {
    // фокус на первый инпут
    if (isOpen) focusAction();
  }, [isOpen]);

  useEffect(() => {
    if (isComplete) handleClick();
  // eslint-disable-next-line
  }, [isComplete]);

  const inputsClasses = classNames('phone-login-modal__input', {
    'phone-login-modal__input--error': error,
  });

  return (
    <Modal show={isOpen} onHide={closeModal} className="phone-login-modal" centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <div>{t('auth-page__enter-sms')}</div>
        <ReactCodeInput
          className={inputsClasses}
          type="number"
          fields={6}
          values={code.split('')}
          onChange={handleChange}
          onComplete={handleComplete}
          autoFocus
        />
        {
          error
            ? (
              <div className="invalid-feedback">
                { errorText || t('auth-page__wrong-sms') }
              </div>
            )
            : null
        }
        <button className="btn sv-btn-blue sv-btn" onClick={handleClick} disabled={isPending || code.length < 6}>
          {t('auth-page__btn')}
        </button>
        <SmsResend
          phone={phone}
          handleResend={handleResend}
          stopTimer={expiredError}
        />
      </Modal.Body>
    </Modal>
  );
};

export const PhoneLoginModal = (props: IPhoneLoginModalProps) => {
  const dispatch = useDispatch();

  const handleLogin = (token: string) => {
    dispatch(authFetchingStart({ token, social: AuthSocial.phone }));
    sendGtmTrigger('sv2_log_in_phone_complete');
  };

  const newProps = { ...props, handleLogin };

  return <PhoneLoginModalComponent {...newProps} />;
};
