import { createAsyncThunk } from '@reduxjs/toolkit';

import { devicesCatalogFetchingStart } from './devicesCatalog.slice';
import { TRootState } from '../../index';

// получаем данные каталога, только если они не были получены раньше
export const fetchingDevicesCatalog = createAsyncThunk(
  'devicesCatalog/getDevicesCatalog',
  async (_, { dispatch, getState }) => {
    const state = getState() as TRootState;
    if (state.global.devicesCatalog.data.length === 0) dispatch(devicesCatalogFetchingStart());
  },
);
