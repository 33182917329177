import { filtersClear } from './filters';

export const changeRouterMiddleware = (store: any) => (next: any) => (action: any) => {
  const { router } = store.getState();

  if (action.type === '@@router/LOCATION_CHANGE' && router.location) {
    const oldHash: string = router.location.hash;
    const oldKey: string = router.location.key;
    const newHash: string = action.payload.location.hash;
    const newKey: string = action.payload.location.key;
    if (newHash !== '#viewer' && oldHash !== '#viewer' && oldKey !== newKey) {
      store.dispatch(filtersClear());
    }
  }
  return next(action);
};
