export enum ModalTypes {
  CTX_MODAL_RENAME = 'CTX_MODAL_RENAME',
  CTX_MODAL_SHARE = 'CTX_MODAL_SHARE',
  CTX_MODAL_DETAILS = 'CTX_MODAL_DETAILS',
  CTX_MODAL_REMOVE = 'CTX_MODAL_REMOVE',
  CTX_MODAL_SETTINGS_DELETE_ACCOUNT = 'CTX_MODAL_SETTINGS_DELETE_ACCOUNT',
  CTX_MODAL_SETTINGS_LANGUAGE = 'CTX_MODAL_SETTINGS_LANGUAGE',
  CTX_MODAL_BLOCKED_ACCOUNT = 'CTX_MODAL_BLOCKED_ACCOUNT',

  CTX_MODAL_ALBUM_RENAME = 'CTX_MODAL_ALBUM_RENAME',
  CTX_MODAL_ALBUM_REMOVE = 'CTX_MODAL_ALBUM_REMOVE',
  CTX_MODAL_ALBUM_FILE_TO_ALBUM = 'CTX_MODAL_ALBUM_FILE_TO_ALBUM',
  CTX_MODAL_ALBUM_DELETE_FILE_FROM_ALBUM = 'CTX_MODAL_ALBUM_DELETE_FILE_FROM_ALBUM',
}
