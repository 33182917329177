import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectAuthAccountName, selectAuthCountryName, selectAuthAccountAvatar } from 'redusers/global/auth';
import { EditProfile } from 'components/settings/EditProfile';
import { BigAvatar } from 'components/common/BigAvatar';
import { QuotaBox } from './quotaBox';
import { ISettingsHeaderComponentProps } from './settingsHeader.types';

import './settingsHeader.scss';

export const SettingsHeaderComponent = (
  { userName, userAvatar, countryName }: ISettingsHeaderComponentProps,
) => {
  const { t } = useTranslation();

  return (
    <div className="settings-header">
      <div className="settings-header__wrap">
        <BigAvatar className="settings-header__avatar" userAvatar={userAvatar} withDecreasing />
        <div className="settings-header__info-box">
          <div className="settings-header__name">
            {userName || t('profile__placeholder')}
          </div>
          <div className="settings-header__country">
            {countryName}
          </div>
          <EditProfile />
        </div>
        <div className="settings-header__delimiter d-block d-md-none" />
      </div>
      <div className="settings-header__wrap">
        <QuotaBox className="settings-header__quota-mobile " />
      </div>
    </div>
  );
};

export const SettingsHeader = () => {
  const userName = useSelector(selectAuthAccountName);
  const userAvatar = useSelector(selectAuthAccountAvatar);
  const countryName = useSelector(selectAuthCountryName);

  const props = { userName, userAvatar, countryName };

  return <SettingsHeaderComponent {...props} />;
};
