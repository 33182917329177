import React from 'react';

import formatDate from 'utils/formatDate';
import { FileTypes } from 'types/element.types';
import { ReactComponent as VideoIcon } from 'static/icons/video.svg';
import { ReactComponent as PicIcon } from 'static/icons/pic.svg';

import { ISearchResultProps } from './types';
import './resultItem.scss';

export const ResultItem = ({ item, handleOpenViewer }: ISearchResultProps) => {
  const handleClick = () => handleOpenViewer(item.id);

  const renderIcon = () => {
    if (item.metadata.type === FileTypes.VIDEO) return <VideoIcon />;
    return <PicIcon />;
  };

  return (
    <div className="result-item" onClick={handleClick}>
      <div className="result-item__info">
        <div className="result-item__icon-box">
          {renderIcon()}
        </div>
        <span className="result-item__info-text">
          {item.name}
        </span>
      </div>
      <div className="result-item__date">
        {formatDate(item.metadata.created_at)}
      </div>
    </div>
  );
};
