import React, { Fragment, MouseEventHandler, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';
import { SearchInput } from 'components/common/SearchInput';
import { NewAlbumTile } from 'components/common/NewAlbumTile';
import { AlbumForm, THandleSubmit } from 'components/forms/AlmubForm';
import { closeCtxModal } from 'redusers/ctxModal';
import { createAlbumStart, selectAlbumsList } from 'redusers/albums';
import { addFileToAlbumStart } from 'redusers/files/list';
import { AddFileToAlbumItem } from './addFileToAlbumItem';
import { IAddFileToAlbumComponentProps } from './addFileToAlbums.types';

import './addFileToalbum.scss';

export const AddFileToAlbumComponent = ({ albums, handleAdd, handleSubmitNewAlbum }: IAddFileToAlbumComponentProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [isNewAlbum, setIsNewAlbum] = useState(false);

  const toggleOpenAddAlbumForm = () => setIsNewAlbum(!isNewAlbum);

  const handleClick: MouseEventHandler = (e) => {
    // @ts-ignore
    const currAlbum = e.nativeEvent.composedPath().find((element) => element?.dataset?.albumUuid);
    // @ts-ignore
    if (currAlbum) handleAdd(currAlbum.dataset.albumUuid);
  };

  const currentAlbums = albums.filter((album) => {
    return album.name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  });

  const renderModalBody = () => {
    if (isNewAlbum) {
      return (
        <AlbumForm
          initialValues={{ name: '' }}
          handleSubmit={handleSubmitNewAlbum}
        />
      );
    }
    return (
      <>
        <SearchInput
          value={search}
          handleValueChange={setSearch}
          placeholder={t('search-albums__placeholder')}
          className="add-file-to-album__search"
        />
        <div className="add-file-to-album" onClick={handleClick}>
          <NewAlbumTile handleOpenAddAlbumForm={toggleOpenAddAlbumForm} />
          {currentAlbums.map((album) => <AddFileToAlbumItem key={album.uuid} album={album} />)}
        </div>
      </>
    );
  };

  const renderIcon = () => {
    if (isNewAlbum) {
      return (
        <Icon
          type="ArrowLeft"
          className="custom-modal__icon custom-modal__icon--clickable"
          onClick={toggleOpenAddAlbumForm}
        />
      );
    }
    return <Icon type="PlusBoxMultiple" className="custom-modal__icon" />;
  };

  return (
    <Fragment>
      <Modal.Header closeButton>
        {renderIcon()}
        <div className="custom-modal__title">
          {isNewAlbum ? t('album__new-album-modal-title') : t('album__add-file-to-album-modal-title')}
        </div>
      </Modal.Header>
      <Modal.Body className="add-file-to-album__body">
        {renderModalBody()}
      </Modal.Body>
    </Fragment>
  );
};

export const AddFileToAlbum = () => {
  const dispatch = useDispatch();

  const handleAdd = (albumUuid: string) => {
    dispatch(addFileToAlbumStart({ albumUuid }));
    dispatch(closeCtxModal());
  };

  const handleSubmitNewAlbum: THandleSubmit = (values) => {
    dispatch(createAlbumStart({ values, withSelectedFiles: true }));
    dispatch(closeCtxModal());
  };

  const albums = useSelector(selectAlbumsList);

  return <AddFileToAlbumComponent albums={albums} handleAdd={handleAdd} handleSubmitNewAlbum={handleSubmitNewAlbum} />;
};
