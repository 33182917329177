import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { Icon } from 'components/common/Icon';
import { openCtxModal } from 'redusers/ctxModal';
import { createDownloadProcessStart } from 'redusers/downloadManager';
import { favoriteStatusChangeStart } from 'redusers/files/list';
import { updateAlbumStart, selectCurrentAlbum } from 'redusers/albums';
import toast from 'utils/toast';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { useIsSpecialAlbumByURL } from 'utils/hooks/useIsSpecialAlbum';
import { ModalTypes } from 'types/modal.types';
import { ElementTypes } from 'types/element.types';
import { IContextMenuFileItemsProps, IContextMenuFileItemsComponentProps } from './contextMenu.types';

export const ContextMenuFileItemsComponent = ({
  elShare,
  elUrl,
  isMany,
  itemFromSearch,
  openRename,
  openShare,
  openViewDetails,
  openAddFileToAlbum,
  openRemove,
  setFavorite,
  copyLink,
  downloadOne,
  bulkDownload,
  openDeleteFileFromAlbum,
  makeAlbumCover,
}: IContextMenuFileItemsComponentProps) => {
  const { t } = useTranslation();
  const [isAlbum, isSpecialAlbum] = useIsSpecialAlbumByURL();

  const copyLinkHandler = () => {
    copyLink();
    toast(t('toast__link-copied'), { type: 'info' });
  };

  const renderShareElements = () => {
    if (isMany) return null;

    return (
      <Fragment>
        <div className="context-menu-block__item" onClick={openShare}>
          <Icon type="AccountMultipleOutline" />
          {t('context-menu__share')}
        </div>
        {
          elShare !== 'false'
            ? (
              <div className="context-menu-block__item" onClick={copyLinkHandler}>
                <Icon type="ContentCopy" />
                {t('context-menu__get-link')}
              </div>
            )
            : null
        }
      </Fragment>
    );
  };

  const renderRename = () => {
    if (isMany) return null;

    return (
      <div className="context-menu-block__item" onClick={openRename}>
        <Icon type="SquareEditOutline" />
        {t('context-menu__rename')}
      </div>
    );
  };

  const renderFavorite = () => {
    return (
      <div className="context-menu-block__item" onClick={setFavorite}>
        <Icon type="StarOutline" />
        {t('context-menu__favorite')}
      </div>
    );
  };

  const renderMakeCover = () => {
    if (isSpecialAlbum || !isAlbum || isMany) return null;

    return (
      <div className="context-menu-block__item" onClick={makeAlbumCover}>
        <Icon type="ImageOutline" />
        {t('context-menu__make_album_cover')}
      </div>
    );
  };

  const renderAddFileToAlbum = () => {
    return (
      <div className="context-menu-block__item" onClick={openAddFileToAlbum}>
        <Icon type="PlusBoxMultiple" />
        {t('context-menu__add_to_album')}
      </div>
    );
  };

  const renderDeleteFileToAlbum = () => {
    if (!isAlbum || isSpecialAlbum || itemFromSearch) return null;

    return (
      <div className="context-menu-block__item" onClick={openDeleteFileFromAlbum}>
        <Icon type="MinusBoxMultiple" />
        {t('context-menu__delete_from_album')}
      </div>
    );
  };

  const renderDownload = () => {
    if (isMany) {
      return (
        <div className="context-menu-block__item" onClick={bulkDownload}>
          <Icon type="DownloadOutline" />
          {t('context-menu__download')}
        </div>
      );
    }

    return (
      <a href={elUrl} className="context-menu-block__item" download onClick={downloadOne}>
        <Icon type="DownloadOutline" />
        {t('context-menu__download')}
      </a>
    );
  };

  const renderDetails = () => {
    return (
      <div className="context-menu-block__item" onClick={openViewDetails}>
        <Icon type="InformationOutline" />
        {t('context-menu__details')}
      </div>
    );
  };

  const renderRemove = () => {
    return (
      <div className="context-menu-block__item" onClick={openRemove}>
        <Icon type="DeleteOutline" />
        {t('context-menu__remove')}
      </div>
    );
  };

  return (
    <>
      {renderFavorite()}
      {renderAddFileToAlbum()}
      {renderMakeCover()}
      {renderRename()}
      {renderShareElements()}
      {renderDownload()}
      {renderDetails()}
      {renderDeleteFileToAlbum()}
      {renderRemove()}
    </>
  );
};

export const ContextMenuFileItems = ({
  elShare,
  elIdx,
  elId,
  itemFromSearch,
  isMany,
  isFavorite,
  ...props
}: IContextMenuFileItemsProps) => {
  const dispatch = useDispatch();
  const currentAlbum = useSelector(selectCurrentAlbum);

  const open = (modalType: ModalTypes) => {
    dispatch(openCtxModal({
      elementType: ElementTypes.FILE,
      modalType,
      fromSearch: itemFromSearch,
      elementIdx: parseInt(elIdx),
      isMany,
    }));
  };
  const openRename = () => open(ModalTypes.CTX_MODAL_RENAME);
  const openShare = () => open(ModalTypes.CTX_MODAL_SHARE);
  const openAddFileToAlbum = () => open(ModalTypes.CTX_MODAL_ALBUM_FILE_TO_ALBUM);
  const openDeleteFileFromAlbum = () => open(ModalTypes.CTX_MODAL_ALBUM_DELETE_FILE_FROM_ALBUM);
  const openViewDetails = () => {
    open(ModalTypes.CTX_MODAL_DETAILS);

    if (isMany) sendGtmTrigger('sv2_group_open_details');
    else sendGtmTrigger('sv2_file_open_details');
  };

  const openRemove = () => open(ModalTypes.CTX_MODAL_REMOVE);

  const setFavorite = () => {
    if (itemFromSearch) dispatch(favoriteStatusChangeStart({ fileId: elId, isFavorite, fromSearch: itemFromSearch }));
    else dispatch((favoriteStatusChangeStart({})));
  };

  const makeAlbumCover = () => {
    if (currentAlbum) {
      dispatch(updateAlbumStart({ uuid: currentAlbum.uuid, values: { preview_file_id: elId } }));
    }
  };

  const copyLink = () => {
    copy(elShare);
    sendGtmTrigger('sv2_file_copy_link');
  };

  const downloadOne = () => sendGtmTrigger('sv2_file_download');
  const bulkDownload = () => {
    dispatch(createDownloadProcessStart());
    sendGtmTrigger('sv2_group_download');
  };

  const newProps = {
    ...props,
    elId,
    elShare,
    elIdx,
    isFavorite,
    itemFromSearch,
    isMany,
    openRename,
    openShare,
    openViewDetails,
    openRemove,
    setFavorite,
    copyLink,
    downloadOne,
    bulkDownload,
    openAddFileToAlbum,
    openDeleteFileFromAlbum,
    makeAlbumCover,
  };

  return <ContextMenuFileItemsComponent {...newProps} />;
};
