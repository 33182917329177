import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { IFileRes } from 'api/files.types';
import { ElementTypes } from 'types/element.types';
import { ICtxModalStore, ICtxAlbumModalStore } from './types';
import { openCtxModal } from './ctxModal.thunk';

const initialState: ICtxModalStore | ICtxAlbumModalStore = {
  elementType: null,
  loading: false,
  isOpen: false,
  fromSearch: false,
  modalType: undefined,
  elementIdx: undefined,
  data: undefined,
  isMany: false,
  fileIndexes: [],
};

export const closeCtxModal = createAction('ctxModal/closeCtxModal');

export const ctxModalSlice = createSlice({
  name: 'ctxModal',
  initialState,
  reducers: {
    updateCtxModalLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    updateCtxModalData: (state, { payload }: PayloadAction<IFileRes | undefined>) => {
      state.data = payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(openCtxModal.fulfilled, (state, { payload }) => {
        state.isOpen = true;
        state.modalType = payload.modalType;

        if (payload.elementType === ElementTypes.FILE) {
          state.elementIdx = payload.elementIdx;
          state.fromSearch = payload.fromSearch;
          state.data = payload.data;
          state.isMany = payload.isMany;
          state.fileIndexes = payload.fileIndexes;
        }

        if (payload.elementType === ElementTypes.ALBUM) {
          state.elementIdx = payload.elementIdx;
        }
      })
      .addMatcher(
        (action) => action.type === closeCtxModal.type || action.type === '@@router/LOCATION_CHANGE',
        (state) => {
          state.loading = initialState.loading;
          state.isOpen = initialState.isOpen;
          state.fromSearch = initialState.fromSearch;
          state.modalType = initialState.modalType;
          state.elementIdx = initialState.elementIdx;
          state.data = initialState.data;
          state.isMany = initialState.isMany;
          state.fileIndexes = initialState.fileIndexes;
        },
      );
  },
});

export const { updateCtxModalLoading, updateCtxModalData } = ctxModalSlice.actions;
export const ctxModalReducer = ctxModalSlice.reducer;
