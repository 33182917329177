import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Icon } from 'components/common/Icon';

import { selectIsList, updateIsListView } from 'redusers/global/view';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { IViewSwitchComponentProps } from './types';

export const ViewSwitchComponent = ({ isList, handlerViewSwitch }: IViewSwitchComponentProps) => {
  return (
    <div className="d-flex">
      <div
        className="sv-ui-btn-gray sv-ui-btn-m sv-ui-btn-gray--active"
        onClick={handlerViewSwitch}
        data-testid="switch-btn"
      >
        {isList ? <Icon type="ListIcon" /> : <Icon type="TileIcon" />}
      </div>
    </div>
  );
};

export const ViewSwitch = () => {
  const dispatch = useDispatch();
  const isList = useSelector(selectIsList);

  const handlerViewSwitch = () => {
    dispatch(updateIsListView(!isList));
    if (isList) sendGtmTrigger('sv2_switch_view_to_tile');
    else sendGtmTrigger('sv2_switch_view_to_table');
  };

  return <ViewSwitchComponent isList={isList} handlerViewSwitch={handlerViewSwitch} />;
};
