import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISortState, SortFields } from './types';

const initialState: ISortState = {
  fieldName: SortFields.name,
  asc: true,
};

export const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    sortUpdate: (state, action: PayloadAction<ISortState>) => {
      state.fieldName = action.payload.fieldName;
      state.asc = action.payload.asc;
    },
  },
});

export const { sortUpdate } = sortSlice.actions;
export const sortReducer = sortSlice.reducer;
