import { AxiosResponse } from 'axios';
import http from 'utils/http.service';
import { IDownloadProcessRes } from './downloadManager.types';

export const createDownloadProcess = async (filesIds: string[], name: string): Promise<AxiosResponse<IDownloadProcessRes>> => {
  return http<IDownloadProcessRes>(
    '/archives',
    {
      method: 'post',
      data: { files: filesIds, archive_name: name },
    },
  );
};

export const checkDownloadProcess = async (id: number): Promise<AxiosResponse<IDownloadProcessRes>> => {
  return http<IDownloadProcessRes>(`/archives/${id}`);
};

export default { createDownloadProcess, checkDownloadProcess };
