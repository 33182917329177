import { TRootState } from 'redusers';
import { IFileRes } from 'api/files.types';
import { ModalTypes } from 'types/modal.types';

export const selectCtxModalIsOpen = ({ ctxModal }: TRootState): boolean => {
  return ctxModal.isOpen;
};

export const selectCtxModalElementIdx = ({ ctxModal }: TRootState): number | undefined => {
  return ctxModal.elementIdx;
};

export const selectCtxModalType = ({ ctxModal }: TRootState): ModalTypes | undefined => {
  return ctxModal.modalType;
};

export const selectCtxModalIsMany = ({ ctxModal }: TRootState): boolean => {
  return ctxModal.isMany;
};

export const selectCtxModalLoading = ({ ctxModal }: TRootState): boolean => {
  return ctxModal.loading;
};

export const selectCtxModalData = ({ ctxModal }: TRootState): IFileRes | undefined => {
  return ctxModal.data;
};
