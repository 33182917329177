import React from 'react';
import classNames from 'classnames';
import { SkeletonLine } from 'components/common/SkeletonLine';

import { IMainFilesListItemSkeletonProps } from './types';
import './mainFilesItem.scss';

export const MainFilesItemSkeleton = ({ className, isList }: IMainFilesListItemSkeletonProps) => {
  const elementClasses = classNames(className, {
    'main-files-item_list': isList,
    'main-files-item_tile': !isList,
  });

  return (
    <div className={elementClasses}>
      <div
        className="main-files-item__wrap"
      >
        <div className="main-files-item__content">
          <SkeletonLine className="main-files-item__icon-skeleton" />
        </div>
      </div>
    </div>
  );
};
