import React from 'react';
import { SkeletonLine } from 'components/common/SkeletonLine';

export const AlbumsItemSkeleton = () => {
  return (
    <div className="col-6 col-md-4 col-lg-3 col-xl-2 albums-list-item">
      <div className="albums-list-item__wrap">
        <div className="albums-list-item__content">
          <SkeletonLine className="albums-list-item__icon-skeleton" />
        </div>
      </div>
    </div>
  );
};
