import React from 'react';
import { useSelector } from 'react-redux';
import { selectLastAlbumsList } from 'redusers/albums';
import { SidebarAlbumListItem } from './sidebarAlbumListItem';
import { ISidebarAlbumListComponentProps, ISidebarAlbumListProps } from './sidebarAlbumList.types';

export const SidebarAlbumListComponent = ({ albums, handleClickByItem }: ISidebarAlbumListComponentProps) => {
  return (
    <>
      {albums.map((album) => (
        <SidebarAlbumListItem key={album.uuid} album={album} handleClickByItem={handleClickByItem} />
      ))}
    </>
  );
};

export const SidebarAlbumList = ({ handleClickByItem }: ISidebarAlbumListProps) => {
  const albums = useSelector(selectLastAlbumsList);

  return <SidebarAlbumListComponent albums={albums} handleClickByItem={handleClickByItem} />;
};
