import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BigAvatar } from 'components/common/BigAvatar';
import { Spinner } from 'components/common/Spinner';
import { deleteAvatarAccount, uploadAvatarAccount } from 'redusers/global/auth';

import './editAvatar.scss';

export const EditAvatar = ({ userAvatar, loading }: { userAvatar?: string, loading?: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const btnRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    if (btnRef.current) btnRef.current.click();
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];

    dispatch(uploadAvatarAccount(file));
  };

  const handleDeleteAvatar = () => {
    dispatch(deleteAvatarAccount());
  };

  const editAvatarBoxClasses = classNames('edit-avatar__box', {
    'edit-avatar__box--without-camera': !userAvatar,
  });

  return (
    <div className="edit-avatar">
      <div className={editAvatarBoxClasses} onClick={handleClick}>
        <BigAvatar userAvatar={userAvatar} isEditable />
        <Spinner className="edit-avatar__spinner" size={100} loading={loading} />
      </div>
      <div className="edit-avatar__content">
        <span>
          {t('update_avatar__description')}
        </span>
        {
          userAvatar
            ? (
              <span className="edit-avatar__btn edit-avatar__delete" onClick={handleDeleteAvatar}>
                {t('update_avatar__delete')}
              </span>
            )
            : (
              <span className="edit-avatar__btn" onClick={handleClick}>
                {t('update_avatar__upload')}
              </span>
            )

        }
      </div>
      <input
        className="edit-avatar__input"
        name="avatar"
        type="file"
        ref={btnRef}
        onChange={handleFileUpload}
      />
    </div>
  );
};
