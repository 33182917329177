import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectAlbumsList, selectAlbumsLoading } from 'redusers/albums';
import ContextMenu from 'components/common/ContextMenu';
import { IAlbumsListComponentProps } from './albumsList.types';

import { AlbumsItem, AlbumsItemSkeleton } from '../AlbumsItem';

export const AlbumsListComponent = ({ albums, loading }: IAlbumsListComponentProps) => {
  const albumsListRef = useRef<HTMLDivElement>(null);

  if (loading) {
    return (
      <div className="row custom-row">
        {Array(albums.length || 24).fill('').map((file, idx) => (
          <AlbumsItemSkeleton key={idx} />
        ))}
      </div>
    );
  }

  return (
    <div className="row custom-row" ref={albumsListRef}>
      {albums.map((album, idx) => <AlbumsItem key={album.uuid} idx={idx} album={album} />)}
      {/* @ts-ignore */}
      <ContextMenu height={130} ref={albumsListRef} />
    </div>
  );
};

export const AlbumsList = () => {
  const albums = useSelector(selectAlbumsList);
  const loading = useSelector(selectAlbumsLoading);

  return <AlbumsListComponent albums={albums} loading={loading} />;
};
