import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DefLayout } from 'components/layouts/DefLayout';
import MainScreen from 'components/MainScreen';
import { MainHeader } from 'components/MainHeader';

import { filesFetchingStart } from 'redusers/files/list';

export const HomePageComponent = () => {
  return (
    <DefLayout className="unselect-container">
      <MainHeader />
      <MainScreen />
    </DefLayout>
  );
};

export const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(filesFetchingStart());
  }, [dispatch]);

  return <HomePageComponent />;
};
