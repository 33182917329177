import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';

import { openCtxModal } from 'redusers/ctxModal';
import { ModalTypes } from 'types/modal.types';
import { ISettingsDeleteComponentProps } from './types';

export const SettingsMenuDeleteComponent = ({ handleClick }: ISettingsDeleteComponentProps) => {
  const { t } = useTranslation();

  return (
    <div className="settings-menu__item" onClick={handleClick}>
      <div className="settings-menu__item-type">
        <Icon type="DeleteOutline" className="settings-menu__item-icon" />
        <span className="settings-menu__item-name">{t('settings__delete-account')}</span>
      </div>
      <div className="settings-menu__item-meta">
        <Icon type="CheveronRight" className="settings-menu__item-cheveron" />
      </div>
    </div>
  );
};

export const SettingsMenuDelete = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(openCtxModal({
    elementType: null,
    modalType: ModalTypes.CTX_MODAL_SETTINGS_DELETE_ACCOUNT,
  }));

  return <SettingsMenuDeleteComponent handleClick={handleClick} />;
};
