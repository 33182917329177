import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

import API from 'api';
import { TRootState } from 'redusers';

import {
  fileModalOpenFromSearchStart,
  fileModalOpenFromSearchSuccess,
  fileModalOpenFromSearchFailed,
} from './fileModal.slice';

export const fileModalGetFromSearchEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getFileById },
) => action$.pipe(
  ofType(fileModalOpenFromSearchStart),
  switchMap((action) => {
    return from(getFileById(action.payload)).pipe(
      map(({ status, data }) => {
        if (status !== 200) return fileModalOpenFromSearchFailed();
        return fileModalOpenFromSearchSuccess(data);
      }),
      catchError(() => of(fileModalOpenFromSearchFailed())),
    );
  }),
);

export const fileModalEpics = [fileModalGetFromSearchEpic];
