import { combineReducers } from 'redux';

import global from './global';
import { ctxModalSlice } from './ctxModal';
import files from './files';
import { searchSlice } from './search';
import { sortSlice } from './sort';
import { fileModalSlice } from './fileModal';
import { filtersSlice } from './filters';
import { downloadManagerSlice } from './downloadManager';
import { sharingSlice } from './sharing';
import { albumsSlice } from './albums';
import { albumHintsSlice } from './albumHints';

export default (routerReducer: any) => {
  return combineReducers({
    router: routerReducer,
    global,
    files,
    [ctxModalSlice.name]: ctxModalSlice.reducer,
    [searchSlice.name]: searchSlice.reducer,
    [sortSlice.name]: sortSlice.reducer,
    [fileModalSlice.name]: fileModalSlice.reducer,
    [filtersSlice.name]: filtersSlice.reducer,
    [downloadManagerSlice.name]: downloadManagerSlice.reducer,
    [sharingSlice.name]: sharingSlice.reducer,
    [albumsSlice.name]: albumsSlice.reducer,
    [albumHintsSlice.name]: albumHintsSlice.reducer,
  });
};
