import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { IRoutersProps } from 'types/routers.types';

import { LoginPage } from 'pages/LoginPage';
import { HomePage } from 'pages/HomePage';
import { AlbumPage } from 'pages/AlbumPage';
import { SearchPage } from 'pages/SearchPage';
import { SettingsPage } from 'pages/SettingsPage';
import { AlbumsPage } from 'pages/AlbumsPage';
import { PrivacyPage } from 'pages/PrivacyPage';
import { TermsPage } from 'pages/TermsPage';
import { SharingPage } from 'pages/SharingPage';
import { SupportPage } from 'pages/SupportPage';
import { DevicesPage } from 'pages/DevicesPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { TRootState } from './redusers';

interface IRouts {
  path?: string;
  element: any;
}

const Routers = ({ isAuth, history, loading }: IRoutersProps) => {
  const defaultRouts: IRouts[] = [
    { path: '/privacy', element: PrivacyPage },
    { path: '/terms', element: TermsPage },
    { path: '/therms', element: TermsPage },
    { path: '/files/public/:shareKey', element: SharingPage },
  ];

  const authRouts: IRouts[] = [
    { path: '/', element: HomePage },
    { path: '/albums', element: AlbumsPage },
    { path: '/albums/:albumUUID', element: AlbumPage },
    // { path: '/folder/:folderId([0-9]{1,9})', component: HomePage },
    { path: '/result', element: SearchPage },
    { path: '/settings', element: SettingsPage },
    { path: '/devices', element: DevicesPage },
  ];

  const openRoutsAfterRefresh: IRouts[] = [
    { path: '/support', element: SupportPage },
  ];

  const guestRoutes: IRouts[] = [{ path: '/*', element: LoginPage }];

  const renderRouters = () => {
    const currOpenRoutsAfterRefresh = !loading ? openRoutsAfterRefresh : [];
    const currRouts = isAuth ? authRouts : guestRoutes;

    const routs = defaultRouts
      .concat(currOpenRoutsAfterRefresh)
      .concat(currRouts)
      .concat([{ path: '/*', element: NotFoundPage }]);

    return routs.map((rout, idx) => (
      // @ts-ignore
      <Route exact path={rout.path} element={<rout.element />} key={rout.path || idx} />
    ));
  };

  return (
    <Router history={history}>
      <Routes>
        {renderRouters()}
      </Routes>
    </Router>
  );
};

const mapStateToProps = ({ global }: TRootState) => ({
  loading: global.auth.loading,
  isAuth: global.auth.isAuth,
});

export default connect(mapStateToProps)(Routers);
