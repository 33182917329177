import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { exhaustMap, catchError, map } from 'rxjs/operators';
import API from 'api';
import { TRootState } from 'redusers';

import { shareFileFetchingStart, shareFileFetchingSuccess, shareFileFetchingFailed } from './sharing.slice';

export const getShareFileEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getPublicFile },
) => action$.pipe(
  ofType(shareFileFetchingStart),
  exhaustMap((action) => {
    return from(getPublicFile(action.payload.shareKey)).pipe(
      map(({ data, status }) => {
        if (status !== 200) return shareFileFetchingFailed();

        return shareFileFetchingSuccess(data);
      }),
      catchError(() => of(shareFileFetchingFailed())),
    );
  }),
);

export const sharingEpics = [getShareFileEpic];
