import React, { Fragment } from 'react';
import classNames from 'classnames';

import { Icon } from 'components/common/Icon';

import { ISharingHeaderProps } from './types';
import './index.scss';

export const SharingHeader = ({ fileName, fileDataUrl, onlyLogo = false }: ISharingHeaderProps) => {
  const renderData = () => {
    if (onlyLogo) return null;
    return (
      <Fragment>
        <div className="sharing-header__name">
          {fileName}
        </div>
        <a
          href={fileDataUrl}
          className="sharing-header__download"
          data-testid="sharing-download-link"
          download
        >
          <Icon type="DownloadOutline" />
        </a>
      </Fragment>
    );
  };

  const elementClasses = classNames('sharing-header', {
    'sharing-header--only-logo': onlyLogo,
  });

  return (
    <div className={elementClasses}>
      {onlyLogo ? null : <div className="sharing-header__empty" />}
      <div className="sharing-header__logo">
        <img src="/static/logo_white.png" alt="" />
      </div>
      {renderData()}
    </div>
  );
};
