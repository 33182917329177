import React from 'react';
import { Icon } from 'components/common/Icon';
import isMobile from 'utils/isMobile';
import { ElementTypes } from 'types/element.types';
import { AlbumTypes } from 'api/albums.types';
import { IAlbumsItemProps } from './albumsItem.types';

import './albumsItem.scss';

export const AlbumsItem = ({ album, idx }: IAlbumsItemProps) => {
  return (
    <div className="col-6 col-md-4 col-lg-3 col-xl-2 albums-list-item">
      <div
        className="albums-list-item__wrap"
        data-context-active={isMobile() || album.type === AlbumTypes.favorite ? 'false' : 'true'}
        data-ctx-menu-type={ElementTypes.ALBUM}
        data-item-id={album.uuid}
        data-item-name={album.name}
        data-item-idx={idx}
      >
        <div className="albums-list-item__content">
          <div className="albums-list-item__icon" style={{ backgroundImage: `url(${album.preview_url || '/static/images/album-placeholder.svg'})` }} />
          <div className="albums-list-item__title">
            {album.type === AlbumTypes.favorite ? <Icon type="Star" className="albums-list-item__title-icon" /> : null}
            <span className="albums-list-item__title-text">{album.name}</span>
          </div>
          {
            isMobile() && album.type !== AlbumTypes.favorite
              ? (
                <div
                  className="albums-list-item__mobile-context"
                  data-context-active={isMobile() ? 'true' : 'false'}
                  data-ctx-menu-type={ElementTypes.ALBUM}
                  data-item-id={album.uuid}
                  data-item-idx={idx}
                  data-item-name={album.name}
                >
                  <Icon type="DotsVertical" />
                </div>
              )
              : null
          }
        </div>
      </div>
    </div>
  );
};
