import { TRootState } from 'redusers';
import { IFileRes } from 'api/files.types';

export const selectSearchString = ({ search }: TRootState): string => {
  return search.searchString;
};

export const selectSearchHints = ({ search }: TRootState): IFileRes[] => {
  return search.data;
};
