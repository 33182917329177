import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { IPayloadWithPagination } from 'types/pagination.types';
import { IFileRes, IUpdateFileValues } from 'api/files.types';
import { IFileListState, TFavoriteStatusChangeStart } from './types';

const initialState: IFileListState = {
  loading: false,
  data: [],
  pagination: {
    currentPage: 0,
    pageCount: 0,
    perPage: 0,
    totalCount: 0,
  },
};

export const filesLoadModeStart = createAction('list/filesLoadModeStart');
export const filesLoadModeFailed = createAction('list/filesLoadModeFailed');
export const fileUpdateStart = createAction<{ id: string, values: IUpdateFileValues }>('list/fileUpdateStart');
export const fileDeleteStart = createAction<string | undefined>('list/fileDeleteStart');
export const fileShareStart = createAction<{ data: IFileRes, isOpen: boolean }>('list/fileShareStart');

export const favoriteStatusChangeStart = createAction<TFavoriteStatusChangeStart>('list/favoriteStatusChangeStart');
export const favoriteStatusChangeSuccess = createAction('list/favoriteStatusChangeSuccess');
export const favoriteStatusChangeFailed = createAction('list/favoriteStatusChangeFailed');

export const addFileToAlbumStart = createAction<{ albumUuid: string, fileIds?: string[] }>('list/addFileToAlbumStart');
export const addFileToAlbumSuccess = createAction('list/addFileToAlbumSuccess');
export const addFileToAlbumFailed = createAction('list/addFileToAlbumFailed');
export const deleteFilesFromAlbumStart = createAction<{ albumUuid: string, fileIds?: string[] }>('list/deleteFilesFromAlbumStart');
export const deleteFilesFromAlbumSuccess = createAction<{ fileIds: string[] }>('list/deleteFilesFromAlbumSuccess');

export const fileListSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    filesFetchingStart: (state) => {
      state.loading = true;
    },

    filesFetchingSuccess: (state, { payload }: PayloadAction<IPayloadWithPagination<IFileRes[]>>) => {
      state.loading = false;
      state.data = payload.data;
      state.pagination = payload.pagination;
    },
    filesLoadModeSuccess: (state, { payload }: PayloadAction<IPayloadWithPagination<IFileRes[]>>) => {
      state.data = state.data.concat(payload.data);
      state.pagination = payload.pagination;
    },

    filesFetchingFailed: (state) => {
      state.loading = initialState.loading;
      state.data = initialState.data;
      state.pagination = initialState.pagination;
    },
    fileUpdateSuccess: (state, { payload }: PayloadAction<IFileRes>) => {
      state.data = state.data.map((file) => {
        return file.id === payload.id ? payload : file;
      });
    },

    fileDeleteSuccess: (state, { payload }: PayloadAction<string[]>) => {
      state.data = state.data.filter((file) => !payload.includes(file.id));
    },

    filesAddToAlbumOrRemoveFromAlbum: (
      state,
      {
        payload: { albumUUID, fileIds, isAddToAlbum },
      }: PayloadAction<{ albumUUID: string, fileIds: string[], isAddToAlbum: boolean }>,
    ) => {
      state.data = state.data.map((file) => {
        if (!fileIds.includes(file.id)) return file;

        // eslint-disable-next-line no-nested-ternary
        return isAddToAlbum
          ? file.albums_uuids.includes(albumUUID) ? file : { ...file, albums_uuids: [...file.albums_uuids, albumUUID] }
          : { ...file, albums_uuids: file.albums_uuids.filter((currAlbumUUID) => currAlbumUUID !== albumUUID) };
      });
    },

    deleteFilesFromAlbumSuccess: (state, { payload }: PayloadAction<{ fileIds: string[] }>) => {
      state.data = state.data.filter((file) => !payload.fileIds.includes(file.id));
    },
  },
});

export const {
  filesFetchingStart,
  filesFetchingSuccess,
  filesLoadModeSuccess,
  filesFetchingFailed,
  filesAddToAlbumOrRemoveFromAlbum,
  fileDeleteSuccess,
  fileUpdateSuccess,
} = fileListSlice.actions;
export const fileListReducer = fileListSlice.reducer;
