import React from 'react';
import { IMenuGroupProps } from './menu.types';

import './menuGroup.scss';

export const MenuGroup = ({ children }: IMenuGroupProps) => {
  return (
    <div className="menu-group">
      { children }
    </div>
  );
};
