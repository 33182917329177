import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPicker } from 'components/FilterPicker';
import { SortPicker } from 'components/SortPicker';
import { ViewSwitch } from 'components/ViewSwitch';
import { SkeletonLine } from 'components/common/SkeletonLine';

import { IMainHeaderProps } from './types';
import './index.scss';

export const MainHeader = ({ className, title, titleLocaleKey, loading = false }: IMainHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={`main-header ${className || ''}`}>
      <div className="main-header__left-box">
        <div className="main-header__field-name">
          {
            loading
              ? <SkeletonLine />
              : title || t(titleLocaleKey || 'main__cloud-name')
          }
        </div>
        <SortPicker />
      </div>
      <div className="main-header__right-box">
        <FilterPicker />
        <ViewSwitch />
      </div>
    </div>
  );
};