import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import './styles/variables/colorsTheme.scss';
import 'react-toastify/dist/ReactToastify.css';

import Routers from 'Routers';
import { initApp } from 'init';
import { IAppProps } from 'types/app.types';

// loading component for suspense fallback
const Loader = () => <div />;

function App({ store, history }: IAppProps) {
  const bounce = cssTransition({
    enter: 'animate__animated animate__very-fast animate__fadeIn',
    exit: 'animate__animated animate__very-fast animate__fadeOut',
  });

  return (
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <Routers history={history} />
        <ToastContainer
          position={toast.POSITION.TOP_CENTER}
          transition={bounce}
          autoClose={2000}
          limit={1}
          hideProgressBar
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
        />
      </Suspense>
    </Provider>
  );
}

export default initApp(App);
