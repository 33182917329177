import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';
import { selectCtxModalLoading, selectCtxModalElementIdx } from 'redusers/ctxModal';
import { deleteAlbumStart, selectAlbumByIdx } from 'redusers/albums';
import { IAlbumRemoveComponentProps } from './albumRemove.types';

export const AlbumRemoveComponent = ({ removeHandler, loading }: IAlbumRemoveComponentProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon type="DeleteOutline" className="custom-modal__icon" />
        <div className="custom-modal__title">
          {t('album__remove-album-modal-title')}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          {t('album__remove-album-modal-body')}
        </div>
        <div className="custom-modal__btn-box">
          <button
            onClick={removeHandler}
            className="btn btn-danger sv-btn custom-modal__btn-margin"
            disabled={loading}
          >
            {t('global__remove')}
          </button>
        </div>
      </Modal.Body>
    </Fragment>
  );
};

export const AlbumRemove = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectCtxModalLoading);
  const elementIdx = useSelector(selectCtxModalElementIdx);
  const album = useSelector(selectAlbumByIdx(elementIdx));

  const removeHandler = useCallback(() => {
    if (album !== undefined) dispatch(deleteAlbumStart({ uuid: album?.uuid }));
  }, [album, dispatch]);

  const handleKeyUp = useCallback((e: any) => {
    if (e.key === 'Enter') removeHandler();
  }, [removeHandler]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [handleKeyUp]);

  return <AlbumRemoveComponent removeHandler={removeHandler} loading={loading} />;
};
