import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDeviceCatalogRes } from 'api/devices.types';
import { IDevicesCatalogState } from './types';

const initialState: IDevicesCatalogState = {
  loading: false,
  data: [],
};

export const devicesCatalogSlice = createSlice({
  name: 'devicesCatalog',
  initialState,
  reducers: {
    devicesCatalogFetchingStart: (state) => {
      state.loading = true;
    },

    devicesCatalogFetchingFailed: (state) => {
      state.loading = false;
    },

    devicesCatalogFetchingSuccess: (state, action: PayloadAction<IDeviceCatalogRes[]>) => {
      state.loading = false;
      state.data = action.payload;
    },

    devicesCatalogClear: (state) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  devicesCatalogFetchingStart,
  devicesCatalogFetchingFailed,
  devicesCatalogFetchingSuccess,
  devicesCatalogClear,
} = devicesCatalogSlice.actions;
export const devicesCatalogReducer = devicesCatalogSlice.reducer;
