import React from 'react';
import { IAlbumHintsItem } from './types';

export const AlbumHintsItem = ({ name, setAlbumNameValue }: IAlbumHintsItem) => {
  const handleClick = () => setAlbumNameValue(name);

  return (
    <div className="album-hints__item" onClick={handleClick}>{name}</div>
  );
};
