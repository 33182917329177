import { PayloadAction } from '@reduxjs/toolkit';
import { replace } from 'redux-first-history';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import qs from 'query-string';
import { exhaustMap, mergeMap, catchError, tap } from 'rxjs/operators';

import API from 'api';
import { IAuthCredential } from 'api/account.types';
import { TRootState } from 'redusers';

import { AuthMethods } from 'types/auth.types';
import { refreshAuthProvider } from 'providers/refreshAuthProvider';
import { getQuota } from 'utils/getQuota';
import { ModalTypes } from 'types/modal.types';
import { albumsFetchingStart } from 'redusers/albums';
import { openCtxModal } from '../../ctxModal';
import { IAuthFetchingStartPayload } from './types';
import { authFetchingStart, authFetchingSuccess, authFetchingFailed } from './auth.slice';
import { updateQuotaSuccess } from '../quota';

export const loginEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { loginUser, getAccount },
) => action$.pipe(
  ofType(authFetchingStart),
  exhaustMap<PayloadAction<IAuthFetchingStartPayload>, any>((action) => {
    const { accessToken, refreshToken } = refreshAuthProvider.getSessionCookie();
    // @ts-ignore
    const searchStr = state$.value.router?.location?.search;
    const { mobile_token: mobileToken } = qs.parse(searchStr) as { mobile_token: string | undefined };
    const { token, social } = action.payload || {};

    const login$ = (credential: IAuthCredential) => {
      return from(loginUser(credential)).pipe(
        tap(({ status, data: { auth_tokens } }) => {
          if (status === 200) refreshAuthProvider.setSessionCookie(auth_tokens.access_token, auth_tokens.refresh_token);
        }),

        mergeMap(({ status, data }) => {
          if (status === 200) {
            return from([authFetchingSuccess(data.account), albumsFetchingStart(), replace('/')]);
          }
          if (status === 403) {
            return from([
              authFetchingFailed(),
              openCtxModal({ elementType: null, modalType: ModalTypes.CTX_MODAL_BLOCKED_ACCOUNT }),
            ]);
          }
          return of(authFetchingFailed());
        }),
        catchError(() => of(authFetchingFailed())),
      );
    };

    const getAccount$ = () => {
      return from(getAccount()).pipe(
        mergeMap(({ status, data, headers }) => {
          if (status !== 200) return of(authFetchingFailed());
          const quota = getQuota(headers);

          return of(authFetchingSuccess(data), albumsFetchingStart(), updateQuotaSuccess(quota));
        }),
        catchError(() => of(authFetchingFailed())),
      );
    };

    if (mobileToken) {
      const credential = { access_token: mobileToken, method: AuthMethods.mobile_app };
      return login$(credential);
    }

    if (accessToken && refreshToken) {
      return getAccount$();
    }

    if (token && social) {
      const credential = { access_token: token, method: AuthMethods.firebase, social };
      return login$(credential);
    }

    return of(authFetchingFailed());
  }),
);

export const authEpics = [loginEpic];
