import React, { ChangeEvent, useState } from 'react';
import classNames from 'classnames';
import { ISearchInputProps } from './searchInput.types';

import './SearchInput.scss';

export const SearchInput = ({ value, handleValueChange, placeholder, className }: ISearchInputProps) => {
  const [isFocus, setIsFocus] = useState(false);

  const handleFocus = () => setIsFocus(true);
  const handleBlur = () => setIsFocus(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: currentValue } = e.target;
    handleValueChange(currentValue);
  };

  const searchClasses = classNames('search-input-wrap', className, {
    'search-input-wrap--focus': isFocus,
  });

  return (
    <div className={searchClasses}>
      <input
        onFocus={handleFocus}
        onBlur={handleBlur}
        type="text"
        className="form-control form-control-lg search-input"
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
};
