import { AxiosResponse } from 'axios';
import http from 'utils/http.service';
import { IAccountRes, IAuthCredential, IEditAccountData, ILoginRes } from './account.types';

export const getAccount = async (): Promise<AxiosResponse<IAccountRes>> => {
  return http<IAccountRes>('/account');
};

export const loginUser = async (credential: IAuthCredential): Promise<AxiosResponse<ILoginRes>> => {
  const body = { auth_credential: credential, platform: 'web' };

  return http<ILoginRes>('/auth/login', {
    method: 'post',
    data: body,
    withoutAccess: true,
  });
};

export const deleteAccount = async (): Promise<AxiosResponse<any>> => {
  return http<any>('/account', {
    method: 'delete',
  });
};

export const updateAccount = async (data: IEditAccountData): Promise<AxiosResponse<IAccountRes>> => {
  return http<any>('/account', {
    method: 'patch',
    data,
  });
};

export const uploadAvatar = async (data: any): Promise<AxiosResponse<IAccountRes>> => {
  return http<any>('/account/avatar', {
    method: 'post',
    data,
    headers: {
      'content-type': 'image/*',
    },
  });
};

export const deleteAvatar = async (): Promise<AxiosResponse<IAccountRes>> => {
  return http<any>('/account/avatar', {
    method: 'delete',
  });
};

export default {
  getAccount,
  loginUser,
  deleteAccount,
  uploadAvatar,
  deleteAvatar,
  updateAccount,
};
