import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';
import { IFileRes } from 'api/files.types';
import { IPagination } from 'types/pagination.types';

export const selectFiles = ({ files }: TRootState): IFileRes[] => {
  return files.list.data;
};

export const selectFilesLoading = ({ files }: TRootState): boolean => {
  return files.list.loading;
};

export const selectFileByIndex = (fileIndex: number | null) => createSelector(
  selectFiles,
  (files): IFileRes | undefined => {
    if (fileIndex === null || fileIndex < 0) return undefined;
    return files[fileIndex];
  },
);

export const selectFileCount = createSelector(
  selectFiles,
  (files): number => files.length,
);

export const selectFileLastIndex = createSelector(
  selectFileCount,
  (count): number => count - 1,
);

export const selectFilesPagination = ({ files }: TRootState): IPagination => {
  return files.list.pagination;
};

export const selectFilesHasMore = createSelector(
  selectFilesPagination,
  (pagination): boolean => pagination.currentPage < pagination.pageCount,
);

// export const selectFileLastIndex = createSelector(
//   selectFileCount,
//   (count): number => count - 1,
// );
