import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { IFileRes } from 'api/files.types';
import { ISharingState } from './types';

const initialState: ISharingState = {
  loading: true,
  success: false,
  data: undefined,
};

export const shareFileFetchingStart = createAction<{ shareKey: string }>('sharing/shareFileFetchingStart');

export const sharingSlice = createSlice({
  name: 'sharing',
  initialState,
  reducers: {
    shareFileFetchingFailed(state) {
      state.loading = false;
      state.success = false;
      state.data = undefined;
    },
    shareFileFetchingSuccess(state, action: PayloadAction<IFileRes>) {
      state.loading = false;
      state.success = true;
      state.data = action.payload;
    },
  },
});

export const { shareFileFetchingFailed, shareFileFetchingSuccess } = sharingSlice.actions;
export const sharingReducer = sharingSlice.reducer;
