import { Epic, ofType } from 'redux-observable';
import { of, from, concat } from 'rxjs';
import {
  switchMap,
  distinctUntilChanged,
  exhaustMap,
  map,
  catchError,
  debounceTime,
} from 'rxjs/operators';
import API from 'api';
import { TRootState } from 'redusers';
import { filesFetchingStart } from 'redusers/files/list';
import {
  searchStrDetect,
  changeSearchString,
  changeDetectedString,
  searchClear,
  searchFetchingStart,
  searchFetchingSuccess,
  searchFetchingFailed,
} from './search.slice';

export const searchStrSetEpic: Epic<any, any, TRootState, typeof API> = (action$) => action$.pipe(
  ofType(searchStrDetect),
  switchMap((action) => of(
    changeDetectedString(action.payload),
    filesFetchingStart(),
  )),
);

export const getShortHints: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getSearchHints },
) => action$.pipe(
  // @ts-ignore
  ofType(changeSearchString, searchClear),
  debounceTime(300),
  distinctUntilChanged((prev, curr) => prev.payload === curr.payload),
  exhaustMap(() => {
    const { search } = state$.value;
    const { searchString } = search;

    if (!searchString) {
      return of(searchFetchingSuccess([]));
    }

    const req$ = from(getSearchHints(search.searchString)).pipe(
      map(({ data, status }) => {
        if (status !== 200) return searchFetchingFailed();
        return searchFetchingSuccess(data);
      }),
      catchError(() => of(searchFetchingFailed())),
    );
    return concat(of(searchFetchingStart()), req$);
  }),
);

export const searchEpics = [searchStrSetEpic, getShortHints];
