import * as Yup from 'yup';
import i18next from 'i18next';

import { FILE_NAME_MAX_LENGTH, FILE_NAME_MIN_LENGTH } from '../constants/validator';

export default () => ({
  name: Yup.string()
    .trim()
    .min(FILE_NAME_MIN_LENGTH, i18next.t('validator__form-folder-name-min'))
    .max(FILE_NAME_MAX_LENGTH, i18next.t('validator__form-folder-name-max'))
    // .matches(/^[^./|\\:;+=*?"<>\[\]]([^/|\\:;+=*?"<>\[\]]+)?$/, i18next.t('validator__forbidden-symbols'))
    // todo разрешено :
    // eslint-disable-next-line
    .matches(/^[^/|\\;+=*?"<>\[\]]+$/, i18next.t('validator__forbidden-symbols'))
    .matches(/^[^.](.+)?$/, i18next.t('validator__forbidden-dot'))
    .required(i18next.t('validator__form-required')),
});
