import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import classNames from 'classnames';

import ContextMenu from 'components/common/ContextMenu';
import { Spinner } from 'components/common/Spinner';
import { filesLoadModeStart, selectFiles, selectFilesHasMore, selectFilesLoading } from 'redusers/files/list';
import { selectCountSelectedFile, selectWithCtx, selectIsManySelected } from 'redusers/files/selected';
import { selectIsList } from 'redusers/global/view';

import { MainFilesItem, MainFilesItemSkeleton } from '../MainFilesItem';

import './mainFiles.scss';
import { IMainFilesProps } from './types';

export const MainFilesComponent = ({
  isList,
  files,
  hasMore,
  withoutCtxBtn,
  filesLoading,
  loadFiles,
  isManySelected,
}: IMainFilesProps) => {
  const fileListRef = useRef<HTMLDivElement>(null);

  const itemClasses = isList ? 'col-12' : 'col-6 col-md-4 col-lg-3 col-xl-2';

  const elementClasses = classNames('main-files row custom-row', {
    'main-files--list': isList,
    'main-files--without-ctx-btn': withoutCtxBtn,
  });

  if (filesLoading) {
    return (
      <div className={elementClasses}>
        {Array(files.length || 24).fill('').map((file, idx) => (
          <MainFilesItemSkeleton
            key={idx}
            className={itemClasses}
            isList={isList}
          />
        ))}
      </div>
    );
  }

  return (
    <InfiniteScroll
      loadMore={loadFiles}
      hasMore={hasMore}
      initialLoad={false}
      useWindow
      loader={<Spinner size={50} key="spinner_loader" />}
    >
      <div
        className={elementClasses}
        ref={fileListRef}
      >
        {files.map((file, idx) => (
          <MainFilesItem
            key={file.id}
            idx={idx}
            className={itemClasses}
            isList={isList}
            file={file}
          />
        ))}
        {/* @ts-ignore */}
        <ContextMenu height={isManySelected ? 270 : 450} ref={fileListRef} />
      </div>
    </InfiniteScroll>
  );
};

export const MainFiles = () => {
  const dispatch = useDispatch();

  const isList = useSelector(selectIsList);
  const files = useSelector(selectFiles);
  const hasMore = useSelector(selectFilesHasMore);
  const filesLoading = useSelector(selectFilesLoading);
  const filesCount = useSelector(selectCountSelectedFile);
  const withCtx = useSelector(selectWithCtx);
  const isManySelected = useSelector(selectIsManySelected);

  const loadFiles = useCallback(() => dispatch(filesLoadModeStart()), [dispatch]);

  const props = {
    loadFiles,
    isList,
    files,
    hasMore,
    filesLoading,
    isManySelected,
    withoutCtxBtn: !!filesCount && !withCtx,
  };

  return <MainFilesComponent {...props} />;
};
