import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';
import { AlbumForm } from 'components/forms/AlmubForm';
import { selectCtxModalLoading, selectCtxModalElementIdx } from 'redusers/ctxModal';
import { selectAlbumByIdx, updateAlbumStart } from 'redusers/albums';
import { IAlbumRenameComponentProps, THandleAlbumRename } from './albumRename.types';

export const AlbumRenameComponent = ({ handleSubmit, loading, data }: IAlbumRenameComponentProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon type="SquareEditOutline" className="custom-modal__icon" />
        <div className="custom-modal__title">
          {t('album__rename-album-modal-title')}
        </div>
      </Modal.Header>
      <Modal.Body>
        <AlbumForm
          initialValues={{ name: data.name }}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      </Modal.Body>
    </Fragment>
  );
};

export const AlbumRename = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectCtxModalLoading);
  const elementIdx = useSelector(selectCtxModalElementIdx);
  const data = useSelector(selectAlbumByIdx(elementIdx));

  if (!data) return null;

  const handleSubmit: THandleAlbumRename = (values) => {
    dispatch(updateAlbumStart({ uuid: data.uuid, values }));
  };

  return <AlbumRenameComponent handleSubmit={handleSubmit} data={data} loading={loading} />;
};
