import React from 'react';
import { Link } from 'react-router-dom';
import { IAvatarProps } from './avatar.types';

export const Avatar = ({ className, avatarUrl }: IAvatarProps) => {
  return (
    <Link to="/settings" className={`top-header__profile-avatar-box ${className || ''}`}>
      <div
        className="top-header__profile-avatar"
        style={avatarUrl ? { backgroundImage: `url(${avatarUrl})` } : {}}
      />
    </Link>
  );
};
