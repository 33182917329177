import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';
import { IFileRes } from 'api/files.types';
import { ISharingState } from './types';

export const selectSharingState = (globalState: TRootState) => globalState.sharing;

export const selectSharingLoading = createSelector(
  selectSharingState,
  (state: ISharingState): boolean => state.loading,
);

export const selectSharingSuccessStatus = createSelector(
  selectSharingState,
  (state: ISharingState): boolean => state.success,
);

export const selectSharingFileData = createSelector(
  selectSharingState,
  (state: ISharingState): IFileRes | undefined => state.data,
);