import { Epic, ofType } from 'redux-observable';
import { map, distinctUntilChanged } from 'rxjs/operators';
import API from 'api';
import { TRootState } from 'redusers';
import { filesFetchingStart } from 'redusers/files/list';
import { sortUpdate } from './sort.slice';

export const updateSortEpic: Epic<any, any, TRootState, typeof API> = (action$) => action$.pipe(
  ofType(sortUpdate),
  distinctUntilChanged((prev, curr) => JSON.stringify(prev.payload) === JSON.stringify(curr.payload)),
  map(() => (filesFetchingStart())),
);

export const sortEpics = [updateSortEpic];
