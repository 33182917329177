import React from 'react';
import { IDropDownModalItemProps } from './types';

export const DropDownModalItem = ({ children, handleClick, className, ...props }: IDropDownModalItemProps) => {
  return (
    <div className={`dropdown-menu__item ${className}`} onClick={handleClick} {...props}>
      {children}
    </div>
  );
};
