import { Epic, ofType } from 'redux-observable';
import { v4 as uuidv4 } from 'uuid';
import { of, from, concat } from 'rxjs';
import toast from 'utils/toast';
import {
  exhaustMap,
  map,
  catchError, concatMap,
} from 'rxjs/operators';
import { PayloadAction } from '@reduxjs/toolkit';
import { getPagination } from 'utils/getPagination';
import API from 'api';
import i18next from 'i18next';
import { IUpdateAlbumValues } from 'api/albums.types';
import { TRootState } from 'redusers';

import {
  createAlbumStart,
  createAlbumSuccess,
  createAlbumFailed,
  albumsFetchingStart,
  albumsFetchingSuccess,
  albumsFetchingFailed,
  deleteAlbumStart,
  deleteAlbumSuccess,
  updateAlbumStart,
  updateAlbumSuccess,
} from './albums.slice';
import { ICreateAlbumStartPayload } from './albums.types';
import { updateCtxModalLoading, closeCtxModal } from '../ctxModal';

export const createAlbumEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { createAlbum },
) => action$.pipe(
  ofType(createAlbumStart),
  exhaustMap<PayloadAction<ICreateAlbumStartPayload>, any>(({ payload }) => {
    let fileIds: string[] = [];

    if (payload.withSelectedFiles) {
      const { files: { list: { data }, selected: { indexes } } } = state$.value;
      fileIds = data.reduce<string[]>((accum, item, idx) => {
        if (indexes.includes(idx)) return accum.concat(item.id);
        return accum;
      }, []);
    }

    const uuid = uuidv4();
    return from(createAlbum({ uuid, name: payload.values.name, files_ids: fileIds })).pipe(
      map(({ data, status }) => {
        if (status === 201) {
          return createAlbumSuccess(data);
        }
        if (status === 405) {
          toast(i18next.t('album__create-failed-limit'), { type: 'error' });
        } else {
          toast(i18next.t('album__create-failed'), { type: 'error' });
        }
        return createAlbumFailed();
      }),
      catchError(() => {
        toast(i18next.t('album__create-failed'), { type: 'error' });
        return of(createAlbumFailed());
      }),
    );
  }),
);

export const getAlbumsEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getAlbums },
) => action$.pipe(
  ofType(albumsFetchingStart), // добавить второй экшн который будет подгружать
  exhaustMap(({ type }) => {
    return from(getAlbums()).pipe(
      map(({ data, status, headers }) => {
        if (status === 200) {
          return albumsFetchingSuccess({ data, pagination: getPagination(headers) });
        }
        return albumsFetchingFailed();
      }),
      catchError(() => of(albumsFetchingFailed())),
    );
  }),
);

export const deleteAlbumEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { deleteAlbum },
) => action$.pipe(
  ofType(deleteAlbumStart),
  exhaustMap(({ payload }) => {
    const apiRequest$ = from(deleteAlbum(payload.uuid)).pipe(
      map(({ status }) => {
        if (status === 204) {
          return [deleteAlbumSuccess({ uuid: payload.uuid }), closeCtxModal()];
        }
        return updateCtxModalLoading(false);
      }),
      catchError(() => of(updateCtxModalLoading(false))),
    );

    return concat(of(updateCtxModalLoading(true)), apiRequest$);
  }),
  concatMap((action) => ((Array.isArray(action)) ? from(action) : of(action))),
);

export const updateAlbumEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { updateAlbum },
) => action$.pipe(
  ofType(updateAlbumStart),
  exhaustMap<PayloadAction<{ uuid: string, values: IUpdateAlbumValues }>, any>(({ payload }) => {
    const apiRequest$ = from(updateAlbum(payload.uuid, payload.values)).pipe(
      map(({ data, status }) => {
        if (status === 200) {
          return [updateAlbumSuccess(data), closeCtxModal()];
        }
        return updateCtxModalLoading(false);
      }),
    );

    return concat(of(updateCtxModalLoading(true)), apiRequest$);
  }),
  concatMap((action) => ((Array.isArray(action)) ? from(action) : of(action))),
);

export const albumsEpics = [
  createAlbumEpic,
  getAlbumsEpic,
  deleteAlbumEpic,
  updateAlbumEpic,
];
