import filterOutline from '@iconify-icons/mdi/filter-outline';
import listIcon from '@iconify-icons/zondicons/list';
import tileIcon from '@iconify-icons/zondicons/view-tile';
import fileCancelOutline from '@iconify-icons/mdi/file-cancel-outline';
import downloadOutline from '@iconify-icons/mdi/download-outline';
import bxGlobe from '@iconify-icons/bx/bx-globe';
import cheveronRight from '@iconify-icons/zondicons/cheveron-right';
import deleteOutline from '@iconify-icons/mdi/delete-outline';
import logoutIcon from '@iconify-icons/mdi/logout';
import fileDocumentMultipleOutline from '@iconify-icons/mdi/file-document-multiple-outline';
import chatOutline from '@iconify-icons/mdi/chat-outline';
import accountIcon from '@iconify-icons/mdi/account';
import dotsVertical from '@iconify-icons/mdi/dots-vertical';
import closeIcon from '@iconify-icons/mdi/close';
import image from '@iconify-icons/bx/bxs-image-alt';
import bxsVideo from '@iconify-icons/bx/bxs-video';
import arrowLeft from '@iconify-icons/mdi/arrow-left';
import cheveronLeft from '@iconify-icons/zondicons/cheveron-left';
import checkBold from '@iconify-icons/mdi/check-bold';
import closeThick from '@iconify-icons/mdi/close-thick';
import chevronDown from '@iconify-icons/mdi/chevron-down';
import accountMultipleOutline from '@iconify-icons/mdi/account-multiple-outline';
import linkVariant from '@iconify-icons/mdi/link-variant';
import squareEditOutline from '@iconify-icons/mdi/square-edit-outline';
import informationOutline from '@iconify-icons/mdi/information-outline';
import blockIcon from '@iconify-icons/mdi/block-helper';
import contentCopy from '@iconify-icons/mdi/content-copy';
import starOutline from '@iconify-icons/mdi/star-outline';
import star from '@iconify-icons/mdi/star';
import plusBoxMultiple from '@iconify-icons/mdi/plus-box-multiple';
import plusIcon from '@iconify-icons/mdi/plus';
import folderMove from '@iconify-icons/mdi/folder-move-outline';
import menuIcon from '@iconify-icons/mdi/menu';
import imageOutline from '@iconify-icons/mdi/image-outline';
import images from '@iconify-icons/mdi/images';
import minusBoxMultiple from '@iconify-icons/mdi/minus-box-multiple';
import addFolderIcon from '@iconify-icons/mdi/folder-plus-outline';
import { data as device } from './customeIcons/device';

export type IconType = 
    | 'FilterOutline'
    | 'ListIcon'
    | 'TileIcon'
    | 'FileCancelOutline'
    | 'DownloadOutline'
    | 'BxGlobe'
    | 'CheveronRight'
    | 'CheveronLeft'
    | 'CheveronDown'
    | 'DeleteOutline'
    | 'LogoutIcon'
    | 'FileDocumentMultipleOutline'
    | 'ChatOutline'
    | 'AccountIcon'
    | 'DotsVertical'
    | 'CloseIcon'
    | 'Image'
    | 'ImageOutline'
    | 'Images'
    | 'BxsVideo'
    | 'ArrowLeft'
    | 'CheckBold'
    | 'CloseThick'
    | 'AccountMultipleOutline'
    | 'LinkVariant'
    | 'SquareEditOutline'
    | 'InformationOutline'
    | 'BlockIcon'
    | 'ContentCopy'
    | 'PlusIcon'
    | 'PlusBoxMultiple'
    | 'MinusBoxMultiple'
    | 'FolderMove'
    | 'MenuIcon'
    | 'AddFolderIcon'
    | 'StarOutline'
    | 'Star'
    | 'Device';

export const iconTypes = new Map([
  ['FilterOutline', filterOutline],
  ['ListIcon', listIcon],
  ['TileIcon', tileIcon],
  ['FileCancelOutline', fileCancelOutline],
  ['DownloadOutline', downloadOutline],
  ['BxGlobe', bxGlobe],
  ['CheveronRight', cheveronRight],
  ['CheveronLeft', cheveronLeft],
  ['CheveronDown', chevronDown],
  ['DeleteOutline', deleteOutline],
  ['LogoutIcon', logoutIcon],
  ['FileDocumentMultipleOutline', fileDocumentMultipleOutline],
  ['ChatOutline', chatOutline],
  ['AccountIcon', accountIcon],
  ['DotsVertical', dotsVertical],
  ['CloseIcon', closeIcon],
  ['Image', image],
  ['ImageOutline', imageOutline],
  ['Images', images],
  ['BxsVideo', bxsVideo],
  ['ArrowLeft', arrowLeft],
  ['CheckBold', checkBold],
  ['CloseThick', closeThick],
  ['AccountMultipleOutline', accountMultipleOutline],
  ['LinkVariant', linkVariant],
  ['SquareEditOutline', squareEditOutline],
  ['InformationOutline', informationOutline],
  ['BlockIcon', blockIcon],
  ['ContentCopy', contentCopy],
  ['PlusIcon', plusIcon],
  ['PlusBoxMultiple', plusBoxMultiple],
  ['MinusBoxMultiple', minusBoxMultiple],
  ['FolderMove', folderMove],
  ['MenuIcon', menuIcon],
  ['AddFolderIcon', addFolderIcon],
  ['Star', star],
  ['StarOutline', starOutline],
  ['Device', device],
]);