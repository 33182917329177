import { createSelector } from '@reduxjs/toolkit';
import { IAlbumHintRes } from 'api/albums.types';
import { TRootState } from '../index';
import { IAlbumHintsState } from './albumHints.types';

export const selectAlbumHintsState = ({ albumHints }: TRootState): IAlbumHintsState => albumHints;

export const selectAlbumHintsList = createSelector(
  selectAlbumHintsState,
  (albumHintsState: IAlbumHintsState): IAlbumHintRes[] => albumHintsState.data,
);

export const selectAlbumHintsLoadingStatus = createSelector(
  selectAlbumHintsState,
  (albumHintsState: IAlbumHintsState): boolean => albumHintsState.loading,
);