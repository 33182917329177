import React from 'react';
import sendGtmTrigger from 'utils/sendGtmTrigger';

import './appButtons.scss';

export const AppButtons = () => {
  return (
    <div className="app-buttons">
      <a
        href="https://apps.apple.com/us/app/stream-vision-2/id1511736862"
        target="_blank"
        className="app-buttons__btn"
        onClick={() => sendGtmTrigger('sv2_go_to_appstore')}
        data-testid="apple-store-link"
      >
        <img src="/static/app_store.svg" alt="App Store" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.yukon.streamvision2"
        target="_blank"
        className="app-buttons__btn"
        onClick={() => sendGtmTrigger('sv2_go_to_playmarket')}
        data-testid="google-play-link"
      >
        <img src="/static/google_play.svg" alt="Google Play" />
      </a>
    </div>
  );
};
