import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';
import { INewAlbumTileTypesProps } from './newAlbumTile.types';

import './newAlbumTile.scss';

export const NewAlbumTile = ({ handleOpenAddAlbumForm }: INewAlbumTileTypesProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="new-album-tile" onClick={handleOpenAddAlbumForm}>
        <div className="new-album-tile__icon-box">
          <Icon type="PlusIcon" className="new-album-tile__icon" />
        </div>
        <span className="new-album-tile__title">{t('album__new-album-tile')}</span>
      </div>
    </>
  );
};
