import React, { useEffect, useState, useRef, KeyboardEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input/input';
import validators from 'validators';
import authMap from 'validators/authMap';
import { firebaseProvider } from 'providers/firebaseProvider';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { PhoneLoginModal } from './phoneLoginModal';

import './index.scss';
import './phone-input.scss';

export const PhoneLogin = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isPending, setIsPending] = useState(false);
  const [phone, setPhone] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const AuthSchema = validators(authMap, ['phone']);

  const validator = async () => {
    return AuthSchema.validate({ phone })
      .then(() => {
        setError(false);
        setErrorText('');
        return false;
      })
      .catch((e: any) => {
        setError(true);
        setErrorText(e.message);
        return true;
      });
  };

  const handleChange = (value: string) => {
    setPhone(value);
    if (inputRef.current) {
      // фикс перескакивания каретки на android
      // из-за форматирования(добавления пробелов в номер) при удалении на android каретка перескакивала
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.selectionStart = 1000;
          inputRef.current.selectionEnd = 1000;
        }
      }, 0);
    }
  };

  const toggle = () => setIsOpen(!isOpen);

  const handleClick = async () => {
    const currError = await validator();

    if (!currError) {
      try {
        setIsPending(true);
        await firebaseProvider.phoneAuth(phone);
        sendGtmTrigger('sv2_log_in_phone_start');
        toggle();
      } catch (e) {
        console.log(e);
        // @ts-ignore
        if (e.code === 'auth/too-many-requests') {
          setError(true);
          setErrorText(t('auth-page__many-request'));
        }
        // @ts-ignore
        if (e.code === 'auth/invalid-phone-number') {
          setError(true);
          setErrorText(t('validator__phone-format-error'));
        }
      } finally {
        setIsPending(false);
      }
    }
  };

  const handlePress: KeyboardEventHandler = async (e) => {
    if (e.key === 'Enter') await handleClick();
  };

  useEffect(() => {
    firebaseProvider.initRecaptha('phone-sign-in-btn');
  }, []);

  return (
    <div className="phone-login">
      <div className={`form-group ${error ? 'form-group--is-invalid' : ''}`}>
        <PhoneInput
          placeholder={t('auth-page__phone-placeholder')}
          value={phone}
          onChange={handleChange}
          onKeyDown={handlePress}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          smartCaret={false}
          ref={inputRef}
        />
        {
          error && errorText
            ? (<div className="invalid-feedback">{errorText}</div>)
            : (
              <small className="form-text">
                {t('auth-page__phone-help')}
              </small>
            )
        }
      </div>
      <div className="phone-login__submit-box">
        <button
          id="phone-sign-in-btn"
          className="btn btn-lg btn-block sv-btn-blue sv-btn"
          onClick={handleClick}
          disabled={isPending}
        >
          {t('auth-page__btn')}
        </button>
      </div>
      <div className="phone-login__rules">
        {t('auth-page__rule-start')}{' '}
        <a href="/privacy" target="_blank">{t('auth-page__rule-privacy')}</a>
        {' '}{t('auth-page__rule-and')}{' '}
        <a href="/therms" target="_blank">{t('auth-page__rule-licence')}</a>
      </div>

      <PhoneLoginModal phone={phone} isOpen={isOpen} toggle={toggle} />
    </div>
  );
};
