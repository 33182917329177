import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';
import { ICountryRes } from 'api/countries.types';

export const selectAllCountries = ({ global }: TRootState): ICountryRes[] => {
  return global.countries.data;
};

export const selectCountriesLoading = ({ global }: TRootState): boolean => {
  return global.countries.loading;
};

export const selectCurrentPage = ({ global }: TRootState): number => {
  return global.countries.pagination.currentPage;
};

export const selectPageCount = ({ global }: TRootState): number => {
  return global.countries.pagination.pageCount;
};

export const selectHasMoreCountries = createSelector(
  selectCurrentPage,
  selectPageCount,
  (currentPage, pageCount): boolean => currentPage < pageCount,
);
