import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VIEW_IS_LIST } from 'constants/cookieNames';
import { IViewStore } from './types';


const initialState: IViewStore = {
  isList: false,
};

export const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    updateIsListView(state, action: PayloadAction<boolean>) {
      localStorage.setItem(VIEW_IS_LIST, String(action.payload));
      state.isList = action.payload;
    },
    initView(state, action: PayloadAction<boolean>) {
      state.isList = action.payload;
    },
  },
});

export const { updateIsListView, initView } = viewSlice.actions;
export const viewReducer = viewSlice.reducer;
