import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AlbumTypes } from 'api/albums.types';
import { selectFavoriteAlbumId } from 'redusers/albums';

/**
 * return [isAlbum, isSpecialAlbum]
 */
export const useIsSpecialAlbum = (uuid?: string, type?: AlbumTypes): boolean => {
  const favoriteAlbumUUID = useSelector(selectFavoriteAlbumId);
  // сравниваем типы чтобы потом могли определять что этот альбом относится к конкретному типу
  // и соответсвенно отображать  необходимый UI
  if (type === AlbumTypes.favorite) {
    return favoriteAlbumUUID === uuid;
  }
  return favoriteAlbumUUID === uuid;
};

/**
 * return [isAlbum, isSpecialAlbum]
 */
export const useIsSpecialAlbumByURL = (type?: AlbumTypes): [boolean, boolean] => {
  const { albumUUID } = useParams<string>();
  const isSpecial = useIsSpecialAlbum(albumUUID, type);
  return [!!albumUUID, isSpecial];
};
