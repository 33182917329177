import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { selectQuotaTotalBytes, selectQuotaUsedBytes } from 'redusers/global/quota';
import { fileSizeConverter } from 'utils/fileSizeConverter';
import { IQuotaBoxComponentProps, IQuotaBoxProps } from './quotaBox.types';

import './quota-box.scss';

export const QuotaBoxComponent = (
  { className, percent, usedBytes, totalBytes }: IQuotaBoxComponentProps,
) => {
  return (
    <div className={`quota-box ${className}`}>
      <div className="quota-box__info">
        <Trans
          i18nKey="quota-box__info"
          values={{ usedQuota: fileSizeConverter(usedBytes), totalQuota: fileSizeConverter(totalBytes) }}
        />
      </div>
      <div className="progress">
        <div
          className="progress-bar"
          style={{ width: `${percent >= 1 || percent <= 0 ? percent : 1}%` }}
        />
      </div>
    </div>
  );
};

export const QuotaBox = ({ className }: IQuotaBoxProps) => {
  const totalBytes = useSelector(selectQuotaTotalBytes);
  const usedBytes = useSelector(selectQuotaUsedBytes);

  const props = {
    className,
    totalBytes,
    usedBytes,
    percent: (usedBytes / totalBytes) * 100,
  };

  return <QuotaBoxComponent {...props} />;
};
