import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { createEpicMiddleware } from 'redux-observable';

import API from 'api';
import reduxThunk from 'utils/reduxThunk';
import { changeRouterMiddleware } from './changeRouterMiddleware';

import epics from './epics';
import reducerInit from './reducer';

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({ history: createBrowserHistory() });

const reducer = reducerInit(routerReducer);

export const initStoreAndHistory = (() => {
  let data: any = null;

  return (initialState = {}) => {
    if (data) return data;

    const epicMiddleware = createEpicMiddleware<any, any, TRootState>({
      dependencies: API,
    });

    const store = createStore(
      reducer,
      initialState,
      composeWithDevTools(
        applyMiddleware(routerMiddleware, changeRouterMiddleware, reduxThunk, epicMiddleware),
      ),
    );
    epicMiddleware.run(epics);

    data = { store, history: createReduxHistory(store) };

    return data;
  };
})();

export type TRootState = ReturnType<typeof reducer>;
