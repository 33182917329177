import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';
import { IDeviceCatalogRes } from 'api/devices.types';

export const selectDevicesCatalog = ({ global }: TRootState): IDeviceCatalogRes[] => {
  return global.devicesCatalog.data;
};

export const selectDevicesCatalogLoading = ({ global }: TRootState): boolean => {
  return global.devicesCatalog.loading;
};

export const selectSkuByName = createSelector(
  selectDevicesCatalog,
  (_: any, name: string): string => name,
  (devices, name): string => {
    const curDevice = devices.find((device) => device.name === name);
    return curDevice ? curDevice.sku : '';
  },
);
