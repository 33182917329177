import { useState, useRef, useEffect, useCallback } from 'react';

export default (initSeconds) => {
  const timer = useRef();
  const [seconds, setSeconds] = useState(initSeconds);

  const timeReduction = useCallback(() => {
    setSeconds(seconds - 1);
  }, [seconds]);

  useEffect(() => {
    if (seconds <= 0) {
      clearTimeout(timer.current);
    } else {
      timer.current = setTimeout(timeReduction, 1000);
    }

    return () => clearTimeout(timer.current);
  }, [seconds, timeReduction]);

  const restartFn = () => setSeconds(initSeconds);
  const stopFn = () => setSeconds(0);

  return [seconds, seconds <= 0, restartFn, stopFn];
};
