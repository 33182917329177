import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditProfileModal } from './editProfileModal';
import './editProfile.scss';

export const EditProfile = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <span onClick={toggle} className="edit-profile-btn">
        {t('edit_profile__toggle_btn')}
      </span>
      <EditProfileModal isOpen={isOpen} toggle={toggle} />
    </>
  );
};
