import { Children, useState, TouchEventHandler, cloneElement } from 'react';
import { ISwipeProps } from './swipe.types';

export const Swipe = ({ children, handleSwipeLeft, handleSwipeRight }: ISwipeProps): any => {
  const [startSwipe, setStartSwipe] = useState(false);
  const [startPosition, setStartPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

  const handleTouchStart: TouchEventHandler = (e) => {
    setStartSwipe(true);
    setStartPosition({
      x: e.changedTouches[0].pageX,
      y: e.changedTouches[0].pageY,
    });
  };

  const handleTouchEnd: TouchEventHandler = (e) => {
    if (!startSwipe) return;
    const { pageX, pageY } = e.changedTouches[0];

    if (Math.abs(startPosition.y - pageY) < 60) {
      const type = startPosition.x < pageX ? 'right' : 'left';

      switch (type) {
        case 'left': {
          if (startPosition.x > pageX + 90) {
            if (handleSwipeLeft) handleSwipeLeft();
          }
          break;
        }
        case 'right': {
          if (startPosition.x + 90 < pageX) {
            if (handleSwipeRight) handleSwipeRight();
          }
          break;
        }

        default:
          console.log('udefined swipe');
      }
    }

    setStartSwipe(false);
    setStartPosition({ x: 0, y: 0 });
  };

  return Children.map(children, (child: any) => {
    return cloneElement(child, { ...child, onTouchStart: handleTouchStart, onTouchEnd: handleTouchEnd });
  });

  // return Children.map(children, (child: any) => ({
  //   ...child,
  //   props: {
  //     ...child.props,
  //     onTouchStart: handleTouchStart,
  //     onTouchEnd: handleTouchEnd,
  //   },
  // }));
};
