import React from 'react';
import { AlbumTypes } from 'api/albums.types';
import { IAddFileToAlbumItemProps } from './addFileToAlbums.types';

import './addFileToAlbumItem.scss';

export const AddFileToAlbumItem = ({ album }: IAddFileToAlbumItemProps) => {
  if (album.type === AlbumTypes.favorite) return null;

  return (
    <div className="add-file-to-album-item" data-album-uuid={album.uuid}>
      <div
        className="add-file-to-album-item__preview"
        style={album.preview_url ? { backgroundImage: `url(${album.preview_url})` } : {}}
      />
      <span className="add-file-to-album-item__title">{album.name}</span>
    </div>
  );
};
