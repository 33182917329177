import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectAuthAccountName,
  selectAuthAccountAvatar,
  selectAvatarIsLoading,
  selectAuthCountryId,
  selectAuthCountryName,
  updateUser,
} from 'redusers/global/auth';
import { Icon } from 'components/common/Icon';
import { EditProfileForm } from 'components/forms/EditProfileForm';
import { THandleSubmit } from 'components/forms/EditProfileForm/editProfileForm.types';
import { EditAvatar } from './editAvatar';
import { IEditProfileModalComponentProps, IEditProfileModalProps } from './editProfileModal.types';

import './editProfileModal.scss';

export const EditProfileModalComponent = ({
  isOpen,
  toggle,
  userAvatar,
  avatarIsLoading,
  userName,
  countryName,
  countryId,
  handleSubmit,
}: IEditProfileModalComponentProps) => {
  const { t } = useTranslation();
  return (
    <Modal show={isOpen} onHide={toggle} className="custom-modal edit-profile-modal" centered>
      <Modal.Header closeButton>
        <Icon type="AccountIcon" className="custom-modal__icon" />
        <div className="custom-modal__title">{t('edit_profile__modal-title')}</div>
      </Modal.Header>
      <Modal.Body>
        <EditAvatar userAvatar={userAvatar} loading={avatarIsLoading} />
        <EditProfileForm
          initialValues={{ country: { name: countryName || '', value: countryId || '' }, name: userName || '' }}
          handleSubmit={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};

export const EditProfileModal = (props: IEditProfileModalProps) => {
  const dispatch = useDispatch();
  const userName = useSelector(selectAuthAccountName);
  const userAvatar = useSelector(selectAuthAccountAvatar);
  const countryId = useSelector(selectAuthCountryId);
  const countryName = useSelector(selectAuthCountryName);
  const avatarIsLoading = useSelector(selectAvatarIsLoading);
  const { toggle } = props;

  const handleSubmit: THandleSubmit = (values) => {
    dispatch(updateUser(values));
    toggle();
  };

  const newProps = {
    ...props,
    toggle,
    userName,
    userAvatar,
    countryId,
    countryName,
    avatarIsLoading,
    handleSubmit,
  };

  return <EditProfileModalComponent {...newProps} />;
};
