import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IDeviceWithInfoFromCatalog, selectDevicesWithCatalogInfo } from 'redusers/global/devices';
import { getLastDeviceFirmwares } from 'api/devices.api';
import { Placeholder } from 'components/common/Placeholder';
import { DeviceItem } from '../DeviceItem';
import { DeviceModal } from '../DeviceModal';
import { IDevicesListComponentProps } from './types';
import './devicesList.scss';

export const DevicesListComponent = ({ devices, serialNumbersWithUpdate }: IDevicesListComponentProps) => {
  const { t } = useTranslation();
  const [activeDevice, setActiveDevice] = useState<IDeviceWithInfoFromCatalog | null>(null);

  const handleOpenDevice = (device: IDeviceWithInfoFromCatalog) => setActiveDevice(device);

  const handleCloseDeviceModal = () => setActiveDevice(null);

  return (

    <>
      <div className="row devices-list">
        {
          devices.length > 0
            ? devices.map((device) => (
              <DeviceItem
                key={device.serial_number}
                device={device}
                handleOpenDevice={handleOpenDevice}
                hasNewFirmware={serialNumbersWithUpdate.includes(device.serial_number)}
              />
            ))
            : <Placeholder text={t('devices__no-devices')} classNames="mx-auto" />
        }
      </div>
      {
        activeDevice
        && (
          <DeviceModal
            isOpen={!!activeDevice}
            closeModalHandler={handleCloseDeviceModal}
            device={activeDevice}
            hasNewFirmware={serialNumbersWithUpdate.includes(activeDevice.serial_number)}
          />
        )
      }
    </>
  );
};

export const DevicesList = () => {
  const [serialNumbersWithUpdate, setSerialNumbersWithUpdate] = useState<string[]>([]);

  const devices = useSelector(selectDevicesWithCatalogInfo);

  useEffect(() => {
    const checkUpdates = async () => {
      try {
        const reqProps = devices.map((device) => ({
          device_serial: device.serial_number,
          device_sku: device.sku,
          device_hardware_ver: device.hardware_version as string,
          device_software_ver: device.software_version as string,
        }));

        const { data: updates } = await getLastDeviceFirmwares(reqProps);
        const serialNumbers = updates.map((update) => update.device_serial);
        if (serialNumbers.length > 0) setSerialNumbersWithUpdate(serialNumbers);
        else setSerialNumbersWithUpdate([]);
      } catch (e) {
        setSerialNumbersWithUpdate([]);
      }
    };
    if (devices.length > 0) checkUpdates();
  }, [devices]);

  return <DevicesListComponent serialNumbersWithUpdate={serialNumbersWithUpdate} devices={devices} />;
};
