import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';

export const selectQuota = (state: TRootState) => state.global.quota;

export const selectQuotaTotalBytes = createSelector(
  selectQuota,
  (quota) => quota.totalBytes,
);

export const selectQuotaUsedBytes = createSelector(
  selectQuota,
  (quota) => quota.usedBytes,
);
