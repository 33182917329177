import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { IFileRes } from 'api/files.types';
import { authUserLogout } from 'redusers/global/auth';

import { ISearchState } from './types';

const initialState: ISearchState = {
  loading: false,
  searchString: '',
  detectedString: '',
  data: [],
};

export const searchStrDetect = createAction<string>('search/searchStrDetect');

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    changeSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    changeDetectedString: (state, action: PayloadAction<string>) => {
      state.detectedString = action.payload;
    },
    searchFetchingStart: (state) => {
      state.loading = true;
    },

    searchFetchingSuccess: (state, action: PayloadAction<IFileRes[]>) => {
      state.loading = false;
      state.data = action.payload;
    },

    searchFetchingFailed: (state) => {
      state.loading = false;
      state.data = [];
    },

    searchClear: (state) => {
      state.loading = false;
      state.searchString = '';
      state.detectedString = '';
      state.data = [];
    },

    searchDeleteItemByIds: (state, { payload }: PayloadAction<string[]>) => {
      state.data = state.data.filter((file) => !payload.includes(file.id));
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(authUserLogout.type, (state) => {
        state.loading = false;
        state.searchString = '';
        state.data = [];
      });
  },
});

export const {
  changeSearchString,
  changeDetectedString,
  searchFetchingStart,
  searchFetchingSuccess,
  searchFetchingFailed,
  searchClear,
  searchDeleteItemByIds,
} = searchSlice.actions;
export const searchReducer = searchSlice.reducer;
