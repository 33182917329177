import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';

import FileForm from 'components/forms/FileForm';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { fileUpdateStart } from 'redusers/files/list';
import { selectCtxModalLoading, selectCtxModalData } from 'redusers/ctxModal';

import { IFileEditComponentProps, THandleSubmit } from './fileEdit.types';

export const FileEditComponent = ({ handleSubmit, loading, data }: IFileEditComponentProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon type="SquareEditOutline" className="custom-modal__icon" />
        <div className="custom-modal__title">{t('context-modal__rename-file')}</div>
      </Modal.Header>
      <Modal.Body>
        <FileForm
          handleSubmit={handleSubmit}
          loading={loading}
          initialValues={{ name: data.name }}
          submitText={t('global__update')}
        />
      </Modal.Body>
    </Fragment>
  );
};

export const FileEdit = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectCtxModalLoading);
  const data = useSelector(selectCtxModalData);

  if (!data) return null;

  const handleSubmit: THandleSubmit = (values) => {
    dispatch(fileUpdateStart({ id: data.id, values }));
    sendGtmTrigger('sv2_file_rename');
  };

  return <FileEditComponent handleSubmit={handleSubmit} data={data} loading={loading} />;
};
