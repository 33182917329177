import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import classNames from 'classnames';
import toBoolean from 'utils/toBoolean';
import { LIGHT_THEME } from 'constants/cookieNames';
import { ReactComponent as LightIcon } from 'static/icons/theme-light-icon.svg';
import { ReactComponent as DarkIcon } from 'static/icons/theme-dark-icon.svg';

import { IThemeSwitcherProps } from './themeSwitcher.types';
import './index.scss';

export const ThemeSwitcher = ({ className }: IThemeSwitcherProps) => {
  const [checked, setChecked] = useState(toBoolean(localStorage.getItem(LIGHT_THEME)));
  const handleChange = (nextChecked: boolean) => {
    setChecked(nextChecked);
    localStorage.setItem(LIGHT_THEME, String(nextChecked));
  };

  useEffect(() => {
    if (checked) document.body.classList.add('light');
    else document.body.classList.remove('light');
  }, [checked]);

  useEffect(() => {
    const isLight = localStorage.getItem(LIGHT_THEME);
    if (isLight === 'true') setChecked(true);
  }, []);

  const elementClasses = classNames('theme-switcher', className);

  return (
    <Switch
      onChange={handleChange}
      checked={checked}
      className={elementClasses}
      uncheckedIcon={<DarkIcon />}
      checkedIcon={<LightIcon />}
    />
  );
};
