import React from 'react';
import { Logo } from 'components/common/Logo';
import { TopHeader } from 'components/TopHeader';
import { Sidebar } from 'components/Sidebar';
import { ContextModal } from 'components/common/ContextModal';
import { SelectionBar } from 'components/SelectionBar';
import { DownloadManagerContainer } from 'components/downloadManager/DownloadManagerContainer';

import { IDefLayoutProps } from './defLayout.types';

export const DefLayout = ({ children, className }: IDefLayoutProps) => {
  return (
    <>
      <div className="def-layout def-layout-top">
        <div className="def-layout__sidebar def-layout__logo-sidebar">
          <Logo />
        </div>
        <div className="def-layout__content">
          <TopHeader rightBoxClassNames="d-none d-lg-flex" />
        </div>
      </div>
      <div className={`def-layout ${className}`}>
        <div className="def-layout__sidebar">
          <Sidebar />
        </div>
        <div className="def-layout__content">
          {children}
        </div>
      </div>
      <ContextModal />
      <DownloadManagerContainer />
      <SelectionBar />
    </>
  );
};
