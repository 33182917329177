import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { closeCtxModal, selectCtxModalIsOpen, selectCtxModalType } from 'redusers/ctxModal';
import { ModalTypes } from 'types/modal.types';

import { SettingsLanguage } from './SettingsLanguage';
import { SettingsDeleteAccount } from './SettingsDeleteAccount';
import { FileEdit } from './FileEdit';
import { FileShare } from './FileShare';
import { Details } from './Details';
import { Remove } from './Remove';
import { BlockedAccount } from './BlockedAccount';
import { AlbumRemove } from './AlbumRemove';
import { AlbumRename } from './AlbumRename';
import { AddFileToAlbum } from './AddFileToAlbum';
import { DeleteFileFromAlbum } from './DeleteFileFromAlbum';
import { IContextModalComponentProps } from './contextModal.types';

const ContextModalComponent = ({ isOpen, modalType, closeModalHandler }: IContextModalComponentProps) => {
  const renderBody = () => {
    if (modalType === ModalTypes.CTX_MODAL_SETTINGS_LANGUAGE) return <SettingsLanguage />;
    if (modalType === ModalTypes.CTX_MODAL_SETTINGS_DELETE_ACCOUNT) return <SettingsDeleteAccount />;
    if (modalType === ModalTypes.CTX_MODAL_RENAME) return <FileEdit />;
    if (modalType === ModalTypes.CTX_MODAL_SHARE) return <FileShare />;
    if (modalType === ModalTypes.CTX_MODAL_DETAILS) return <Details />;
    if (modalType === ModalTypes.CTX_MODAL_REMOVE) return <Remove />;
    if (modalType === ModalTypes.CTX_MODAL_BLOCKED_ACCOUNT) return <BlockedAccount />;
    if (modalType === ModalTypes.CTX_MODAL_ALBUM_REMOVE) return <AlbumRemove />;
    if (modalType === ModalTypes.CTX_MODAL_ALBUM_RENAME) return <AlbumRename />;
    if (modalType === ModalTypes.CTX_MODAL_ALBUM_FILE_TO_ALBUM) return <AddFileToAlbum />;
    if (modalType === ModalTypes.CTX_MODAL_ALBUM_DELETE_FILE_FROM_ALBUM) return <DeleteFileFromAlbum />;
    return null;
  };

  return (
    <Modal show={isOpen} onHide={closeModalHandler} className="custom-modal" centered>
      {renderBody()}
    </Modal>
  );
};

export const ContextModal = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(selectCtxModalType);
  const isOpen = useSelector(selectCtxModalIsOpen);

  const closeModalHandler = () => dispatch(closeCtxModal());

  return <ContextModalComponent modalType={modalType} isOpen={isOpen} closeModalHandler={closeModalHandler} />;
};
