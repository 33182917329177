import React from 'react';
import { Link } from 'react-router-dom';
import { ILogoProps, ILogoWrapProps } from './logo.types';

import './logo.scss';

const LogoWrap = ({ children, logoWithoutLink, className }: ILogoWrapProps) => {
  if (logoWithoutLink) {
    return <div className={`logo-wrap ${className}`}>{children}</div>;
  }

  return <Link to="/" className={`logo-wrap ${className}`}>{children}</Link>;
};

export const Logo = ({ logoWithoutLink = false, className } : ILogoProps) => {
  return (
    <LogoWrap logoWithoutLink={logoWithoutLink} className={className}>
      <img className="top-header__black-logo" src="/static/logo.png" alt="" />
      <img className="top-header__white-logo" src="/static/logo_white.png" alt="" />
    </LogoWrap>
  );
};
