import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectedState } from './types';

const initialState: ISelectedState = {
  beforeShift: [],
  indexes: [],
  lastIndex: null,
  withCtx: false,
};

const getShiftedIndexes = (index: number, lastIndex: number) => {
  const shiftRevert = lastIndex > index;
  return new Array(shiftRevert ? lastIndex - index : index - lastIndex).fill(undefined)
    .map((_: any, idx: number) => {
      if (shiftRevert) return index + idx;
      return lastIndex + idx + 1;
    });
};

const getCtrlIndexes = (index: number, indexes: number[]) => {
  const selectedIndex = indexes.indexOf(index);

  if (selectedIndex >= 0) indexes.splice(selectedIndex, 1);
  else indexes.push(index);
  return indexes;
};

export const selectedSlice = createSlice({
  name: 'selected',
  initialState,
  reducers: {
    selectFile: (state, action: PayloadAction<number>) => {
      state.indexes = [action.payload];
      state.beforeShift = [action.payload];
      state.lastIndex = action.payload;
      state.withCtx = false;
    },
    selectFileWithShift: (state, action: PayloadAction<number>) => {
      if (state.lastIndex === null) {
        state.indexes = [action.payload];
        state.beforeShift = [action.payload];
        state.lastIndex = action.payload;
        state.withCtx = false;
      } else {
        state.indexes = Array.from(
          // с помощью Set избавляемя от дублей индексов
          new Set(
            state.indexes.concat(getShiftedIndexes(action.payload, state.lastIndex)),
          ),
        );
      }
    },

    selectFileWithCtrl: (state, action: PayloadAction<number>) => {
      const newSelectedIndexes = getCtrlIndexes(action.payload, state.indexes.slice());
      state.lastIndex = action.payload;
      state.indexes = newSelectedIndexes;
      state.beforeShift = newSelectedIndexes;
      state.withCtx = false;
    },

    selectFileWithCtx: (state, action: PayloadAction<number>) => {
      state.indexes = [action.payload];
      state.beforeShift = [action.payload];
      state.lastIndex = action.payload;
      state.withCtx = true;
    },

    selectFileFromFrame: (state, action: PayloadAction<number[]>) => {
      state.indexes = action.payload;
    },

    selectFileClear: (state) => {
      if (state.indexes.length) {
        state.beforeShift = [];
        state.indexes = [];
        state.lastIndex = null;
        state.withCtx = false;
      }
    },
  },

  extraReducers: (builder) => {
    // todo очищать стор по этим эвентам, переделать
    // 'filters/filtersUpdate':
    // '@@router/LOCATION_CHANGE':
    builder
      .addCase('@@router/LOCATION_CHANGE', (state) => {
        state.beforeShift = [];
        state.indexes = [];
        state.lastIndex = null;
        state.withCtx = false;
      })
      .addCase('filters/filtersUpdate', (state) => {
        state.beforeShift = [];
        state.indexes = [];
        state.lastIndex = null;
        state.withCtx = false;
      });
  },
});

export const {
  selectFile,
  selectFileWithShift,
  selectFileWithCtrl,
  selectFileWithCtx,
  selectFileFromFrame,
  selectFileClear,
} = selectedSlice.actions;
export const selectedReducer = selectedSlice.reducer;
