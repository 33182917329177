import { toast } from 'react-toastify';

const getToastType = (type) => {
  switch (type) {
    case 'info':
      return toast.TYPE.INFO;
    case 'dark':
      return toast.TYPE.DARK;
    case 'warning':
      return toast.TYPE.WARNING;
    case 'error':
      return toast.TYPE.ERROR;
    case 'success':
      return toast.TYPE.SUCCESS;
    case 'default':
      return toast.TYPE.DEFAULT;
    default:
      return toast.TYPE.DEFAULT;
  }
};

export default (text, { type, delay = 2000 }) => {
  toast.dismiss();

  toast(text, {
    type: getToastType(type),
    autoClose: delay,
  });
};
