import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPayloadWithPagination } from 'types/pagination.types';

import { AlbumTypes, IAlbumRes, IUpdateAlbumValues } from 'api/albums.types';
import { IAlbumsState, ICreateAlbumStartPayload } from './albums.types';

const initialState: IAlbumsState = {
  loading: false,
  data: [],
  currentId: undefined,
  current: undefined,
  favoriteAlbumId: '',
  pagination: {
    currentPage: 0,
    pageCount: 0,
    perPage: 0,
    totalCount: 0,
  },
};

export const createAlbumStart = createAction<ICreateAlbumStartPayload>('albums/createAlbumStart');
export const deleteAlbumStart = createAction<{ uuid: string }>('albums/deleteAlbumStart');
export const deleteAlbumFailed = createAction('albums/deleteAlbumFailed');
export const updateAlbumStart = createAction<{ uuid: string, values: IUpdateAlbumValues }>('albums/updateAlbumStart');

export const albumsSlice = createSlice({
  name: 'albums',
  initialState,
  reducers: {
    albumsFetchingStart: (state) => {
      state.loading = true;
    },

    albumsFetchingSuccess: (state, { payload }: PayloadAction<IPayloadWithPagination<IAlbumRes[]>>) => {
      state.loading = false;
      state.data = payload.data;
      state.pagination = payload.pagination;

      const favorite = payload.data.find((album) => album.type === AlbumTypes.favorite);
      if (favorite) state.favoriteAlbumId = favorite.uuid;

      state.current = payload.data.find((album) => album.uuid === state.currentId);
    },

    albumsFetchingFailed: (state) => {
      state.loading = initialState.loading;
      state.data = initialState.data;
      state.pagination = initialState.pagination;
    },

    setCurrentAlbumByUUID: (state, { payload }: PayloadAction<string>) => {
      state.currentId = payload;
      state.current = state.data.find((album) => album.uuid === payload);
    },

    removeCurrentAlbum: (state) => {
      state.current = undefined;
      state.currentId = undefined;
    },

    createAlbumSuccess: (state, { payload }: PayloadAction<IAlbumRes>) => {
      const [firstAlbum, ...otherAlbums] = state.data;
      state.data = [firstAlbum, payload, ...otherAlbums];
    },

    createAlbumFailed: (state, action: PayloadAction) => {

      // console.log(action);
      // todo реализовать
      //state.searchString = action.payload;
    },

    deleteAlbumSuccess: (state, { payload }: PayloadAction<{ uuid: string }>) => {
      state.data = state.data.filter((album) => album.uuid !== payload.uuid);
    },

    updateAlbumSuccess: (state, { payload }: PayloadAction<IAlbumRes>) => {
      state.data = state.data.slice().map((album) => {
        if (album.uuid === payload.uuid) return payload;
        return album;
      });
    },
  },
});

export const {
  albumsFetchingStart,
  albumsFetchingSuccess,
  albumsFetchingFailed,
  createAlbumSuccess,
  createAlbumFailed,
  deleteAlbumSuccess,
  updateAlbumSuccess,
  setCurrentAlbumByUUID,
  removeCurrentAlbum,
} = albumsSlice.actions;
export const albumsReducer = albumsSlice.reducer;
