import React from 'react';
import classNames from 'classnames';

import { ContextModal } from 'components/common/ContextModal';
import { IAuthLayoutProps } from './types';

export const AuthLayout = ({ children, className, bgNumber }: IAuthLayoutProps) => {
  const layoutClasses = classNames('auth-layout', className);

  return (
    <React.Fragment>
      <div className={layoutClasses} data-bg={bgNumber}>
        {children}
      </div>
      <ContextModal />
    </React.Fragment>
  );
};
