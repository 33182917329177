import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';
import { selectDevicesLoading, selectDevicesWithCatalogInfo } from 'redusers/global/devices';
import { Spinner } from 'components/common/Spinner';
import { DropDownModalItem } from 'components/common/DropDownModal';
import { FilterDeviceItem } from './filterDeviceItem';
import { IFilterDeviceComponentProps, IFilterDeviceProps } from './types';

export const FilterDeviceComponent = ({
  currentDevicesSerials,
  clearDeviseSerials,
  setDeviseSerials,
  devices,
  loading,
}: IFilterDeviceComponentProps) => {
  const { t } = useTranslation();

  if (loading) return <Spinner size={40} />;
  // у пользовтаел 1 девайс или их нет, соответсвенно нечего фильтровать
  // if (devices.length <= 1) return null;

  return (
    <Fragment>
      <DropDownModalItem>
        <div className="filter-picker__header">{t('filter__device-block')}</div>
      </DropDownModalItem>
      <DropDownModalItem
        className={`filter-picker__item ${!currentDevicesSerials.length ? 'filter-picker__item--active' : ''}`}
        handleClick={clearDeviseSerials}
      >
        <div className="filter-picker__item-wrap">
          <Icon type="Device" className="filter-picker__icon" />
          <div className="filter-picker__item-info">
            <span className="filter-picker__item-name">
              {t('filter__all-devices')}
            </span>
          </div>
        </div>
      </DropDownModalItem>
      {devices.map((device) => (
        <FilterDeviceItem
          key={device.serial_number}
          device={device}
          setDeviseSerials={setDeviseSerials}
          active={currentDevicesSerials.includes(device.serial_number)}
        />
      ))}
    </Fragment>
  );
};

export const FilterDevice = (props: IFilterDeviceProps) => {
  const loading = useSelector(selectDevicesLoading);
  const devices = useSelector(selectDevicesWithCatalogInfo);

  const componentProps = { loading, devices, ...props };

  return <FilterDeviceComponent {...componentProps} />;
};
