import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';
import { openCtxModal } from 'redusers/ctxModal';
import { ModalTypes } from 'types/modal.types';
import { ElementTypes } from 'types/element.types';
import { IContextMenuAlbumItemsProps, IContextMenuAlbumItemsComponentProps } from './contextMenu.types';

export const ContextMenuAlbumItemsComponent = ({ openRename, openRemove }: IContextMenuAlbumItemsComponentProps) => {
  const { t } = useTranslation();

  const renderRename = () => {
    return (
      <div className="context-menu-block__item" onClick={openRename}>
        <Icon type="SquareEditOutline" />
        {t('context-menu__rename')}
      </div>
    );
  };

  const renderRemove = () => {
    return (
      <div className="context-menu-block__item" onClick={openRemove}>
        <Icon type="DeleteOutline" />
        {t('context-menu__remove')}
      </div>
    );
  };

  return (
    <>
      {renderRename()}
      {renderRemove()}
    </>
  );
};

export const ContextMenuAlbumItems = ({ elIdx }: IContextMenuAlbumItemsProps) => {
  const dispatch = useDispatch();
  const open = (modalType: ModalTypes) => {
    dispatch(openCtxModal({ elementType: ElementTypes.ALBUM, modalType, elementIdx: parseInt(elIdx) }));
  };

  const openRename = () => open(ModalTypes.CTX_MODAL_ALBUM_RENAME);
  const openRemove = () => open(ModalTypes.CTX_MODAL_ALBUM_REMOVE);

  return <ContextMenuAlbumItemsComponent openRename={openRename} openRemove={openRemove} />;
};
