import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DefLayout } from 'components/layouts/DefLayout';
import { AlbumsScreen } from 'components/AlbumsScreen';

import { albumsFetchingStart } from 'redusers/albums';

export const AlbumsPageComponent = () => {
  return (
    <DefLayout className="unselect-container">
      <AlbumsScreen />
    </DefLayout>
  );
};

export const AlbumsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(albumsFetchingStart());
  }, [dispatch]);

  return <AlbumsPageComponent />;
};
