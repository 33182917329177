import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuthProvider } from 'providers/refreshAuthProvider';
import { deleteAccount, uploadAvatar, deleteAvatar, updateAccount } from 'api/account.api';

import { authUserLogout } from './auth.slice';

export const logout = createAsyncThunk(
  'auth/userLogout',
  async (_, { dispatch }) => {
    dispatch(authUserLogout());
    refreshAuthProvider.deleteSessionCookie();
  },
);

export const deleteUserAccount = createAsyncThunk(
  'auth/deleteAccount',
  async (_, { dispatch }) => {
    const { status } = await deleteAccount();
    if (status === 200) {
      dispatch(authUserLogout());
      refreshAuthProvider.deleteSessionCookie();
    }
  },
);

export const uploadAvatarAccount = createAsyncThunk(
  'auth/uploadAvatar',
  async (file: any, { dispatch }) => {
    const { status, data } = await uploadAvatar(file);

    if (status === 200) return data;
    throw Error(data.toString());
  },
);

export const deleteAvatarAccount = createAsyncThunk(
  'auth/deleteAvatar',
  async () => {
    const { status, data } = await deleteAvatar();

    if (status === 200) return data;
    throw Error(data.toString());
  },
);

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async (values: any) => {
    const { status, data } = await updateAccount(values);

    if (status === 200) return data;
    throw Error(data.toString());
  },
);
