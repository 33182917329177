import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { exhaustMap, catchError, map } from 'rxjs/operators';

import API from 'api';
import { TRootState } from 'redusers';

import {
  devicesCatalogFetchingStart,
  devicesCatalogFetchingSuccess,
  devicesCatalogFetchingFailed,
} from './devicesCatalog.slice';

export const devicesCatalogFetchingEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getDevicesFromCatalog },
) => action$.pipe(
  ofType(devicesCatalogFetchingStart),
  exhaustMap(() => {
    return from(getDevicesFromCatalog()).pipe(
      map(({ status, data }) => {
        if (status !== 200) return devicesCatalogFetchingFailed();
        return devicesCatalogFetchingSuccess(data);
      }),
      catchError(() => of(devicesCatalogFetchingFailed())),
    );
  }),
);

export const devicesCatalogEpics = [devicesCatalogFetchingEpic];
