import React from 'react';
import classNames from 'classnames';

import { DownloadManagerContainer } from 'components/downloadManager/DownloadManagerContainer';
import { ISharingLayoutProps } from './sharingLayout.types';

export const SharingLayout = ({ children, className }: ISharingLayoutProps) => {
  const layoutClasses = classNames('sharing-layouts', className);

  return (
    <div>
      <div className={layoutClasses}>
        {children}
      </div>
      <DownloadManagerContainer />
    </div>
  );
};
