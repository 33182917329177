import * as Yup from 'yup';
import { pick } from 'lodash';

export default (validatorsMap, fieldsToValidate) => {
  const map = fieldsToValidate && fieldsToValidate.length
    ? pick(validatorsMap(), fieldsToValidate)
    : validatorsMap();

  return Yup.object().shape(map);
};
