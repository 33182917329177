import { combineReducers } from 'redux';
import { authSlice } from './auth';
import { viewSlice } from './view';
import { countriesSlice } from './countries';
import { devicesCatalogSlice } from './devicesCatalog';
import { quotaSlice } from './quota';
import { devicesSlice } from './devices';

export default combineReducers({
  [authSlice.name]: authSlice.reducer,
  [viewSlice.name]: viewSlice.reducer,
  [countriesSlice.name]: countriesSlice.reducer,
  [devicesCatalogSlice.name]: devicesCatalogSlice.reducer,
  [quotaSlice.name]: quotaSlice.reducer,
  [devicesSlice.name]: devicesSlice.reducer,
});
