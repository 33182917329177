import { createSlice, PayloadAction, createAction, AnyAction } from '@reduxjs/toolkit';
import { IAccountRes } from 'api/account.types';
import { IAuthFetchingStartPayload, IAuthState } from './types';
import { uploadAvatarAccount, deleteAvatarAccount, updateUser } from './auth.thunk';

const initialState: IAuthState = {
  loading: false,
  avatarIsLoading: false,
  updateIsLoading: false,
  isAuth: false,
  account: null,
};

export const authFetchingFailed = createAction('auth/authFetchingFailed');
export const authUserLogout = createAction('auth/authUserLogout');

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authFetchingStart: (state, action: PayloadAction<IAuthFetchingStartPayload | undefined>) => {
      state.loading = true;
    },

    authFetchingSuccess: (state, action: PayloadAction<IAccountRes>) => {
      state.loading = false;
      state.isAuth = true;
      state.account = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(updateUser.fulfilled, (state, action) => {
        state.account = action.payload;
        state.updateIsLoading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.updateIsLoading = true;
      })
      .addCase(updateUser.rejected, (state) => {
        state.updateIsLoading = false;
      })
      .addCase(uploadAvatarAccount.fulfilled, (state, action) => {
        state.account = action.payload;
        state.avatarIsLoading = false;
      })
      .addCase(uploadAvatarAccount.pending, (state) => {
        state.avatarIsLoading = true;
      })
      .addCase(uploadAvatarAccount.rejected, (state) => {
        state.avatarIsLoading = false;
      })
      .addCase(deleteAvatarAccount.fulfilled, (state, action) => {
        state.account = action.payload;
        state.avatarIsLoading = false;
      })
      .addCase(deleteAvatarAccount.pending, (state) => {
        state.avatarIsLoading = true;
      })
      .addCase(deleteAvatarAccount.rejected, (state) => {
        state.avatarIsLoading = false;
      })
      .addMatcher(
        ({ type }: AnyAction) => (type === authFetchingFailed.type || type === authUserLogout.type),
        (state) => {
          state.loading = false;
          state.isAuth = false;
          state.account = null;
        },
      );
  },
});

export const { authFetchingStart, authFetchingSuccess } = authSlice.actions;
export const authReducer = authSlice.reducer;
