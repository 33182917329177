import { useState, MutableRefObject } from 'react';

export enum MenuDirections {
  right = 'right',
  left = 'left',
}

type TChangeDirection = () => void;
// определеяет к какой стороне прижимать меню, в зависимости от того,
// где располагается элемнет раскрывающий меню.
// width - предполаагемая ширина меню
export const useMenuDirection = (
  boxWidth: number, ref: MutableRefObject<HTMLElement | null>,
): [MenuDirections, TChangeDirection] => {
  const [direction, setDirection] = useState(MenuDirections.right);

  const changeDirection = () => {
    if (ref?.current) {
      const { x, width } = ref.current.getBoundingClientRect();

      if (width + x >= boxWidth) setDirection(MenuDirections.right);
      else setDirection(MenuDirections.left);
    }
  };

  return [direction, changeDirection];
};
