export enum DownloadProcessStatuses {
  waiting = 'waiting',
  pending = 'pending',
  success = 'success',
  error = 'error',
}

export interface IDownloadProcessRes {
  id: number,
  name: string,
  estimated_size: number,
  status: DownloadProcessStatuses,
  loaded?: number,
  total_size?: number,
  part?: number,
  data_url?: string
}
