import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';
import { ISelectedState } from './types';
import { selectFiles } from '../list';

export const selectSelectedStore = ({ files }: TRootState): ISelectedState => {
  return files.selected;
};

export const selectSelectedFileIndexes = createSelector(
  selectSelectedStore,
  (state: ISelectedState): number[] => state.indexes,
);

export const selectCountSelectedFile = createSelector(
  selectSelectedFileIndexes,
  (indexes): number => indexes.length,
);

export const selectWithCtx = createSelector(
  selectSelectedStore,
  (state: ISelectedState): boolean => state.withCtx,
);

export const selectFileIsSelected = (idx: number) => createSelector(
  selectSelectedFileIndexes,
  (indexes: number[]): boolean => indexes.includes(idx),
);

export const selectIsManySelected = createSelector(
  selectSelectedFileIndexes,
  (indexes: number[]): boolean => indexes.length > 1,
);

export const selectTotalSizeSelectedFiles = createSelector(
  selectSelectedFileIndexes,
  selectFiles,
  (indexes, files): number => {
    return indexes.reduce((accum, filesIdx) => {
      const currFileSize = files[filesIdx]?.metadata?.size;
      if (currFileSize) return accum + currFileSize;
      return accum;
    }, 0);
  },
);
