import React from 'react';
import { useSelector } from 'react-redux';
import { selectTotalSizeSelectedFiles } from 'redusers/files/selected';
import { selectCtxModalIsMany, selectCtxModalData } from 'redusers/ctxModal';

import { DetailsMany } from './detailsMany';
import { DetailsSingle } from './detailsSingle';

import './details.scss';

export const Details = () => {
  const isMany = useSelector(selectCtxModalIsMany);
  const data = useSelector(selectCtxModalData);
  const totalSize = useSelector(selectTotalSizeSelectedFiles);

  if (isMany) return <DetailsMany totalSize={totalSize} />;
  if (data) return <DetailsSingle data={data} />;
  return null;
};
