import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';
import { FileTypes } from 'types/element.types';

export const selectFilterType = ({ filters }: TRootState): FileTypes | null => filters.type;

export const selectFilterDeviceSerials = ({ filters }: TRootState): string[] => filters.deviseSerials;

export const selectFilterIsOpen = ({ filters }: TRootState): boolean => filters.isOpen;

export const selectFilterCount = createSelector(
  selectFilterType,
  selectFilterDeviceSerials,
  (type, deviceSerials): number => {
    let count = deviceSerials.length;
    if (type) count++;
    return count;
  },
);
