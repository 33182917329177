import { createSlice, PayloadAction, createAction, AnyAction } from '@reduxjs/toolkit';
import { IDownloadProcessRes } from 'api/downloadManager.types';
import { authUserLogout } from 'redusers/global/auth';
import { IDownloadManagerState } from './types';

const initialState: IDownloadManagerState = {
  processes: [],
};

export const updateDownloadProcess = createAction<IDownloadProcessRes>('downloadManager/updateDownloadProcess');
export const downloadProcessFinish = createAction<IDownloadProcessRes>('downloadManager/downloadProcessFinish');
export const clearDownloadProcess = createAction('downloadManager/clearDownloadProcess');
export const createDownloadProcessStart = createAction('downloadManager/createDownloadProcessStart');
export const createDownloadProcessError = createAction('downloadManager/createDownloadProcessError');

export const downloadManagerSlice = createSlice({
  name: 'downloadManager',
  initialState,
  reducers: {
    createDownloadProcessSuccess: (state, action: PayloadAction<IDownloadProcessRes>) => {
      state.processes = state.processes.concat(action.payload);
    },

    deleteDownloadProcess: (state, action: PayloadAction<number>) => {
      state.processes = state.processes.filter((process) => process.id !== action.payload);
    },

    clearDownloadProcess: (state) => {
      state.processes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        ({ type }: AnyAction) => (type === updateDownloadProcess.type || type === downloadProcessFinish.type),
        (state, action: PayloadAction<IDownloadProcessRes>) => {
          state.processes = state.processes.map((process) => {
            if (process.id === action.payload.id) return action.payload;
            return process;
          });
        },
      )
      .addMatcher(
        ({ type }: AnyAction) => (type === authUserLogout.type || type === clearDownloadProcess.type),
        (state) => {
          state.processes = [];
        },
      );
  },
});

export const {
  createDownloadProcessSuccess,
  deleteDownloadProcess,
} = downloadManagerSlice.actions;
export const downloadManagerReducer = downloadManagerSlice.reducer;
