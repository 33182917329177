export const data = {
  body: '<path d="M14.7715 6.21467H20.0667V4.51127C20.1429 0.496597 19.7143 3.9588 18.8571 3.95881L15.4815 3.95881C15.0306 3.95881 14.7715 4.12758 14.7715 4.51127V6.21467Z" fill="currentColor"/>\n'
    + '<path d="M9.2285 18.3779H2.57141V20.0445C2.57141 20.3496 2.81876 20.597 3.12387 20.597H8.67604C8.98115 20.597 9.2285 20.3496 9.2285 20.0445V18.3779Z" fill="currentColor"/>\n'
    + '<path d="M14.7715 18.3779V20.0445C14.7715 20.3496 15.0188 20.597 15.3239 20.597H20.8761C21.1812 20.597 21.4286 20.3496 21.4286 20.0445V18.3779H14.7715Z" fill="currentColor"/>\n'
    + '<path d="M9.22852 17.273V7.31958H3.77749L2.99998 13.3537C2.57141 16.3537 2.57141 17.273 2.57141 17.273H9.22852Z" fill="currentColor"/>\n'
    + '<path d="M14.7715 17.273V7.31958H20.2225L21 13.3537C21.4286 16.3537 21.4286 17.273 21.4286 17.273H14.7715Z" fill="currentColor"/>\n'
    + '<path d="M13.6666 9.50188V8.39697H10.3335V9.50188V16.159H13.6666V9.50188ZM11.4476 13.3875V11.1685C11.4476 10.8634 11.695 10.616 12.0001 10.616C12.3052 10.616 12.5525 10.8634 12.5525 11.1685V13.3875C12.5525 13.6926 12.3052 13.9399 12.0001 13.9399C11.695 13.9399 11.4476 13.6926 11.4476 13.3875Z" fill="currentColor"/>\n'
    + '<path d="M9.22854 6.21466V4.51125C9.22854 4.19179 8.96941 3.9588 8.46318 3.9588L5.14288 3.95879C4.28566 3.9588 3.85716 0.496581 3.93334 4.51125V6.21466H9.22854Z" fill="currentColor"/>',
  width: 24,
  height: 24,
};