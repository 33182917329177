import moment from 'moment';
/* eslint-disable import/extensions,import/no-unresolved */
import { languageProvider } from '../providers/languageProvider';
/* eslint-enable import/extensions,import/no-unresolved */
import 'moment/locale/bg';
import 'moment/locale/zh-cn';
import 'moment/locale/cs';
import 'moment/locale/da';
import 'moment/locale/nl';
import 'moment/locale/et';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/el';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/lv';
import 'moment/locale/lt';
import 'moment/locale/nb';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sr';
import 'moment/locale/sl';
import 'moment/locale/es';
import 'moment/locale/sv';

moment.locale(languageProvider.getLanguageKey());

export default (date) => {
  return moment(date).format('L LT');
};
