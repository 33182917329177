import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { selectAuthAccountAvatar } from 'redusers/global/auth';
import { ThemeSwitcher } from 'components/common/ThemeSwitcher';
import { Logo } from 'components/common/Logo';
import { MobileMenu } from 'components/common/MobileMenu';
import { Avatar } from 'components/common/Avatar';
import { Search } from 'components/Search';
import { ITopHeaderComponentProps, ITopHeaderProps } from './types';

import './topHeader.scss';

export const TopHeaderComponent = ({
  className,
  avatarUrl,
  hiddenSearch = false,
  hiddenAvatar = false,
  hiddenMobileMenu = false,
  themeSwitcherClassNames,
  avatarClassNames,
  rightBoxClassNames,
}: ITopHeaderComponentProps) => {
  const elementClasses = classNames('top-header', className, {
    'top-header--hidden-search': hiddenSearch,
  });

  return (
    <div className={elementClasses}>
      {hiddenMobileMenu ? null : <MobileMenu />}
      <Logo className="d-block d-lg-none" />

      { hiddenSearch ? null : <Search /> }
      <div className={`top-header__right-box ${rightBoxClassNames}`}>
        <div className="top-header__profile-box">
          <ThemeSwitcher className={themeSwitcherClassNames} />
          {
            hiddenAvatar
              ? null
              : <Avatar className={avatarClassNames} avatarUrl={avatarUrl} />
          }
        </div>
      </div>
    </div>
  );
};

export const TopHeader = (props: ITopHeaderProps) => {
  const avatarUrl = useSelector(selectAuthAccountAvatar);
  const newProps = { ...props, avatarUrl };

  return <TopHeaderComponent {...newProps} />;
};
