import React from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import validators from 'validators';
import { editProfileMap } from 'validators/editProfileMap';
import SelectCountry from 'components/forms/inputs/SelectCountry';
import { ConnectedAccounts } from 'components/common/ConnectedAccounts';
import { selectUpdateIsLoading } from 'redusers/global/auth';
import {
  IEditProfileFormProps,
  IEditProfileFormComponentProps,
  IEditProfileInitialValues,
} from './editProfileForm.types';

import './editProfileForm.scss';

export const EditProfileFormComponent = ({ initialValues, loading, handleSubmit }: IEditProfileFormComponentProps) => {
  const { t } = useTranslation();
  const submit = async (values: IEditProfileInitialValues) => {
    handleSubmit({ country_id: values.country.value, name: values.name });
  };

  const EditProfileSchema = validators(editProfileMap);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditProfileSchema}
      onSubmit={submit}
    >
      {({ errors, touched, submitCount, values }) => {
        return (
          <Form className="edit-profile-form">
            <div className="form-group">
              <label htmlFor="name">{t('edit_profile__form-name-title')}</label>
              <Field
                type="text"
                maxLength={180}
                name="name"
                id="name"
                className={`form-control ${errors.name && touched.name && submitCount ? 'is-invalid' : null}`}
                placeholder={t('edit_profile__form_name_placeholder')}
                autoComplete="off"
              />
            </div>
            <div className="form-group">
              <label htmlFor="country_id">{t('edit_profile__form-location-title')}</label>
              <Field
                component={SelectCountry}
                name="country"
                id="country"
                className={`form-control ${errors.country && touched.country && submitCount ? 'is-invalid' : null}`}
                placeholder={t('edit_profile__form-location-placeholder')}
                autoComplete="off"
              />
            </div>
            <ConnectedAccounts className="edit-profile-form__connected-account" />
            <button
              className="btn sv-btn-blue sv-btn mx-auto"
              type="submit"
              disabled={values.name.length < 1 || loading}
            >
              {t('edit_profile__form-save')}
            </button>
          </Form>
        );
      }}

    </Formik>
  );
};

export const EditProfileForm = (props: IEditProfileFormProps) => {
  const loading = useSelector(selectUpdateIsLoading);

  const componentProps = { ...props, loading };

  return <EditProfileFormComponent {...componentProps} />;
};
