import { useState } from 'react';

// определяет позиции элемента в зависимости от того куда был совершен клик
// width, height - размеры показываемого элемента
export default (width, height) => {
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);

  const setCoordinates = (clientX, clientY) => {
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;

    const getHorizontalLeftPosition = () => {
      return clientX - width - 10 > 0 ? clientX - width - 10 : pageWidth - width - 10;
    };

    const x = pageWidth - clientX - 10 >= width ? clientX + 10 : getHorizontalLeftPosition();

    const y = pageHeight - clientY >= height ? clientY : pageHeight - height - 10;
    setPositionX(x);
    setPositionY(y);
  };

  return [positionX, positionY, setCoordinates];
};
