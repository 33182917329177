import React from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import './index.scss';

const TextareaAutosize = ({
  field,
  form,
  className,
  maxRows,
  minRows,
  ...props
}) => (
  <ReactTextareaAutosize
    className={`form-control custom-field__resize-textarea ${className}`}
    maxRows={maxRows}
    minRows={minRows}
    {...field}
    {...props}
  />
);

TextareaAutosize.defaultProps = {
  maxRows: 8,
  minRows: 4,
};

export default TextareaAutosize;
