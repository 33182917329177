import React, { Fragment, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';
import { SearchInput } from 'components/common/SearchInput';

import { Languages } from 'types/language.types';
import { SettingsLanguageItem } from './settingsLanguageItem';

import './settingsLanguage.scss';

export const SettingsLanguage = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const languages = Languages.filter((language) => {
    return language.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
      || (language.translate && language.translate.toLowerCase().indexOf(search.toLowerCase()) >= 0);
  });

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon type="BxGlobe" className="custom-modal__icon" />
        <div className="custom-modal__title">
          {t('settings__language')}
        </div>
      </Modal.Header>
      <Modal.Body className="custom-modal-languages">
        <SearchInput
          value={search}
          handleValueChange={setSearch}
          placeholder={t('search-language__placeholder')}
          className="custom-modal-languages__search"
        />
        <div className="custom-modal-languages__box">
          {languages.map((language) => (
            <SettingsLanguageItem key={language.key} language={language} />
          ))}
        </div>
      </Modal.Body>
    </Fragment>
  );
};
