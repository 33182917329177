import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import { searchClear, searchStrDetect } from 'redusers/search';

import { DefLayout } from 'components/layouts/DefLayout';
import MainScreen from 'components/MainScreen';
import { MainHeader } from 'components/MainHeader';

const SearchPageComponent = () => {
  return (
    <DefLayout className="unselect-container">
      <MainHeader titleLocaleKey="main__search-result" />
      <MainScreen />
    </DefLayout>
  );
};

export const SearchPage = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { query } = qs.parse(search) as { query: string };
    dispatch(searchStrDetect(query));

    return () => {
      dispatch(searchClear());
    };
  }, [dispatch, search]);

  return <SearchPageComponent />;
};
