import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import ClipLoader from 'react-spinners/ClipLoader';

import './index.scss';
import { ISpinnerProps, SpinnerType } from './spinner.types';

export const Spinner = ({
  size = 100,
  loading = true,
  className,
  type,
}: ISpinnerProps) => {
  const findSpinner = () => {
    if (type === SpinnerType.puffLoader) {
      return <PuffLoader size={size} color="#8E8E93" loading={loading} />;
    }

    if (type === SpinnerType.clipLoader) {
      return <ClipLoader size={size} color="#8E8E93" loading={loading} />;
    }

    return <PuffLoader size={size} color="#8E8E93" loading={loading} />;
  };

  return (
    <div className={`spinner-box ${className}`}>
      {findSpinner()}
    </div>
  );
};
