import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAlbumHintRes } from 'api/albums.types';
import { IAlbumHintsState } from './albumHints.types';

const initialState: IAlbumHintsState = {
  loading: false,
  data: [],
};

export const albumHintsSlice = createSlice({
  name: 'albumHints',
  initialState,
  reducers: {
    albumHintsFetchingStart: (state, action: PayloadAction<string | undefined>) => {
      if (state.data.length === 0 && !action.payload) state.loading = true;
    },

    albumHintsFetchingSuccess: (state, { payload }: PayloadAction<IAlbumHintRes[]>) => {
      state.loading = false;
      state.data = payload;
    },

    albumHintsClear: (state) => {
      state.loading = initialState.loading;
      state.data = initialState.data;
    },
  },
});

export const {
  albumHintsFetchingStart,
  albumHintsFetchingSuccess,
  albumHintsClear,
} = albumHintsSlice.actions;
export const albumHintsReducer = albumHintsSlice.reducer;
