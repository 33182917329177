import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/extensions,import/no-unresolved */
import { ElementTypes } from '../../../types/element.types';
import ContextMenuHeader from './ContextMenuHeader';
import { ContextMenuFileItems } from './ContextMenuFileItems';
import { ContextMenuAlbumItems } from './ContextMenuAlbumItems';
/* eslint-enable import/extensions,import/no-unresolved */
// todo rewrite in TypeScript
const ContextMenuBlock = ({
  ctxMenuType,
  elId,
  elIdx,
  elName,
  elCount,
  isMany,
  contextMenuId,
  contextMenuBoxId,
  closeFn,
  ...props
}) => {
  const newProps = {
    elName,
    elId,
    elIdx,
    elCount,
    isMany,
    contextMenuId,
    contextMenuBoxId,
    ...props
  };

  const renderCtxMenuItems = () => {
    if (ctxMenuType === ElementTypes.ALBUM) return <ContextMenuAlbumItems elIdx={elIdx} />;
    if (ctxMenuType === ElementTypes.FILE) return <ContextMenuFileItems {...newProps} />;
    return <ContextMenuFileItems {...newProps} />;
  };

  return (
    <div id={contextMenuId} className="context-menu-block">
      <ContextMenuHeader elName={elName} elCount={elCount} isMany={isMany} closeFn={closeFn} />
      <div id={contextMenuBoxId} className="context-menu__box">
        {renderCtxMenuItems()}
      </div>
    </div>
  );
};

ContextMenuBlock.propTypes = {
  closeFn: PropTypes.func.isRequired,
  elShare: PropTypes.string,
  itemFromSearch: PropTypes.bool,
  isMany: PropTypes.bool,
};

ContextMenuBlock.defaultProps = {
  elShare: 'false',
  itemFromSearch: false,
  isMany: false,
};

export default ContextMenuBlock;
