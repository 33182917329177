import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';

import { selectFileIsSelected } from 'redusers/files/selected';
import { favoriteStatusChangeStart } from 'redusers/files/list';
import { selectFileIsFavorite } from 'redusers/albums';
import formatDate from 'utils/formatDate';
import isMobile from 'utils/isMobile';
import { fileSizeConverter } from 'utils/fileSizeConverter';
import { ElementTypes, FileTypes } from 'types/element.types';

import './mainFilesItem.scss';
import { IMainFilesItemComponentProps, IMainFilesItemProps } from './types';

export const MainFilesItemComponent = ({
  className,
  isList,
  file,
  idx,
  isSelected,
  isFavorite,
  setFavorite,
}: IMainFilesItemComponentProps) => {
  const { t } = useTranslation();
  const elementClasses = classNames(className, {
    'main-files-item_list': isList,
    'main-files-item_tile': !isList,
    'main-files-item--active': isSelected,
    'main-files-item--favorite': isFavorite,
  });

  const handleClickByFavorite = () => setFavorite(idx);

  const renderInfo = () => {
    if (isList) {
      return (
        <div className="main-files-item__info">
          <div className="main-files-item__title">
            <span className="main-files-item__title-text">{file.name}</span>
            { file.share_url ? <span className="main-files-item__title-public d-none d-md-block">{t('global__public')}</span> : null }
          </div>
          <div className="main-files-item__info-meta-date">
            {formatDate(file.metadata.created_at)}
          </div>
          <div className="main-files-item__info-meta-size">
            {fileSizeConverter(file.metadata.size)}
          </div>
          <div className="main-files-item__info-meta-favorite">
            <div className="main-files-item__favorite" onClick={isMobile() ? undefined : handleClickByFavorite} />
          </div>
          {
            file.share_url
              ? <div className="main-files-item__info-meta-public d-block d-md-none">{t('global__public')}</div>
              : null
          }
        </div>
      );
    }

    return (
      <>
        <div className="main-files-item__title">
          {
            file.metadata.type === FileTypes.VIDEO
              ? <Icon type="BxsVideo" className="main-files-item__title-icon" />
              : <Icon type="Image" className="main-files-item__title-icon" />
          }
          <span className="main-files-item__title-text">{file.name}</span>
        </div>
        <div className="main-files-item__favorite" onClick={isMobile() ? undefined : handleClickByFavorite} />
      </>
    );
  };

  return (
    <div className={elementClasses}>
      <div
        // @ts-ignore
        name="MOUSE_SELECTED_ELEMENT"
        className="main-files-item__wrap"
        data-context-active={isMobile() ? 'false' : 'true'}
        data-ctx-menu-type={ElementTypes.FILE}
        data-item-id={file.id}
        data-item-idx={idx}
        data-item-share={file.share_url || false}
        data-item-url={file.data_url || false}
        data-item-favorite={isFavorite}
        data-item-name={file.name}
      >

        <div className="main-files-item__content">
          <div className="main-files-item__icon" style={{ backgroundImage: `url(${file.preview_url})` }} />
          {renderInfo()}
          {
            isMobile()
              ? (
                <div
                  className="main-files-item__mobile-context"
                  data-context-active={isMobile() ? 'true' : 'false'}
                  data-ctx-menu-type={ElementTypes.FILE}
                  data-item-id={file.id}
                  data-item-idx={idx}
                  data-item-share={file.share_url || false}
                  data-item-url={file.data_url || false}
                  data-item-favorite={isFavorite}
                  data-item-name={file.name}
                >
                  <Icon type="DotsVertical" />
                </div>
              )
              : null
          }
        </div>
      </div>
    </div>
  );
};

export const MainFilesItem = ({ idx, file, ...props }: IMainFilesItemProps) => {
  const dispatch = useDispatch();
  const isSelected = useSelector(selectFileIsSelected(idx));
  const isFavorite = useSelector(selectFileIsFavorite(file.albums_uuids));
  const setFavorite = () => {
    dispatch(favoriteStatusChangeStart({ fileId: file.id, isFavorite }));
  };

  const newProps = {
    ...props,
    file,
    isFavorite,
    idx,
    isSelected,
    setFavorite,
  };
  return <MainFilesItemComponent {...newProps} />;
};
