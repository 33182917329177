import { LanguageKeys, Languages, ILanguage, EnLanguage } from 'types/language.types';
import { LANGUAGE_KEY } from '../constants/cookieNames';

export class LanguageProvider {
  setLanguage = (lng: LanguageKeys): void => {
    localStorage.setItem(LANGUAGE_KEY, lng);
  };

  findLanguageObj = (): ILanguage => {
    const lng = localStorage.getItem(LANGUAGE_KEY) || window.navigator.language.slice(0, 2);
    const current = Languages.find((language) => language.key === lng);

    return current || EnLanguage;
  };

  getLanguageName = (): string => {
    const lngObj = this.findLanguageObj();
    return lngObj.name;
  };

  getLanguageKey = (): LanguageKeys => {
    const lngObj = this.findLanguageObj();
    return lngObj.key;
  };

  getTranslateLanguageName = (): string => {
    const lngObj = this.findLanguageObj();
    return lngObj.translate || '';
  };
}

export const languageProvider = new LanguageProvider();
