import qs from 'query-string';
import http, { AxiosResponse } from 'utils/http.service';
import { IAlbumRes, ICreateAlbumData, IUpdateAlbumValues, IAlbumHintRes } from './albums.types';

export const createAlbum = async (data: ICreateAlbumData): Promise<AxiosResponse<IAlbumRes>> => {
  return http<IAlbumRes>('/albums', { method: 'post', data });
};

export const getAlbums = async (): Promise<AxiosResponse<IAlbumRes[]>> => {
  const query: { [key: string]: any } = {
    limit: 100,
    offset: 0,
  };

  const queryString = qs.stringify(query, { arrayFormat: 'bracket' });
  return http<IAlbumRes[]>(`/albums?${queryString}`);
};

export const getAlbumHints = async (searchString?: string): Promise<AxiosResponse<IAlbumHintRes[]>> => {
  const query: { [key: string]: any } = {
    limit: 10,
    query: searchString,
  };
  const queryString = qs.stringify(query, { arrayFormat: 'bracket' });
  return http(`/searchable_album_names?${queryString}`);
};

export const addFileToAlbum = async (albumUuid: string, filesIds: string[]): Promise<AxiosResponse> => {
  return http(`/albums/${albumUuid}/files`, { method: 'post', data: { ids: filesIds } });
};

export const deleteFilesFormAlbum = async (albumUuid: string, filesIds: string[]): Promise<AxiosResponse> => {
  return http(`/albums/${albumUuid}/files`, { method: 'delete', data: { ids: filesIds } });
};

export const deleteAlbum = async (uuid: string): Promise<AxiosResponse> => {
  return http('/albums', { method: 'delete', data: { ids: [uuid] } });
};

export const updateAlbum = async (uuid: string, values: IUpdateAlbumValues): Promise<AxiosResponse<IAlbumRes>> => {
  return http<IAlbumRes>(`/albums/${uuid}`, { method: 'patch', data: values });
};

export default {
  createAlbum,
  getAlbums,
  deleteAlbum,
  updateAlbum,
  addFileToAlbum,
  deleteFilesFormAlbum,
  getAlbumHints,
};
