import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as Google } from 'static/icons/login-google.svg';
import { ReactComponent as Facebook } from 'static/icons/login-facebook.svg';
import { ReactComponent as Apple } from 'static/icons/login-apple.svg';
import { firebaseProvider } from 'providers/firebaseProvider';
import { authFetchingStart } from 'redusers/global/auth';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { AuthSocial } from 'types/auth.types';
import { SocialBtn } from './socialBtn';
import { ISocialLoginComponentProps } from './types';

import './socialLogin.scss';

export const SocialLoginComponent = ({ googleLogin, facebookLogin, appleLogin } : ISocialLoginComponentProps) => {
  return (
    <div className="social-login">
      <SocialBtn onClick={googleLogin}>
        <Google />
      </SocialBtn>
      <SocialBtn onClick={facebookLogin} className="social-login__item--fb">
        <Facebook />
      </SocialBtn>
      <SocialBtn onClick={appleLogin}>
        <Apple />
      </SocialBtn>
    </div>

  );
};

export const SocialLogin = () => {
  const dispatch = useDispatch();

  const googleLogin = async () => {
    const token = await firebaseProvider.googleAuth();
    if (token) {
      dispatch(authFetchingStart({
        token,
        social: AuthSocial.google,
      }));
    }
    sendGtmTrigger('sv2_log_in_google');
  };

  const facebookLogin = async () => {
    const token = await firebaseProvider.facebookAuth();
    if (token) {
      dispatch(authFetchingStart({
        token,
        social: AuthSocial.facebook,
      }));
    }
    sendGtmTrigger('sv2_log_in_fb');
  };

  const appleLogin = async () => {
    const token = await firebaseProvider.appleAuth();
    if (token) {
      dispatch(authFetchingStart({
        token,
        social: AuthSocial.apple,
      }));
    }
    sendGtmTrigger('sv2_log_in_apple');
  };

  return (
    <SocialLoginComponent
      googleLogin={googleLogin}
      appleLogin={appleLogin}
      facebookLogin={facebookLogin}
    />
  );
};
