import { TouchEvent } from 'react';

class LongTouchProvider {
  touchDuration = 1400; // ms

  timer: any;

  handler: any;

  constructor(handler: any) {
    this.handler = handler;
  }

  touchstart = (e: TouchEvent) => {
    // вычисляем composedPath в самом начале, тк потом он не доступен
    const patch = e.nativeEvent.composedPath();
    const eventHandler = () => {
      this.handler(e, patch);
    };

    this.timer = setTimeout(eventHandler, this.touchDuration);
  };

  touchend = () => {
    clearTimeout(this.timer);
  };
}

export default LongTouchProvider;
