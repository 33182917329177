export enum SpinnerType {
  puffLoader = 'PuffLoader',
  clipLoader = 'ClipLoader',
}

export interface ISpinnerProps {
  size?: number,
  loading?: boolean,
  className?: string,
  type?: SpinnerType
}
