import React from 'react';
import { useTranslation } from 'react-i18next';

import { FullWidthLayout } from 'components/layouts/FullWidthLayout';
import { Placeholder } from 'components/common/Placeholder';

export const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <FullWidthLayout hiddenAvatar>
      <div className="container">
        <Placeholder text={t('placeholder__page-not-found')} />
      </div>
    </FullWidthLayout>
  );
};
