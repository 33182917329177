import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';
import { IAlbumRes } from 'api/albums.types';
import { IAlbumsState } from './albums.types';

export const selectAlbumsState = ({ albums }: TRootState): IAlbumsState => albums;

export const selectAlbumsList = createSelector(
  selectAlbumsState,
  (albumsState: IAlbumsState): IAlbumRes[] => albumsState.data,
);

export const selectLastAlbumsList = createSelector(
  selectAlbumsList,
  (albumsList): IAlbumRes[] => albumsList.slice(0, 10),
);

export const selectCurrentAlbum = createSelector(
  selectAlbumsState,
  (albumsState): IAlbumRes | undefined => albumsState.current,
);

export const selectCurrentAlbumId = createSelector(
  selectCurrentAlbum,
  (currentAlbum): string | undefined => currentAlbum?.uuid,
);

export const selectAlbumsLoading = createSelector(
  selectAlbumsState,
  (albumsState: IAlbumsState): boolean => albumsState.loading,
);

export const selectAlbumByIdx = (idx: number | undefined) => createSelector(
  selectAlbumsState,
  (albumsState: IAlbumsState): IAlbumRes | undefined => {
    return idx !== undefined ? albumsState.data[idx] : undefined;
  },
);

export const selectFavoriteAlbumId = createSelector(
  selectAlbumsState,
  (albumsState): string => albumsState.favoriteAlbumId,
);

export const selectFileIsFavorite = (albumsUuids: string[]) => createSelector(
  selectFavoriteAlbumId,
  (favoriteUuid): boolean => albumsUuids.includes(favoriteUuid),
);

export const selectCurrentAlbumIsNotFound = createSelector(
  selectCurrentAlbumId,
  selectAlbumsLoading,
  (currentAlbumId, isLoading): boolean => (!!(!isLoading && !currentAlbumId)),
);
