import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DefLayout } from 'components/layouts/DefLayout';
import MainScreen from 'components/MainScreen';
import { MainHeader } from 'components/MainHeader';
import { NotFoundPage } from 'pages/NotFoundPage';

import { filesFetchingStart } from 'redusers/files/list';
import {
  setCurrentAlbumByUUID,
  removeCurrentAlbum,
  selectCurrentAlbum,
  selectAlbumsLoading,
  selectCurrentAlbumIsNotFound,
} from 'redusers/albums';
import { IAlbumPageComponentProps } from './albumPage.types';

export const AlbumPageComponent = ({ albumTitle, isLoading, isNotFond }: IAlbumPageComponentProps) => {
  if (isNotFond) return <NotFoundPage />;

  return (
    <DefLayout className="unselect-container">
      <MainHeader title={albumTitle} loading={isLoading} />
      <MainScreen />
    </DefLayout>
  );
};

export const AlbumPage = () => {
  const dispatch = useDispatch();
  const currentAlbum = useSelector(selectCurrentAlbum);
  const isLoading = useSelector(selectAlbumsLoading);
  const isNotFond = useSelector(selectCurrentAlbumIsNotFound);
  const { albumUUID } = useParams<string>();

  useEffect(() => {
    if (albumUUID) dispatch(setCurrentAlbumByUUID(albumUUID));
    dispatch(filesFetchingStart());

    return () => {
      dispatch(removeCurrentAlbum());
    };
  }, [dispatch, albumUUID]);

  return <AlbumPageComponent albumTitle={currentAlbum?.name} isLoading={isLoading} isNotFond={isNotFond} />;
};
