import React, { useRef, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import validators from 'validators';
import { albumMap } from 'validators/album';
import { AlbumHints } from './AlbumHints';
import {
  IAlbumFormProps,
  IAlbumFormInitialValues,
} from './albumForm.types';

export const AlbumForm = ({ initialValues, handleSubmit, loading }: IAlbumFormProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const submit = async (values: IAlbumFormInitialValues) => {
    handleSubmit({ name: values.name });
  };

  const AlbumSchema = validators(albumMap);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    inputRef.current?.focus();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AlbumSchema}
      onSubmit={submit}
    >
      {({
        errors,
        touched,
        submitCount,
        values,
        setFieldValue,
      }) => {
        return (
          <Form className="edit-profile-form">
            <div className="form-group">
              <Field
                type="text"
                maxLength={60}
                name="name"
                id="name"
                className={`form-control ${errors.name && touched.name && submitCount ? 'is-invalid' : ''}`}
                autoComplete="off"
                innerRef={inputRef}
              />
              <small className="form-text text-muted">
                {t('album__new-album-input-name-hint')}
              </small>
            </div>
            <AlbumHints searchString={values.name} setFieldValue={setFieldValue} />
            <button
              className="btn sv-btn-blue sv-btn mx-auto"
              type="submit"
              disabled={values.name.length < 1 || loading}
            >
              {t('album__new-album-save')}
            </button>
          </Form>
        );
      }}

    </Formik>
  );
};
