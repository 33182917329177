import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/common/Icon';

import { DownloadManagerItem } from '../DownloadManagerItem';
import { clearDownloadProcess, selectDownloadProcesses } from '../../../redusers/downloadManager';
import './index.scss';
import { IDownloadManagerContainerProps } from './types';

export const DownloadManagerContainerComponent = ({ processes, clearProcess }: IDownloadManagerContainerProps) => {
  const [isHidden, setIsHidden] = useState(false);
  const { t } = useTranslation();

  const handleClear = () => {
    setIsHidden(false);
    clearProcess();
  };

  const toggleHidden = () => setIsHidden(!isHidden);

  if (!processes.length) return null;

  const elementClasses = classNames('download-manager', {
    'download-manager--hidden': isHidden,
  });

  return (
    <div className={elementClasses}>
      <div className="download-manager__header">
        <div className="download-manager__header-title">
          {t('download-manager__title')}
        </div>
        <div className="download-manager__header-controller">
          <Icon
            type="CheveronDown"
            className="download-manager__header-icon download-manager__icon-hidden"
            onClick={toggleHidden}
          />
          {/* @ts-ignore */}
          <Icon type="CloseIcon" className="download-manager__header-icon" onClick={handleClear} />
        </div>
      </div>
      <div className="download-manager__list">
        {processes.map((process) => (
          <DownloadManagerItem key={process.id} process={process} />
        ))}
      </div>
    </div>
  );
};

export const DownloadManagerContainer = () => {
  const dispatch = useDispatch();
  const processes = useSelector(selectDownloadProcesses);
  const clearProcess = () => dispatch(clearDownloadProcess());

  return <DownloadManagerContainerComponent processes={processes} clearProcess={clearProcess} />;
};
