import React, { useState } from 'react';
import { Icon } from 'components/common/Icon';
import { MobileMenuContainer } from './mobileMenuContainer';

import './mobileMenu.scss';

export const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className="mobile-menu">
      <div onClick={toggle}>
        <Icon type="MenuIcon" className="mobile-menu__burger" />
      </div>
      <MobileMenuContainer isOpen={isOpen} toggle={toggle} />
    </div>
  );
};
