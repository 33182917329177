import React, {
  Fragment, useState, ChangeEventHandler, useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';

import { deleteUserAccount } from 'redusers/global/auth';
import { closeCtxModal } from 'redusers/ctxModal';
import sendGtmTrigger from 'utils/sendGtmTrigger';

import './index.scss';
import { ISettingsDeleteAccountComponentProps } from './types';

export const SettingsDeleteAccountComponent = ({ removeHandler }: ISettingsDeleteAccountComponentProps) => {
  const { t } = useTranslation();

  const [confirmationString, setConfirmationString] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleChangeConfirmation: ChangeEventHandler<HTMLInputElement> = (e) => {
    setConfirmationString(e.target.value);
    if (e.target.value.toLowerCase() === 'delete') setIsConfirmed(true);
    else setIsConfirmed(false);
  };

  const handleKeyUp = useCallback((e: any) => {
    if (e.key === 'Enter' && isConfirmed) removeHandler();
  }, [isConfirmed, removeHandler]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [handleKeyUp]);

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon type="DeleteOutline" className="custom-modal__icon" />
        <div className="custom-modal__title">
          {t('settings__delete-account')}
        </div>
      </Modal.Header>
      <Modal.Body className="custom-modal-delete">
        <div>
          {t('settings__delete-account-text')}
        </div>

        <div className="custom-modal-delete__input-label">
          {t('settings__delete-account-input-label')} DELETE
        </div>
        <div className="form-group">
          <input type="text" className="form-control" value={confirmationString} onChange={handleChangeConfirmation} />
        </div>
        <div className="custom-modal__btn-box">
          <button
            onClick={removeHandler}
            className="btn btn-danger sv-btn"
            disabled={!isConfirmed}
          >
            {t('global__remove')}
          </button>
        </div>
      </Modal.Body>
    </Fragment>
  );
};

export const SettingsDeleteAccount = () => {
  const dispatch = useDispatch();

  const removeHandler = () => {
    dispatch(deleteUserAccount());
    dispatch(closeCtxModal());
    sendGtmTrigger('sv2_delete_account');
  };

  return <SettingsDeleteAccountComponent removeHandler={removeHandler} />;
};
