import { Epic, ofType } from 'redux-observable';
import { from, of, throwError } from 'rxjs';
import { exhaustMap, catchError, map } from 'rxjs/operators';
import { TRootState } from 'redusers';
import API from 'api';

import { devicesFetchingSuccess, devicesFetchingFailed, devicesFetchingStart } from './devices.slice';

export const fetchDevices: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getDevices },
) => action$.pipe(
  ofType(devicesFetchingStart),
  exhaustMap(() => {
    const { success, data: oldData } = state$.value.global.devices;

    if (success) return of(devicesFetchingSuccess(oldData.slice()));

    return from(getDevices()).pipe(
      map(({ status, data }) => {
        if (status !== 200) {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw throwError();
        }
        return devicesFetchingSuccess(data);
      }),
      catchError(() => of(devicesFetchingFailed())),
    );
  }),
);

export const devicesEpics = [fetchDevices];
