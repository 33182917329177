import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Icon } from 'components/common/Icon';
import { useTranslation } from 'react-i18next';

import { IDownloadManagerItem } from './types';

import './index.scss';

export const DownloadManagerItem = ({ process }: IDownloadManagerItem) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (process.status === 'success') setProgress(100);
    else if (process.loaded) setProgress(Math.round((process.loaded / process.estimated_size) * 100));

    if (process.status === 'success' && linkRef.current) {
      linkRef.current.click();
    }
  }, [process]);

  const renderStatus = () => {
    if (process.status === 'success') {
      return (
        <div className="download-manager-item__status-success">
          <Icon type="CheckBold" className="download-manager-item__status-icon" />
        </div>
      );
    }

    if (process.status === 'error') {
      return (
        <div className="download-manager-item__status-error">
          <Icon type="CloseThick" className="download-manager-item__status-icon" />
        </div>
      );
    }

    return <div className="spinner-border custom-spinner" />;
  };

  const renderInfo = () => {
    if (process.status === 'error') {
      return t('download-manager__error');
    }

    if (process.status === 'success') {
      return (
        <Fragment>
          {t('download-manager__success')}
          <a
            href={process.data_url}
            className="download-manager-item__info-percent"
            ref={linkRef}
            download
          >
            {t('download-manager__download-btn')}
          </a>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {t('download-manager__pending')}
        <span className="download-manager-item__info-percent">{progress || 0}%</span>
      </Fragment>
    );
  };

  return (
    <div className="download-manager-item">
      <div className="download-manager-item__info">
        {renderInfo()}
      </div>
      <div className="download-manager-item__status">
        {renderStatus()}
      </div>
    </div>
  );
};
