const createThunkMiddleware = () => {
  return ({ dispatch, getState }) => (next) => async (action) => {
    if (typeof action === 'function') {
      try {
        return await action(dispatch, getState);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') console.log('[thunk]');
        if (process.env.NODE_ENV !== 'production') console.log(error.message);
        if (process.env.NODE_ENV !== 'production') console.log(error);

        /*
         * Тут можно обарабатывать все ошибки поизошедшие в екшенах
         * например показывыть тосты
         */
        return undefined;
      }
    }

    return next(action);
  };
};

export default createThunkMiddleware();
