import React from 'react';
import { Logo } from 'components/common/Logo';
import { TopHeader } from 'components/TopHeader';
import { ContextModal } from 'components/common/ContextModal';
import { DownloadManagerContainer } from 'components/downloadManager/DownloadManagerContainer';
import { SelectionBar } from 'components/SelectionBar';

import { IFullWidthLayoutProps } from './fullWidthLayout.types';

export const FullWidthLayout = ({
  children,
  className,
  globalClassName,
  logoWithoutLink,
  hiddenAvatar = false,
  hiddenMobileMenu = false,
}: IFullWidthLayoutProps) => {
  return (
    <div className={globalClassName}>
      <div className="def-layout def-layout-top">
        <div className="def-layout__sidebar def-layout__logo-sidebar">
          <Logo logoWithoutLink={logoWithoutLink} />
        </div>
        <div className="def-layout__content">
          <TopHeader hiddenSearch hiddenAvatar={hiddenAvatar} hiddenMobileMenu={hiddenMobileMenu} />
        </div>
      </div>
      <div className={`def-layout ${className || ''}`}>
        <div className="def-layout__full-content">
          {children}
        </div>
      </div>
      <ContextModal />
      <DownloadManagerContainer />
      <SelectionBar />
    </div>
  );
};
