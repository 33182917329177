import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';
import moment from 'moment';
import { IDeviceRes, IDeviceCatalogRes } from 'api/devices.types';
import { selectDevicesCatalog } from 'redusers/global/devicesCatalog';
import { IDeviceState, IDeviceWithInfoFromCatalog } from './types';

export const selectDevicesState = (globalState: TRootState) => globalState.global.devices;

export const selectDevices = createSelector(
  selectDevicesState,
  (state: IDeviceState): IDeviceRes[] => state.data,
);

export const selectDevicesWithCatalogInfo = createSelector(
  selectDevices,
  selectDevicesCatalog,
  (devices: IDeviceRes[], devicesCatalog: IDeviceCatalogRes[]): IDeviceWithInfoFromCatalog[] => {
    return devices.map((device) => {
      const curCatalogDevice = devicesCatalog.find(
        (catalogDevice) => device.sku === catalogDevice.sku
      ) as IDeviceCatalogRes;

      return { ...device, ...curCatalogDevice };
    });
  },
);

export const selectDevicesLoading = createSelector(
  selectDevicesState,
  (state: IDeviceState): boolean => state.loading,
);

export const selectDevicesSuccess = createSelector(
  selectDevicesState,
  (state: IDeviceState): boolean => state.success,
);

export const selectLastConnectedDevice = createSelector(
  selectDevicesWithCatalogInfo,
  (devices): IDeviceWithInfoFromCatalog | undefined => {
    const lastConnectedDevice = devices.reduce<IDeviceWithInfoFromCatalog[]>((accum, item): IDeviceWithInfoFromCatalog[] => {
      if (!accum[0] || moment(accum[0].last_connection_date).isBefore(item.last_connection_date)) {
        return [item];
      }
      return accum;
    }, []);

    return lastConnectedDevice[0];
  },
);
