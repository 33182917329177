import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
/* eslint-disable import/extensions,import/no-unresolved */
import {
  fetchingDevicesCatalog, devicesCatalogClear,
  selectDevicesCatalog,
  selectDevicesCatalogLoading,
} from '../../../../redusers/global/devicesCatalog';
import { devicesFetchingStart, selectDevices, selectDevicesLoading } from '../../../../redusers/global/devices';
/* eslint-enable import/extensions,import/no-unresolved */
import SelectWithSearch from '../SelectWithSearch';
import debounce from '../../../../utils/debounce';

import './index.scss';

// todo rewrite in TypeScript
const SelectDevice = (props) => {
  const { form: { setFieldValue, values } } = props;
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const deviceCatalog = useSelector(selectDevicesCatalog);
  const devices = useSelector(selectDevices);
  const loading = useSelector(selectDevicesCatalogLoading);
  const loadingDevices = useSelector(selectDevicesLoading);
  const getData = debounce((q) => setQuery(q), 100);

  const onClose = () => setQuery('');
  const afterChangeFieldCb = (currentItem) => {
    setFieldValue('sku', currentItem.sku || '');
    setFieldValue('serial_number', currentItem.serial_number || '');
    setFieldValue('firmware_version', currentItem.software_version || '');
    setFieldValue('hardware_version', currentItem.hardware_version || '');
  };

  useEffect(() => {
    dispatch(fetchingDevicesCatalog());
    dispatch(devicesFetchingStart());

    return () => dispatch(devicesCatalogClear());
  }, [dispatch]);

  const renderItem = useCallback((item) => {
    return (
      <div className="select-device__box">
        <div className="select-device__container">
          <div>{item.name}</div>
          <div className="select-device__item-info">
            SKU: {item.sku}{item.serial_number && ` • s/n: ${item.serial_number}`}
          </div>
        </div>
        {
          item.sku === values.sku
          && (item.serial_number === values.serial_number || (!item.serial_number && !values.serial_number))
            ? <div className="select-device__active-status" />
            : null
        }
      </div>
    );
  }, [values]);

  const fullDevices = deviceCatalog.length === 0 ? [] : [
    ...devices.map((device) => {
      const current = deviceCatalog.find(((item) => item.sku === device.sku));
      return {
        ...current,
        serial_number: device.serial_number,
        software_version: device.software_version,
        hardware_version: device.hardware_version,
      };
    }),
    ...deviceCatalog
  ];

  const data = fullDevices
    .filter((item) => item.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
    .map((item) => ({
      name: item.name,
      value: item.sku + item.serial_number || '',
      sku: item.sku,
      serial_number: item.serial_number,
      software_version: item.software_version,
      hardware_version: item.hardware_version,
    }));

  const newProps = {
    ...props,
    getData,
    onClose,
    afterChangeFieldCb,
    renderItem,
    data,
    loading: loading || loadingDevices,
  };

  return <SelectWithSearch {...newProps} />;
};

export default SelectDevice;
