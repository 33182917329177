import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';

import { TRootState } from 'redusers';
import API from 'api';
import { filesFetchingStart } from 'redusers/files/list';
import { filtersUpdate, filtersToggle } from './filters.slice';

export const filterUpdateEpic: Epic<any, any, TRootState, typeof API> = (action$) => action$.pipe(
  ofType(filtersUpdate),
  distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
  switchMap(() => of(
    filtersToggle(false),
    filesFetchingStart(),
  )),
);

export const filtersEpics = [filterUpdateEpic];
