import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, MenuItemIcon } from 'components/common/Menu';
import { NewAlbumModal, INewAlbumRef } from 'components/common/modals/NewAlbumModal';

export const NewAlbumBtn = () => {
  const { t } = useTranslation();
  const newAlbumRef = useRef<INewAlbumRef>();
  const openModal = () => newAlbumRef.current && newAlbumRef.current.openModal();

  return (
    <>
      <MenuItem handleClick={openModal} className="sidebar__new-album-btn">
        <MenuItemIcon type="PlusIcon" />
        {t('album__new-album-btn')}
      </MenuItem>
      <NewAlbumModal ref={newAlbumRef} />
    </>
  );
};
