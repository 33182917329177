import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from 'static/placeholder-logo.svg';

import './placeholder.scss';
import { IPlaceholderProps } from './placeholder.types';

export const Placeholder = ({ text, classNames }: IPlaceholderProps) => {
  const { t } = useTranslation();

  return (
    <div className={`placeholder ${classNames}`}>
      <div className="placeholder__logo-box">
        <Logo className="placeholder__logo" />
      </div>
      <div className="placeholder__text">
        {text || t('placeholder__text')}
      </div>
    </div>
  );
};
