import React from 'react';
import { createRoot } from 'react-dom/client';

import 'i18nextInit'; // импортится перед импортом App, чтобы локализовать валидаторы

import App from 'App';

import 'bootstrap/scss/bootstrap.scss';
import 'styles/global/index.scss';

const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(<App />);
