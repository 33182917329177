import React from 'react';

import { DropDownModalItem } from 'components/common/DropDownModal';
import { IFilterDeviceItemProps } from './types';

export const FilterDeviceItem = ({ device, setDeviseSerials, active = false }: IFilterDeviceItemProps) => {
  const handleClick = () => setDeviseSerials(device.serial_number);

  return (
    <DropDownModalItem
      className={`filter-picker__item ${active ? 'filter-picker__item--active' : ''}`}
      handleClick={handleClick}
    >
      <div className="filter-picker__item-wrap">
        <div className="filter-picker__icon" />
        <div className="filter-picker__item-info">
          <span className="filter-picker__item-name">{device.name || 'Device'}</span>
          <span className="filter-picker__item-sn">s/n: {device.serial_number}</span>
        </div>
      </div>
    </DropDownModalItem>
  );
};
