import { IPagination } from 'types/pagination.types';

export const getPagination = (headers: { [key: string]: string }): IPagination => {
  return {
    currentPage: parseInt(headers['x-pagination-current-page']),
    pageCount: parseInt(headers['x-pagination-page-count']),
    perPage: parseInt(headers['x-pagination-per-page']),
    totalCount: parseInt(headers['x-pagination-total-count']),
  };
};
