import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  MouseEvent,
} from 'react';
import { IPhotoViewer } from './types';
import './photoViewer.scss';

export const PhotoViewer = ({ file }: IPhotoViewer) => {
  const photoBoxRef = useRef<HTMLDivElement>(null);

  const [zoom, setZoom] = useState(1);
  const [mouseX, setMouseX] = useState(50);
  const [mouseY, setMouseY] = useState(50);

  const incrementZoom = () => {
    if (zoom >= 4) return null;
    return setZoom(zoom + 0.5);
  };

  const decrementZoom = () => {
    if (zoom <= 1) return null;
    return setZoom(zoom - 0.5);
  };

  const handleMouseMovement = (e: MouseEvent) => {
    if (zoom <= 1) {
      setMouseX(50);
      return setMouseY(50);
    }
    if (photoBoxRef.current) {
      const {
        left: offsetLeft,
        top: offsetTop,
        width,
        height,
      } = photoBoxRef.current.getBoundingClientRect();
      const x = ((e.clientX - offsetLeft) / parseInt(String(width), 10)) * 100;
      const y = ((e.clientY - offsetTop) / parseInt(String(height), 10)) * 100;
      setMouseX(Math.round(x));
      return setMouseY(Math.round(y));
    }
    return null;
  };

  useEffect(() => {
    if (photoBoxRef.current) {
      photoBoxRef.current.innerHTML = '';
      const photo = document.createElement('img');
      photo.id = file.id;
      photo.src = file.data_url;
      photo.alt = file.name;
      photoBoxRef.current.appendChild(photo);
      setZoom(1);
    }
  }, [file.id, file.data_url, file.name]);

  useEffect(() => {
    const img = document.getElementById(file.id);
    if (img) {
      img.setAttribute(
        'style',
        `transform-origin: ${mouseX}% ${mouseY}%; transform: scale(${zoom})`,
      );
    }
  }, [zoom, mouseX, mouseY, file.id]);

  return (
    <Fragment>
      <div className="photo-viewer">
        <div className="photo-viewer__box" ref={photoBoxRef} onMouseMove={handleMouseMovement} />
      </div>
      <div className="photo-viewer__zoom-box">
        <div onClick={decrementZoom} className="photo-viewer__zoom-minus" />
        <div className="photo-viewer__zoom-icon" />
        <div onClick={incrementZoom} className="photo-viewer__zoom-plus" />
      </div>
    </Fragment>
  );
};
