import { PayloadAction } from '@reduxjs/toolkit';
import { Epic, ofType } from 'redux-observable';
import { goBack } from 'redux-first-history';
import { from, of, concat } from 'rxjs';
import i18next from 'i18next';
import { exhaustMap, switchMap, concatMap, catchError, map } from 'rxjs/operators';
import { TRootState } from 'redusers';
import API from 'api';
import { getPagination } from 'utils/getPagination';
import toast from 'utils/toast';
import { closeCtxModal, updateCtxModalLoading, updateCtxModalData } from 'redusers/ctxModal';
import { fileModalOpenFromSearchUpdate, fileModalIndexDecrease } from 'redusers/fileModal';
import { selectFileClear } from 'redusers/files/selected';
import { IUpdateFileValues } from 'api/files.types';
import {
  filesLoadModeStart,
  filesFetchingStart,
  filesLoadModeFailed,
  filesFetchingFailed,
  filesLoadModeSuccess,
  filesFetchingSuccess,
  fileUpdateStart,
  fileUpdateSuccess,
  fileDeleteStart,
  fileDeleteSuccess,
  fileShareStart,
  favoriteStatusChangeStart,
  filesAddToAlbumOrRemoveFromAlbum,
  favoriteStatusChangeFailed,
  addFileToAlbumStart,
  addFileToAlbumSuccess,
  addFileToAlbumFailed,
  deleteFilesFromAlbumStart,
  deleteFilesFromAlbumSuccess, favoriteStatusChangeSuccess,
} from './fileList.slice';
import { fileFromSearchAddToAlbumOrRemoveFromAlbum } from '../../fileModal';

import { searchDeleteItemByIds } from '../../search';

export const getFilesEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getFiles },
) => action$.pipe(
  // @ts-ignore
  ofType(filesFetchingStart, filesLoadModeStart),
  map(({ type }) => {
    const {
      sort,
      filters,
      files,
      search,
      albums: { favoriteAlbumId, currentId },
    } = state$.value;

    return {
      apiParams: {
        sortFieldName: sort.fieldName,
        albumId: currentId,
        sortAsc: sort.asc,
        fileType: filters.type,
        filterDeviseSerials: filters.deviseSerials,
        searchStr: search.detectedString,
        currentPage: type === filesFetchingStart.type ? 0 : files.list.pagination.currentPage,
      },
      favoriteAlbumId,
      successAction: type === filesFetchingStart.type ? filesFetchingSuccess : filesLoadModeSuccess,
      failedAction: type === filesFetchingStart.type ? filesFetchingFailed : filesLoadModeFailed,
    };
  }),
  switchMap(({ apiParams, successAction, failedAction }) => {
    return from(getFiles(apiParams)).pipe(
      map(({ data, status, headers }) => {
        if (status !== 200) return failedAction();

        return successAction({ data, pagination: getPagination(headers) });
      }),
      catchError(() => of(failedAction())),
    );
  }),
);

export const updateFileEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { updateFile },
) => action$.pipe(
  ofType(fileUpdateStart),
  exhaustMap<PayloadAction<{ id: string, values: IUpdateFileValues }>, any>(({ payload }) => {
    const apiRequest$ = from(updateFile(payload.id, payload.values)).pipe(
      map(({ data, status }) => {
        const { ctxModal: { fromSearch } } = state$.value;
        if (status === 200) {
          const actions = [fileUpdateSuccess(data), closeCtxModal()];
          if (fromSearch) actions.push(fileModalOpenFromSearchUpdate(data));
          return actions;
        }
        return updateCtxModalLoading(false);
      }),
    );

    return concat(of(updateCtxModalLoading(true)), apiRequest$);
  }),
  concatMap((action) => ((Array.isArray(action)) ? from(action) : of(action))),
);

// если передан id удаляем его, если не передан достаем ид из выделенных файлов
export const deleteFilesEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { deleteFile },
) => action$.pipe(
  ofType(fileDeleteStart),
  exhaustMap(({ payload }) => {
    let idArray: string[] = [];
    if (payload) {
      idArray = [payload];
    } else {
      const selectedIndexes = state$.value.files.selected.indexes;
      const files = state$.value.files.list.data;

      idArray = selectedIndexes.map((idx) => files[idx].id);
    }

    const apiRequest$ = from(deleteFile(idArray)).pipe(
      map(({ status }) => {
        const { ctxModal: { fromSearch }, fileModal, files: { list: { data: fileData } } } = state$.value;
        if (status === 204) {
          const actions: { type: string, payload?: any }[] = [
            selectFileClear(),
            fileDeleteSuccess(idArray),
          ];

          if (fileModal.isOpen
            && !fileModal.fromSearch
            && fileModal.fileIdx !== null
            && fileModal.fileIdx >= fileData.length - 1
          ) {
            actions.push(fileModalIndexDecrease());
          }

          if (fromSearch) {
            actions.push(goBack());
            actions.push(searchDeleteItemByIds(idArray));
          }

          actions.push(closeCtxModal());
          return actions;
        }

        return updateCtxModalLoading(false);
      }),
    );
    return concat(of(updateCtxModalLoading(true)), apiRequest$);
  }),
  concatMap((action) => ((Array.isArray(action)) ? from(action) : of(action))),
);

export const shareFilesEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { shareFile },
) => action$.pipe(
  ofType(fileShareStart),
  exhaustMap(({ payload }) => {
    const apiRequest$ = from(shareFile(payload.data.id, payload.isOpen)).pipe(
      map(({ data, status }) => {
        const { ctxModal: { fromSearch } } = state$.value;
        if (status === 200 || status === 204) {
          let newFile = payload.data;
          if (payload.isOpen) newFile = { ...newFile, share_url: data.share_url };
          else {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { share_url, ...otherProps } = newFile;
            newFile = otherProps;
          }

          const actions = [updateCtxModalData(newFile), fileUpdateSuccess(newFile), updateCtxModalLoading(false)];

          if (fromSearch) actions.push(fileModalOpenFromSearchUpdate(newFile));

          return actions;
        }

        return updateCtxModalLoading(false);
      }),
    );
    return concat(of(updateCtxModalLoading(true)), apiRequest$);
  }),
  concatMap((action) => ((Array.isArray(action)) ? from(action) : of(action))),
);

export const favoriteStatusChangeEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { addFileToAlbum, deleteFilesFormAlbum },
) => action$.pipe(
  ofType(favoriteStatusChangeStart),
  concatMap(({ payload }): any => {
    let fileIds: string[] = [];
    let allInFavorite = true;

    const {
      albums: { favoriteAlbumId },
      files: { list: { data }, selected: { indexes } },
    } = state$.value;

    if (payload.fileId) {
      fileIds = [payload.fileId];
      allInFavorite = payload.isFavorite;
    } else {
      const result = data.reduce<{ fileIds: string[], allInFavorite: boolean }>(
        (accum, item, idx) => {
          if (indexes.includes(idx)) {
            return {
              fileIds: accum.fileIds.concat(item.id),
              allInFavorite: !accum.allInFavorite ? accum.allInFavorite : item.albums_uuids.includes(favoriteAlbumId),
            };
          }
          return accum;
        }, { fileIds: [], allInFavorite: true },
      );
      fileIds = result.fileIds;
      allInFavorite = result.allInFavorite;
    }

    const apiReq$ = from(
      allInFavorite
        ? deleteFilesFormAlbum(favoriteAlbumId, fileIds)
        : addFileToAlbum(favoriteAlbumId, fileIds),
    ).pipe(
      map(() => favoriteStatusChangeSuccess()),
      catchError(() => of(favoriteStatusChangeFailed())),
    );

    return payload.fromSearch
      ? concat(
        of(filesAddToAlbumOrRemoveFromAlbum({ albumUUID: favoriteAlbumId, fileIds, isAddToAlbum: !allInFavorite })),
        of(fileFromSearchAddToAlbumOrRemoveFromAlbum({ albumUUID: favoriteAlbumId })),
        apiReq$,
      )
      : concat(
        of(filesAddToAlbumOrRemoveFromAlbum({ albumUUID: favoriteAlbumId, fileIds, isAddToAlbum: !allInFavorite })),
        apiReq$,
      );
  }),
);

export const addFileToAlbumEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { addFileToAlbum },
) => action$.pipe(
  ofType(addFileToAlbumStart),
  concatMap(({ payload }): any => {
    let fileIds = [];

    if (payload.fileIds) {
      fileIds = payload.fileIds;
    } else {
      const { files: { list: { data }, selected: { indexes } } } = state$.value;

      fileIds = data.reduce<string[]>((accum, item, idx) => {
        if (indexes.includes(idx)) return accum.concat(item.id);
        return accum;
      }, []);
    }

    return from(addFileToAlbum(payload.albumUuid, fileIds)).pipe(
      map(({ status }) => {
        if (status === 204) {
          toast(i18next.t('album__add-file-to-album-notification-success'), { type: 'info' });
          return addFileToAlbumSuccess();
        }

        toast(i18next.t('album__add-file-to-album-notification-failed'), { type: 'warning' });
        return addFileToAlbumFailed();
      }),
      catchError(() => {
        toast(i18next.t('album__add-file-to-album-notification-failed'), { type: 'error' });
        return of(addFileToAlbumFailed());
      }),
    );
  }),
);

export const deleteFilesFromAlbumEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { deleteFilesFormAlbum },
) => action$.pipe(
  ofType(deleteFilesFromAlbumStart),
  concatMap(({ payload }): any => {
    const {
      files: {
        list: { data: fileData },
        selected: { indexes: selectedIndexes },
      },
    } = state$.value;

    const fileIds: string[] = payload.fileIds && payload.fileIds.length
      ? payload.fileIds
      : selectedIndexes.map((currIndex) => (fileData[currIndex].id));

    const apiRequest$ = from(deleteFilesFormAlbum(payload.albumUuid, fileIds)).pipe(
      map(({ status }) => {
        if (status === 204) {
          return [selectFileClear(), closeCtxModal(), deleteFilesFromAlbumSuccess({ fileIds })];
        }

        return updateCtxModalLoading(false);
      }),
      catchError(() => {
        return of(updateCtxModalLoading(false));
      }),
    );

    return concat(of(updateCtxModalLoading(true)), apiRequest$);
  }),
  concatMap((action) => ((Array.isArray(action)) ? from(action) : of(action))),
);

export const fileListEpics = [
  getFilesEpic,
  deleteFilesEpic,
  updateFileEpic,
  shareFilesEpic,
  favoriteStatusChangeEpic,
  addFileToAlbumEpic,
  deleteFilesFromAlbumEpic,
];
