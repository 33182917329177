import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  albumHintsFetchingStart,
  albumHintsClear,
  selectAlbumHintsList,
  selectAlbumHintsLoadingStatus,
} from 'redusers/albumHints';
import { SkeletonLine } from 'components/common/SkeletonLine';
import { AlbumHintsItem } from './AlbumHintsItem';
import { IAlbumHintsComponentProps, IAlbumHintsProps } from './types';
import './albumHints.scss';

export const AlbumHintsComponent = ({ hints, loading, setAlbumNameValue }: IAlbumHintsComponentProps) => {
  const renderContent = () => {
    if (loading) {
      return (
        <>
          <SkeletonLine className="album-hints__item-skeleton" style={{ width: 'calc(30% - 8px)' }} />
          <SkeletonLine className="album-hints__item-skeleton" style={{ width: 'calc(40% - 8px)' }} />
          <SkeletonLine className="album-hints__item-skeleton" style={{ width: 'calc(30% - 8px)' }} />
          <SkeletonLine className="album-hints__item-skeleton" style={{ width: 'calc(25% - 8px)' }} />
          <SkeletonLine className="album-hints__item-skeleton" style={{ width: 'calc(40% - 8px)' }} />
          <SkeletonLine className="album-hints__item-skeleton" style={{ width: 'calc(35% - 8px)' }} />
          <SkeletonLine className="album-hints__item-skeleton" style={{ width: 'calc(45% - 8px)' }} />
        </>
      );
    }
    return hints.map((hint) => (
      <AlbumHintsItem key={hint.id} name={hint.name} setAlbumNameValue={setAlbumNameValue} />
    ));
  };

  return (
    <div className="album-hints__wrap">
      <div className="album-hints__box">
        {renderContent()}
      </div>
    </div>
  );
};

export const AlbumHints = ({ searchString, setFieldValue }: IAlbumHintsProps) => {
  const dispatch = useDispatch();

  const hints = useSelector(selectAlbumHintsList);
  const loading = useSelector(selectAlbumHintsLoadingStatus);
  const setAlbumNameValue = (value: string) => setFieldValue('name', value);

  useEffect(() => {
    dispatch(albumHintsFetchingStart(searchString));
  }, [dispatch, searchString]);

  useEffect(() => {
    return () => {
      dispatch(albumHintsClear());
    };
  }, [dispatch]);

  return (
    <AlbumHintsComponent
      hints={hints}
      loading={loading}
      setAlbumNameValue={setAlbumNameValue}
    />
  );
};
