export enum AuthMethods {
  firebase = 'firebase',
  mobile_app = 'mobile_app',
}

export enum AuthSocial {
  phone = 'phone',
  facebook = 'facebook',
  google = 'google',
  apple = 'apple',
}