import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, MenuGroup, MenuItemIcon } from '../common/Menu';
import { NewAlbumBtn } from './NewAlbumBtn';
import { SidebarAlbumList } from '../SidebarAlbumList';

import './sidebar.scss';

export const Sidebar = ({ handleClickByItem } : { handleClickByItem?: () => void }) => {
  const { t } = useTranslation();
  return (
    <>
      <MenuItem to="/" handleClick={handleClickByItem}>
        <MenuItemIcon type="Images" />
        {t('sidebar__all-files')}
      </MenuItem>

      <MenuGroup>
        <MenuItem to="/albums" handleClick={handleClickByItem}>
          <MenuItemIcon type="Images" />
          {t('sidebar__all-albums')}
        </MenuItem>
        <SidebarAlbumList handleClickByItem={handleClickByItem} />
        <NewAlbumBtn />
      </MenuGroup>
    </>
  );
};
