import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';
import { ElementTypes } from 'types/element.types';

import {
  ICtxAlbumModalOpenPayload,
  ICtxAlbumModalOpenReturned,
  ICtxSystemModalOpenReturned,
  ICtxModalOpenPayload,
  ICtxSystemModalOpenPayload,
  ICtxModalOpenReturned,
} from './types';

export const openCtxModal = createAsyncThunk<
ICtxModalOpenReturned | ICtxAlbumModalOpenReturned | ICtxSystemModalOpenReturned,
ICtxModalOpenPayload | ICtxAlbumModalOpenPayload | ICtxSystemModalOpenPayload
>(
  'ctxModal/openCtxModal',
  async (values, { getState }) => {
    const { files, fileModal } = getState() as TRootState;

    if (values.elementType === ElementTypes.FILE) {
      let data;

      if (values.fromSearch) data = fileModal.dataFromSearch;
      else data = values.elementIdx !== undefined ? files.list.data[values.elementIdx] : undefined;

      return {
        elementType: values.elementType,
        modalType: values.modalType,
        elementIdx: values.elementIdx,
        fromSearch: !!values.fromSearch,
        isMany: !!values.isMany,
        fileIndexes: values.isMany ? files.selected.indexes : [],
        data,
      };
    }

    if (values.elementType === ElementTypes.ALBUM) {
      return {
        elementType: values.elementType,
        modalType: values.modalType,
        elementIdx: values.elementIdx,
      };
    }

    return {
      elementType: values.elementType,
      modalType: values.modalType,
    };
  },
);
