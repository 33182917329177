import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { IDeviceRes } from 'api/devices.types';
import { IDeviceState } from './types';

const initialState: IDeviceState = {
  loading: false,
  success: false,
  data: [],
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    devicesFetchingStart(state) {
      state.loading = true;
    },
    devicesFetchingFailed(state) {
      state.loading = false;
      state.success = false;
      state.data = [];
    },
    devicesFetchingSuccess(state, action: PayloadAction<IDeviceRes[]>) {
      state.loading = false;
      state.success = true;
      state.data = action.payload.sort((a, b) => {
        return moment(a.last_connection_date).isBefore(b.last_connection_date) ? 1 : -1;
      });
    },
  },
});

export const { devicesFetchingStart, devicesFetchingFailed, devicesFetchingSuccess } = devicesSlice.actions;
export const devicesReducer = devicesSlice.reducer;
