import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import sendGtmTrigger from 'utils/sendGtmTrigger';
import { DropDownModal, DropDownModalItem } from 'components/common/DropDownModal';
import { sortUpdate, selectSortFieldName, selectSortAsc, SortFields } from 'redusers/sort';
import { TUpdateSortHandlerCreator, ISortPickerComponentProps, ISortPickerProps } from './types';

import './sortPicker.scss';

export const SortPickerComponent = ({ handlerClick, fieldName, asc, className }: ISortPickerComponentProps) => {
  const { t } = useTranslation();

  const renderBtn = useMemo(() => {
    if (SortFields.name === fieldName && asc) return `${t('sort__name')} ${t('sort__name-asc')}`;
    if (SortFields.name === fieldName && !asc) return `${t('sort__name')} ${t('sort__name-desc')}`;
    if (SortFields.date === fieldName && asc) return `${t('sort__date')} (${t('sort__date-desc')})`;
    if (SortFields.date === fieldName && !asc) return `${t('sort__date')} (${t('sort__date-asc')})`;
    return 'Sort';
  }, [asc, fieldName, t]);

  return (
    <DropDownModal
      renderButton={renderBtn}
      className={`sort-picker ${className}`}
      btnWrapClassName="sort-picker__btn"
      mobileHeaderTitle={t('sort__header-title')}
      closeAfterClickByItem
    >
      <DropDownModalItem handleClick={handlerClick(SortFields.name, true)}>
        <div className="sort-picker__item">
          <div>{t('sort__name')}</div>
          <div>{t('sort__name-asc')}</div>
        </div>
      </DropDownModalItem>
      <DropDownModalItem handleClick={handlerClick(SortFields.name, false)}>
        <div className="sort-picker__item">
          <div>{t('sort__name')}</div>
          <div>{t('sort__name-desc')}</div>
        </div>
      </DropDownModalItem>
      <DropDownModalItem handleClick={handlerClick(SortFields.date, false)}>
        <div className="sort-picker__item">
          <div>{t('sort__date')}</div>
          <div>{t('sort__date-asc')}</div>
        </div>
      </DropDownModalItem>
      <DropDownModalItem onClick={handlerClick(SortFields.date, true)}>
        <div className="sort-picker__item">
          <div>{t('sort__date')}</div>
          <div>{t('sort__date-desc')}</div>
        </div>
      </DropDownModalItem>
    </DropDownModal>
  );
};

export const SortPicker = ({ className = '' }: ISortPickerProps) => {
  const fieldName = useSelector(selectSortFieldName);
  const asc = useSelector(selectSortAsc);
  const dispatch = useDispatch();
  const handlerClick: TUpdateSortHandlerCreator = (name, order) => () => {
    dispatch(sortUpdate({ fieldName: name, asc: order }));
    sendGtmTrigger('sv2_sort_use');
  };

  const props = {
    className,
    fieldName,
    asc,
    handlerClick,
  };

  return <SortPickerComponent {...props} />;
};
