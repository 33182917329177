import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { selectSortAccountСredentials } from 'redusers/global/auth';
import { ConnectedAccountItem } from './connectedAccountItem';
import { IConnectedAccountsComponent, IConnectedAccountsComponentProps } from './connectedAccounts.types';

import './connectedAccounts.scss';

export const ConnectedAccountsComponent = ({ credentials, className }: IConnectedAccountsComponentProps) => {
  const { t } = useTranslation();
  const elementClasses = classNames('connected-accounts', className);

  return (
    <div className={elementClasses}>
      <p className="connected-accounts__title">{t('connected-account__title')}</p>
      {
        credentials.map((credential) => (
          <ConnectedAccountItem key={credential.name} credential={credential} />
        ))
      }
      <span className="connected-accounts__description">
        {t('connected-account__description')}
      </span>
    </div>
  );
};

export const ConnectedAccounts = ({ className }: IConnectedAccountsComponent) => {
  const credentials = useSelector(selectSortAccountСredentials);

  return <ConnectedAccountsComponent credentials={credentials} className={className} />;
};
