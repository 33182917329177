import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common/Icon';

import { ReactComponent as BinocularIcon } from 'static/icons/binocular.svg';
import { IDeviceModalProps } from './deviceModal.types';

import './deviceModal.scss';

export const DeviceModal = ({ isOpen, closeModalHandler, device, hasNewFirmware }: IDeviceModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal show={isOpen} onHide={closeModalHandler} className="custom-modal custom-modal--big" centered>
      <Modal.Header closeButton>
        <BinocularIcon className="custom-modal__icon" />
        <div className="custom-modal__title">
          {t('devices__about')}
        </div>
      </Modal.Header>
      <Modal.Body>
        {
          hasNewFirmware
          && (
            <div className="device-modal__new_update" data-testid="deviceModalNewUpdate">
              <span>{t('devices__new_update')}</span>
            </div>
          )
        }
        <div className="device-modal__header">
          <div className="device-modal__header-img">
            <img src={device.image_url} alt={device.name} />
          </div>
          <div className="device-modal__header-info">
            <h3>{device.name}</h3>
            <div className="device-modal__primary-info">
              <span>SKU: {device.sku}</span>
              <span>{t('devices__serial_number')}: {device.serial_number}</span>
            </div>

            {
              device.brand_name
              && (
                <div className="device-modal__list">
                  <span>{t('devices__brand')}</span>
                  <span>{device.brand_name}</span>
                </div>
              )
            }
            {
              device.software_version
              && (
                <div className="device-modal__list">
                  <span>{t('devices__software_version')}</span>
                  <span>{device.software_version}</span>
                </div>
              )
            }
            {
              device.hardware_version
              && (
                <div className="device-modal__list">
                  <span>{t('devices__hardware_version')}</span>
                  <span>{device.hardware_version}</span>
                </div>
              )
            }
            {
              device.api_version
              && (
                <div className="device-modal__list">
                  <span>{t('devices__api_version')}</span>
                  <span>{device.api_version}</span>
                </div>
              )
            }
          </div>
        </div>

        {
          device.description
          && (
            <div className="device-modal__desc">
              {device.description}
            </div>
          )
        }
        {
          device.instruction_url
          && (
            <a href={device.instruction_url} target="_blank" className="device-modal__instruction">
              <Icon type="FileDocumentMultipleOutline" className="device-modal__instruction-icon" />
              Device instruction
            </a>
          )
        }

      </Modal.Body>
    </Modal>
  );
};
