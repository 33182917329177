import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Icon } from 'components/common/Icon';

import { selectAuthAccountAvatar } from 'redusers/global/auth';
import { Sidebar } from 'components/Sidebar';
import { Logo } from 'components/common/Logo';
import { Avatar } from 'components/common/Avatar';

import './mobileMenuContainer.scss';
import { IMobileMenuContainerProps, IMobileMenuContainerComponentProps } from './mobileMenu.types';

export const MobileMenuContainerComponent = ({ isOpen, toggle, avatarUrl }: IMobileMenuContainerComponentProps) => {
  const bgClasses = classNames('mobile-menu-bg', {
    'mobile-menu-bg--open': isOpen,
  });

  const containerClasses = classNames('mobile-menu-wrap', {
    'mobile-menu-wrap--open': isOpen,
  });

  return (
    <>
      <div className={bgClasses} onClick={toggle} />
      <div className={containerClasses}>
        <div className="mobile-menu-container__close-btn" onClick={toggle}>
          <Icon type="CloseIcon" />
        </div>
        <div className="mobile-menu-container">
          <div className="mobile-menu-container__header">
            <Logo />
            <Avatar avatarUrl={avatarUrl} />
          </div>
          <Sidebar handleClickByItem={toggle} />
        </div>
      </div>
    </>
  );
};

export const MobileMenuContainer = (props: IMobileMenuContainerProps) => {
  const avatarUrl = useSelector(selectAuthAccountAvatar);
  const newProps = { ...props, avatarUrl };

  return <MobileMenuContainerComponent {...newProps} />;
};
