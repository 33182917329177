import React from 'react';

import { languageProvider } from 'providers/languageProvider';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { ISettingsLanguageItemProps } from './types';

export const SettingsLanguageItem = ({ language }: ISettingsLanguageItemProps) => {
  const handleClick = () => {
    languageProvider.setLanguage(language.key);
    sendGtmTrigger('sv2_change_language');
    window.location.href = '/settings';
  };

  return (
    <div className="custom-modal-languages__item" onClick={handleClick}>
      {language.name}
      {language.translate && ` (${language.translate})`}
    </div>
  );
};
