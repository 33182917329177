import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/common/Icon';

import { DropDownModalItem } from 'components/common/DropDownModal';
import { FileTypes } from 'types/element.types';

import { IFilterTypeProps } from './types';

export const FilterType = ({ currentType, setType }: IFilterTypeProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <DropDownModalItem className="filter-picker__header">
        {t('filter__type-block')}
      </DropDownModalItem>

      <DropDownModalItem
        className={`filter-picker__item ${!currentType ? 'filter-picker__item--active' : ''}`}
        handleClick={() => setType(null)}
        data-testid="filter-type-clear"
      >
        <div className="filter-picker__item-wrap">
          <Icon type="FileDocumentMultipleOutline" className="filter-picker__icon" />
          <div className="filter-picker__item-info">
            <span className="filter-picker__item-name">
              {t('filter__all-file-types')}
            </span>
          </div>
        </div>

      </DropDownModalItem>
      <DropDownModalItem
        className={`filter-picker__item ${currentType === FileTypes.IMG ? 'filter-picker__item--active' : ''}`}
        handleClick={() => setType(FileTypes.IMG)}
        data-testid="filter-type-img"
      >
        <div className="filter-picker__item-wrap">
          <Icon type="Image" className="filter-picker__icon" />
          <div className="filter-picker__item-info">
            <span className="filter-picker__item-name">
              {t('filter__images-type')}
            </span>
          </div>
        </div>
      </DropDownModalItem>
      <DropDownModalItem
        className={`filter-picker__item ${currentType === FileTypes.VIDEO ? 'filter-picker__item--active' : ''}`}
        handleClick={() => setType(FileTypes.VIDEO)}
        data-testid="filter-type-video"
      >
        <div className="filter-picker__item-wrap">
          <Icon type="BxsVideo" className="filter-picker__icon" />
          <div className="filter-picker__item-info">
            <span className="filter-picker__item-name">
              {t('filter__videos-type')}
            </span>
          </div>
        </div>
      </DropDownModalItem>
    </Fragment>
  );
};
