import React, { DOMAttributes, memo } from 'react';
import { Icon as LibIcon } from '@iconify/react';

import { IconType, iconTypes } from './IconTypes';

export interface IIconProps extends DOMAttributes<HTMLOrSVGElement> {
  type: IconType;
  className?: string;
  onClick?: () => void | Promise<void>;
}

const getIcon = (type: IconType) => iconTypes.get(type);

const IconComponent: React.FC<IIconProps> = ({
  type,
  className,
  onClick,
}) => {
  const icon = getIcon(type)!;
  // @ts-ignore
  return <LibIcon icon={icon} className={className} onClick={onClick} />;
};

export const Icon = memo(IconComponent);