import React, { useRef } from 'react';

import { Icon } from 'components/common/Icon';
import { ElementTypes } from 'types/element.types';
import ContextMenu from '../common/ContextMenu';
import { IItemModalControl } from './types';

import './itemModalControl.scss';

export const ItemModalControl = ({
  file,
  setFavorite,
  handleClose,
  isFavorite,
  fileIndex,
  fromSearch = false,
}: IItemModalControl) => {
  const ctxBtnRef = useRef<HTMLDivElement>(null);

  return (
    <div className="item-modal-control">
      <div className="item-modal-control__close" onClick={handleClose}>
        <Icon type="ArrowLeft" />
      </div>
      <div className="item-modal-control__name">
        {file.name}
      </div>
      <div className="item-modal-control__ctx-box">
        <div className="item-modal-control__icon" onClick={setFavorite}>
          <Icon type={isFavorite ? 'Star' : 'StarOutline'} />
        </div>
        <a href={file.data_url} className="item-modal-control__icon" download>
          <Icon type="DownloadOutline" />
        </a>
        <div
          ref={ctxBtnRef}
          className="item-modal-control__ctx"
          data-context-active="true"
          data-ctx-menu-type={ElementTypes.FILE}
          data-item-id={file.id}
          data-item-idx={fileIndex}
          data-item-share={file.share_url || false}
          data-item-url={file.data_url || false}
          data-item-name={file.name}
          data-item-favorite={isFavorite}
          data-item-from-search={fromSearch}
        >
          <Icon type="DotsVertical" />
        </div>
      </div>
      {/* @ts-ignore */}
      <ContextMenu height={360} ref={ctxBtnRef} onlyByClick />
    </div>
  );
};
